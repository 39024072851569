import React from 'react';
import { Link, useLocation} from 'react-router-dom';
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';

import arrow from '../img/arrow.png';

function t(field) { return Translation.get(field) }

function change(e) {
  const robots = window.sessionStorage.getItem("robot_ids").split(",")
  const robotsNames = window.sessionStorage.getItem("robot_names").split("||")
  window.sessionStorage.setItem("robot_id", robots[e.target.value])
  window.sessionStorage.setItem("robot_name", robotsNames[e.target.value])
  window.location.reload(false)
}
function Logo() {
  //console.log(window.sessionStorage)
  const location = useLocation();
  const path = location.pathname.split("/");
  window.sessionStorage.setItem("page_id", location.pathname)
  let first = path[1]
  let second = null
  if (path[2]) second = path[2]
  let id= null
  if (path[2] && path[2].indexOf(":") === 0) {
    id = path[2].substring(1, path[2].length)
    window.sessionStorage.setItem("robot_id", id)
  }
  if (path[3] && path[3].indexOf(":") === 0) {
    if (id === null) {
      id = path[3].substring(1, path[3].length)
      window.sessionStorage.setItem("robot_id", id)
    }
    else window.sessionStorage.setItem("robot_name", path[3].substring(1, path[3].length))
  }
  if (path[4]) window.sessionStorage.setItem("robot_name", path[4].substring(1, path[4].length))
  if (first === "") first = "home"
  const headLine = "nav_" + first
  let secondLine = "nav_" + second
  //console.log(window.sessionStorage)
  const robots = window.sessionStorage.getItem("robot_ids").split(",");
  const robotsNames = window.sessionStorage.getItem("robot_names").split("||");
  const robotIndex = robots.findIndex((element) => parseInt(element) === parseInt(window.sessionStorage.getItem("robot_id")))
  //{decodeURI(robotName)}
  return (
      <div className="head">
        <Link to=""><img src={require("../img/Hetwin_Head.png")} alt={t('nav_home')}  title={t('nav_home')} className="logo" /></Link>
        {UserProfile.getUserLevel() <= 4 || window.sessionStorage.getItem("support") === "true" ? <div className="customer">{window.sessionStorage.getItem("customer_name")}</div> : null}
        {UserProfile.getAboLeft()}
        {first === "robot1" || first === "stats1" ?
          <div className="headline">
            <select name={id} value={robotIndex} onChange={(value) => {change(value)}}>
              { Object.entries(robots).map((t,k) => <option key={k} value={t[0]}>{robotsNames[k]}</option>) }
            </select>
            {second !== null ? <span> <img src={arrow} alt={t('arrow')} title={t('arrow')} className="arrow"/> {t(secondLine)}</span> : ""}
          </div> :
          <div className="headline">{t(headLine)} {second !== null ? <span><img src={arrow} alt={t('arrow')} title={t('arrow')} className="arrow"/> {t(secondLine)}</span> : ""}</div>
        }
      </div>
  )
}

function Navigation() {
  const location = useLocation()
  let first = location.pathname.substring(1, location.pathname.length)
  if (first.indexOf("/") > 0) { first = first.substring(0, first.indexOf("/"))  }
  function over(e) {
    switch(e.target.id) {
      case "nav_home":
        e.currentTarget.src =  require("../img/navi/mouseover/home.png")
        break
      case "nav_login":
        e.currentTarget.src =  require("../img/navi/mouseover/logout.png")
        break
      case "nav_profile":
        e.currentTarget.src =  require("../img/navi/mouseover/profile.png")
        break
      //case "nav_feeds":
        //e.currentTarget.src =  require("../img/navi/mouseover/feeds.png")
        //break
      case "nav_edit":
        e.currentTarget.src =  require("../img/navi/mouseover/animalfeed.png")
        break
      case "nav_stats":
        e.currentTarget.src =  require("../img/navi/mouseover/stats.png")
        break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_home":
        //if (first === "home") e.currentTarget.src = require("../img/navi/active/home.png")
        //else
        e.currentTarget.src =  require("../img/navi/home.png")
        break
      case "nav_login":
        if (first === "Login") e.currentTarget.src = require("../img/navi/active/logout.png")
        else e.currentTarget.src =  require("../img/navi/logout.png")
        break
      case "nav_profile":
        if (first === "profile") e.currentTarget.src = require("../img/navi/active/profile.png")
        else e.currentTarget.src =  require("../img/navi/profile.png")
        break
      case "nav_feeds":
        if (first === "recipe") e.currentTarget.src = require("../img/navi/active/feeds.png")
        else e.currentTarget.src =  require("../img/navi/feeds.png")
        break
      case "nav_edit":
        if (first === "robot") e.currentTarget.src = require("../img/navi/active/animalfeed.png")
        else e.currentTarget.src =  require("../img/navi/animalfeed.png")
        break
      case "nav_stats":
        if (first === "stats") e.currentTarget.src = require("../img/navi/active/stats.png")
        else e.currentTarget.src =  require("../img/navi/stats.png")
        break
      default:
    }
  }
  <Logo />
  if (window.sessionStorage.getItem("user_id")) {
    //console.log(window.sessionStorage)
    if (UserProfile.checkRight("addcust")) first = "profile"
    return (
      <nav id="navigation">
        <div>
          <Link to="Login"><img id="nav_login" src={first === "Login" ? require("../img/navi/active/logout.png") : require("../img/navi/logout.png")} alt={t('nav_logout')}  title={t('nav_logout')} onMouseOver={over} onMouseOut={out}/></Link>
          <Link to="profile/robots"><img id="nav_profile" src={first=== "profile" ? require("../img/navi/active/profile.png") : require("../img/navi/profile.png")} alt={t('nav_profile')}  title={t('nav_profile')} onMouseOver={over} onMouseOut={out}/></Link>
          { UserProfile.showFeeds() === "true" ?
            <span>
              <Link to="recipe/recipe"><img id="nav_feeds" src={first === "recipe" ? require("../img/navi/active/feeds.png") : require("../img/navi/feeds.png")} alt={t('nav_feeds')}  title={t('nav_feeds')} onMouseOver={(e) => { e.currentTarget.src=require("../img/navi/mouseover/feeds.png")}} onMouseOut={out}/></Link>
              <Link to="robot/drives"><img id="nav_edit" src={first=== "robot" ? require("../img/navi/active/animalfeed.png") : require("../img/navi/animalfeed.png")} alt={t('nav_animalgroup')}  title={t('nav_animalgroup')} onMouseOver={over} onMouseOut={out}/></Link>
              <Link to="stats/feeds"><img id="nav_stats" src={first=== "stats" ? require("../img/navi/active/stats.png") : require("../img/navi/stats.png")} alt={t('nav_stats')}  title={t('nav_stats')} onMouseOver={over} onMouseOut={out}/></Link>
              <Link to="/"><img id="nav_home" src={require("../img/navi/home.png")} alt={t('nav_home')}  title={t('nav_home')} onMouseOver={over} onMouseOut={out}/></Link>
            </span>
            : null
          }
      </div>
      </nav>
    )
  }
  else {
    return (
      <nav id="navigation">
        <div>
          <Link to="contact">Contact</Link>
          <Link to="/">Login</Link>
        </div>
      </nav>
    )
  }
}

class Header1 extends React.Component {
  render() {
    return (
      <header>
        <Logo />
        <Navigation />
      </header>
    )
  }
}

export default Header1