import React, { Component } from 'react'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import Translation from '../user/Translation'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP"

import AnimalGroup from '../datatables/AnimalGroupList.js'
import BoxList from '../datatables/BoxList.js'
import PushjourneyList from '../datatables/PushjourneyList.js'
import DriveList from '../datatables/DrivesList.js'
import MilkDailyList from '../datatables/MilkDailyList.js'
import DailyWeight from '../datatables/WeightDailyList.js'
import Contact from './Contact.js'
import UserProfile from '../user/UserProfile.js'


function t(field) { return Translation.get(field) }

function SideBar() {
  const location = useLocation()
  const path = location.pathname.split("/")
  let second = path[2]
  let id= null
  if (path[2] && path[2].indexOf(":") === 0) {
    id = path[2].substring(1, path[2].length)
    window.sessionStorage.setItem("robot_id", id)
  }
  if (path[3] && path[3].indexOf(":") === 0) {
    if (id === null) {
      id = path[3].substring(1, path[3].length)
      window.sessionStorage.setItem("robot_id", id)
    }
    else window.sessionStorage.setItem("robot_name", path[3].substring(1, path[3].length))
  }
  //if (id === null ) id = window.sessionStorage.getItem("robot_id")
  //const linkAnimalgroup = "animalgroup/:" + id
  //const linkBoxes = "boxes/:" + id
  function over(e) {
    switch(e.target.id) {
      case "nav_animalgroup":
        e.currentTarget.src =  require("../img/navi/mouseover/animalgroup.png")
        break
      case "nav_box":
        e.currentTarget.src =  require("../img/navi/mouseover/box.png")
        break
      case "nav_route":
        e.currentTarget.src =  require("../img/navi/mouseover/route.png")
        break
      case "nav_drives":
        e.currentTarget.src =  require("../img/navi/mouseover/drives.png")
        break
      case "nav_milk":
        e.currentTarget.src =  require("../img/navi/mouseover/milk.png")
        break
      case "nav_meat":
        e.currentTarget.src =  require("../img/navi/mouseover/meat.png")
        break
      case "nav_contact":
        e.currentTarget.src =  require("../img/navi/mouseover/contact.png")
        break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      case "nav_animalgroup":
        if (second === "animalgroup") e.currentTarget.src = require("../img/navi/active/animalgroup.png")
        else e.currentTarget.src =  require("../img/navi/animalgroup.png")
        break
      case "nav_box":
        if (second === "boxes") e.currentTarget.src = require("../img/navi/active/box.png")
        else e.currentTarget.src =  require("../img/navi/box.png")
        break
      case "nav_route":
        if (second === "routes") e.currentTarget.src = require("../img/navi/active/route.png")
        else e.currentTarget.src =  require("../img/navi/route.png")
        break
      case "nav_drives":
        if (second === "drives") e.currentTarget.src = require("../img/navi/active/drives.png")
        else e.currentTarget.src =  require("../img/navi/drives.png")
        break
      case "nav_milk":
        if (second === "milk") e.currentTarget.src = require("../img/navi/active/milk.png")
        else e.currentTarget.src =  require("../img/navi/milk.png")
        break
      case "nav_meat":
        if (second === "meat") e.currentTarget.src = require("../img/navi/active/meat.png")
        else e.currentTarget.src =  require("../img/navi/meat.png")
        break
      case "nav_contact":
        if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png")
        else e.currentTarget.src =  require("../img/navi/contact.png")
        break
      default:
    }
  }
  //console.log(UserProfile.checkMilk())
  //console.log(UserProfile.checkMeat())
  console.log(window.sessionStorage)
  return (
    //<Link to="feed"><img id="nav_feed" src={second === "feed" ? require("../img/navi/feed_active.png") : require("../img/navi/feed.png")} alt={t('nav_feed')} title={t('nav_feed')} onMouseOver={over} onMouseOut={out}/></Link>
    //<Link to="recipe"><img id="nav_recipe" src={second === "recipe" ? require("../img/navi/recipe_active.png") : require("../img/navi/recipe.png")} alt={t('nav_recipe')} title={t('nav_recipe')} onMouseOver={over} onMouseOut={out}/></Link>
    //<Link to="silo"><img id="nav_silo" src={second === "silo" ? require("../img/navi/silo_active.png") : require("../img/navi/silo.png")} alt={t('nav_silo')} title={t('nav_silo')} onMouseOver={over} onMouseOut={out}/></Link>
    //<Link to="boxes"><img id="nav_box" src={second === "boxes" ? require("../img/navi/active/box.png") : require("../img/navi/box.png")} alt={t('nav_boxes')} title={t('nav_boxes')} onMouseOver={over} onMouseOut={out}/></Link>
    //{UserProfile.checkMeat() === "true" ? <Link to="meat"><img id="nav_meat" src={second === "meat" ? require("../img/navi/active/meat.png") : require("../img/navi/meat.png")} alt={t('nav_meat')} title={t('nav_meat')} onMouseOver={over} onMouseOut={out}/></Link> : null}
    <div className="nav_left_main">
      <Link to="drives"><img id="nav_drives" src={second === "drives" ? require("../img/navi/active/drives.png") : require("../img/navi/drives.png")} alt={t('nav_drives')} title={t('nav_drives')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="animalgroup"><img id="nav_animalgroup" src={second === "animalgroup" ? require("../img/navi/active/animalgroup.png") : require("../img/navi/animalgroup.png")} alt={t('nav_animalgroup')} title={t('nav_animalgroup')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="boxes"><img id="nav_box" src={second === "boxes" ? require("../img/navi/active/box.png") : require("../img/navi/box.png")} alt={t('nav_boxes')} title={t('nav_boxes')} onMouseOver={over} onMouseOut={out}/></Link>
      <Link to="routes"><img id="nav_route" src={second === "routes" ? require("../img/navi/active/route.png") : require("../img/navi/route.png")} alt={t('nav_routes')} title={t('nav_routes')} onMouseOver={over} onMouseOut={out}/></Link>
      {UserProfile.checkMilk() === "true" ? <Link to="milk"><img id="nav_milk" src={second === "milk" ? require("../img/navi/active/milk.png") : require("../img/navi/milk.png")} alt={t('nav_milk')} title={t('nav_milk')} onMouseOver={over} onMouseOut={out}/></Link> : null}
      <Link to="contact" className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class DetailsPage extends Component {
  render() {
    const id = window.sessionStorage.getItem("robot_id")
    //console.log("I am here!",this.props )
    return(
      <div>
        <h2>Hallo Robot {id}</h2>
      </div>
    )
  }
}

class Robots extends Component {
  constructor() {
    super()
    this.state={
      geturl: "/robots/get_robots.php?id=",
      data: [],
      robottypes: [],
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + window.sessionStorage.getItem("robot_id")).then((result) => {
      if (result.data) result.data.password = null
      this.setState({
        data: result.data,
        robottypes: result.robottypes,
      })
      if (result.data) window.sessionStorage.setItem("robot_name", result.data[0].name)
    })
  }

  render() {
    //<Route path="boxes" element={<BoxList />} />
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2">< SideBar /></nav>
        </div>
        <div className="content">
        <div className="body">
        <div className="tablebody">
          <Routes>
            <Route path=":id" element={<AnimalGroup />} />
            <Route path="animalgroup/:id/*" element={<AnimalGroup />} />
            <Route path="routes" element={<PushjourneyList />} />
            <Route path="drives" element={<DriveList />} />
            <Route path="boxes" element={<BoxList />} />
            <Route path="details/:id" element={<DetailsPage />} />
            <Route path="milk" element={<MilkDailyList />} />
            <Route path="meat" element={<DailyWeight />} />
            <Route path="contact" element={<Contact />}/>
          </Routes>
        </div>
        </div>
        </div>
      </div>
    )
  }
}

export default Robots