import TableFunctions from '../datatables/api/TableFunctions.js';
import ReactTable from "react-table-6";
import dateFormat from 'dateformat';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
import GetJsonFormPHP, { PostJsonFormPHP } from "../datatables/api/GetJsonFormPHP";
import '../datatables/styles/react-table.css';
import '../datatables/styles/tablestyles.css';

function t(field) { return Translation.get(field) }

class Tables extends TableFunctions{
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-14)
    this.state={
      geturl: "/charts/tables.php?start=",
      data: [], // Table data
      startDate: format(startDay, 'yyyy-MM-01'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      feeds: [], feedsID: [], feedsColor: [], csv: "",
      nutrids: "0", nutridsName: [], nutridsID: [], nutridsUnit: [],
      showHelp: false, showHelpId: null, // Info popup
    }
  }
  componentDidMount(val1 = this.state.startDate, val2 = this.state.endDate, val3 = this.state.nutrids) {
    GetJsonFormPHP(this.state.geturl + val1 + "&end=" + val2 + "&nutrids=" + val3).then((result) => {
      if (result.data === null) result.data = [{id: 0}]
      this.setState({
        data: result.data,
        feeds: result.feeds,
        feedsID: result.feeds_id,
        nutridsName: result.nutrids,
        nutridsUnit: result.nutrids_unit,
        nutridsID: result.nutrids_id,
        feedsColor: result.feeds_color,
        csv: result.csv,
      })
    })
  }
  createColumns() {
    let columns =  [
      { accessor: 'id', show: false,},
      { accessor: 'all', show: false,},
      this.addTextCell('name', 'name', 0, 0, true, "", null, null, null, false),
      this.addTextCell('animals_animals', 'animals', 2, 5, true, "", null, null, null, false, false, 1),
      this.addTextCell('animals_feed_days', 'days', 1, 3, true, "", null, null, 'darkgrey'),
      this.addTextCell('§' + t('feed_FM_short') + " / " + t('day') + "<br />[kg]", 'FM', 2, 7, true, "", null, null, null, false, null, 1),
      this.addTextCell('§' + t('feed_T_short') + " / " + t('day') + "<br />[kg]", 'T', 2, 7, true, "", null, null, null, false, null, 1),
    ]
    if (this.state.feedsID) {
      this.state.feedsID.map((t,k) => {
        //console.log(t,k, 'feed_' + t)
        columns = [...columns, this.addNumberCell('§' + this.state.feeds[k], 'feed_' + t, 2, 0, 100, 5, true, "", null, this.state.feedsColor[k])]
        return null
      })
    }
    //console.log(columns)
    return columns
  }
  createNutridColumns() {
    let columns =  [
      { accessor: 'id', show: false,},
      { accessor: 'all', show: false,},
      this.addTextCell('name', 'name', 0, 0, true, "", null, null, null, false),
      this.addTextCell('animals_animals', 'animals', 1, 5, true, "", null, null, null, false),
      this.addTextCell('animals_feed_days', 'days', 1, 3, true, "", null, null, 'darkgrey'),
      this.addTextCell('§' + t('feed_FM_short') + " / " + t('day') + "<br />[kg]", 'FM', 2, 7, true, "", null, null, null, false),
      this.addTextCell('§' + t('feed_T_short') + " / " + t('day') + "<br />[kg]", 'T', 2, 7, true, "", null, null, null, false),
    ]
    if (this.state.nutridsID) {
      this.state.nutridsID.map((t,k) => {
        //console.log(t,k, 'feed_' + t)
        columns = [...columns, this.addNumberCell('§' + this.state.nutridsName[k], 'feed_' + t, 2, 0, 100, 5, true, this.state.nutridsUnit[k], null, this.state.feedsColor[k])]
        return null
      })
    }
    //console.log(columns)
    return columns
  }
  downloadCsv() {
        var uri = 'data:text/csv;charset=utf-8,' + this.state.csv;
        var downloadLink = document.createElement("a");
        downloadLink.href = uri;
        downloadLink.download = "Hetwin_cows_"+this.state.startDate+"_"+this.state.endDate+".csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
  }
  downloadExcel() {
    let data = {}
    data.csv = this.state.csv
    data.cols = [null, null, null, null, ...this.state.feedsColor]
    //data.format = [...this.state.feedsColor]
    PostJsonFormPHP("get_excel.php", data).then((result) => {
      console.log(this.state.csv)
    })
  }

  render() {
    let columns = null
    if (this.state.nutrids === "0") columns = this.createColumns()
    else columns = this.createNutridColumns()
    //console.log(this.state.data)
    const changeNutrids = value => { this.setState({ nutrids: value.target.value, }); this.componentDidMount(this.state.startDate, this.state.endDate, value.target.value) }
    let cols = [null, null, null, null]
    if (this.state.feedsColor !== null) cols = [null, null, null, null, ...this.state.feedsColor]
    return (
      <div><div className="table_buttons">
        <table width="35%" border="0">
          <tbody><tr>
            <td>{t("choose")}</td><td><select name="nutridslist" value={this.state.nutrids} onChange={(value) => {changeNutrids(value)}} className="chartselect">
              <option key="nutrid_off" value="0">{t("chart_select_feed")}</option>
              <option key="nutrid_on" value="1">{t("chart_select_nutrids")}</option>
            </select></td>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={ value => { this.setState({ startDate: value.target.value, }) }} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={value => { this.setState({ endDate: value.target.value, }) }} className="datefield"/></td>
            <td width="15%" align="center"><button onClick={() => {this.componentDidMount(this.state.startDate, this.state.endDate, this.state.nutrids)}} className="button">{t("show")}</button></td>
            <td><img src="https://connect.hetwin.at/img/csv.png" onClick={() => {this.downloadCsv()}} className="excelimage" alt={t('export_csv')} title={t('export_csv')} /></td>
            <td>
            <form id="TheForm" method="post" action="https://connect.hetwin.at/portalapitest/get_excel.php" target="">
              <input type="hidden" name="data" value={this.state.csv} />
              <input type="hidden" name="col_format" value={cols.join(";")} />
              <input type="hidden" name="col_width" value="30;10;10;0;15;15;15;15;15;15;15;15;15;15;15;15" />
              <input type="hidden" name="head" value={t('time_scale') + ";" + dateFormat(this.state.startDate, t('dateformat')) + ";" + dateFormat(this.state.endDate, t('dateformat'))} />
              <input type="hidden" name="file" value="Hetwin_Animalgroups" />
              <input type="hidden" name="page" value="Page 1" />
              <input type="hidden" name="nutrids" value={this.state.nutrids} />
              <input type="image" src="https://connect.hetwin.at/img/excel.png" alt={t('export_excel')} title={t('export_excel')} className="excelimage" />
            </form>
            </td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_table_charts", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table></div>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                style: {
                  background: 'white',
                  fontWeight: rowInfo && rowInfo.row.all === 1 ? 600 : null,
                }
            }
          }}
        />
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}

export default Tables;
