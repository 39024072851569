import TableFunctions from './api/TableFunctions.js';
import React, { Component } from 'react';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import AlertDialog from "../user/Dialog.js";
import TextField from '@mui/material/TextField';
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP";
import {setTableFontSize} from './api/imports.js'
import FileUpload from "../user/Upload.js";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import Info from "./api/Info";
import ManageCols from "./api/ManageFields";
import { format } from 'date-fns';
import './styles/react-table.css';
import imgClose from './img/close.png';

function t(field) { return Translation.get(field) }
//const delay = ms => new Promise(res => setTimeout(res, ms));


class ManagePrice extends Component {
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-1)
    let endDay = new Date()
    endDay.setDate(endDay.getDate())
    this.state={
      saveurl: "/feed/set_milkprice.php?price=",
      price: null, startDate: format(startDay, 'yyyy-MM-dd'), endDate: format(endDay, 'yyyy-MM-dd'),
      checked: null,
    }
    this.handleClose = this.handleClose.bind(this)
    //this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this)
  }
  static getDerivedStateFromProps(props, state) {
    if (!state.price)
    return {
      price: props.price,
      startDate: props.startDate,
    }
    else return null
  }
  handleClose() {
    this.props.closeDiv();
  }
  /*toggleCheckboxChange(value) {
    let fields = this.state.showfields
    for (let key in fields) { if (key === value.target.value) fields[key] = !fields[key] }
    this.setState({
      price: fields
    })
  }*/
  handleSubmit() {
    GetJsonFormPHP(this.state.saveurl + this.state.price + "&start=" + this.state.startDate + "&end=" + this.state.endDate).then(response => {
      this.props.closeDiv()
    })
  }

  render () {
    //console.log(this.state.startDate)
    return (
      <Draggable handle=".handle">
        <div className="edittable">
        <div className="handle"><div className="header">{t("milkdaily_set_price")}<img src={imgClose} alt={t('close')} className="close"  onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div></div>
          <div>
            <table><tbody>
              <tr><td width="65%">{t('milkdaily_new_price')}</td><td><input type="text" name="price" value={this.state.price} onChange={(value) => { this.setState({ price: value.target.value, }) }} className="right_align" /> €</td></tr>
              <tr><td>{t('start_date')}</td><td><TextField id="startDate" type="date" value={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ startDate: value.target.value, }) }} className="datefield"/></td></tr>
              <tr><td>{t('end_date')}</td><td><TextField id="endDate" type="date" value={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ endDate: value.target.value, }) }} className="datefield"/></td></tr>
            </tbody></table>
            <div className="table_buttons" align="center">
              <button onClick={() => {this.handleSubmit()}}>{t("save")}</button>
            </div>
          </div>
        </div>
      </Draggable>
    )
  }
}

class DailyMilk extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_milkdaily.php",
      saveurl: "/feed/set_milkdaily.php?groups=0",
      saveFieldsUrl: "/users/save_fieldoptions.php?id=",
      data: [], empty: null, selected: null, groups: null, groupnames: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      showfields: null, manageFields: false, managePrice: false,  // Show table fields
      showHelp: false, showHelpId: null, // Info popup
      first: true,  //daily: true,
      showHideLoad: 0, uploadFiles: ".ads, .abo",
      fontSize: setTableFontSize(),
    }
    this.upload = this.upload.bind(this)
    this.closeDiv = this.closeDiv.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    //console.log("mount")
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (!result.auto_import) this.setState({ selected: 0, })
      let gr = 0
      if (result.showfields.groups) gr = 1
      this.setState({
        data: result.data,
        groups: result.groups,
        groupnames:  result.groupnames,
        showfields: result.showfields,
        months: result.months,
        first: false,
        saveurl: this.state.saveurl.substring(0, this.state.saveurl.length-1) + gr
      })
    })
  }
  changeGroupSetting() {
    let newSetting = this.state.showfields
    if (newSetting.groups) {
      newSetting.groups = false
      newSetting.groups_in_milk = false
      newSetting.groups_milk = false
    }
    else {
      newSetting.groups = true
      newSetting.groups_in_milk = true
      newSetting.groups_milk = true
    }
    this.setState({
      selected: null,
    })
    PostJsonFormPHP(this.state.saveFieldsUrl+"milkgrouplist", newSetting).then(response => {
      this.componentDidMount()
    })
  }
  createColumns() {
    //const colors =["#EEAAAA", "#AAEEAA", "#AAAAEE", "#EEAAEE", "#EEEEAA", "#AAEEEE", "#FFAA66", "#AAFF66", "#AA66FF", "#66AAFF", "#66FFAA", "#FF66AA", "#AA33AA", "#33AAAA", "#AAAA33",]
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    let columns = [
      { accessor: 'id',show: false,},
      { accessor: 'customer_id', show: false},
      { accessor: 'incomplete', show: false},
      { accessor: 'price_per_l_save', show: false},
      this.addDateCell('date', 'day', false, 6.8),
    ]
    /*if (this.state.groups) {
      this.state.groups.map((t1,k) => {
        let groupColumns = []
        groupColumns = [...groupColumns, this.addTextCell('§' + t('milkgroup_groups'), 'group_'+t1+'_cow', 1, 4, this.state.showfields ? this.state.showfields['groups'] : true, "", null, colors[k])]
        groupColumns = [...groupColumns, this.addTextCell('§' + t('milkgroup_groups_milk'), 'group_'+t1+'_milk', 2, 4, this.state.showfields ? this.state.showfields['groups_milk'] : true, "", null, colors[k], null, true, false, 1)]
        groupColumns = [...groupColumns, this.addTextCell('§' + t('milkgroup_groups_in_milk'), 'group_'+t1+'_in_milk', 2, 4, this.state.showfields ? this.state.showfields['groups_in_milk'] : true, "", null, colors[k])]
        let groupColumn = [{
          Header: this.state.groupnames[t1],
          columns: groupColumns
        }]
        columns = [...columns, ...groupColumn]
        return null
      })*/
    const columns2 = [
      this.addTextCell('milkgroup_sumcows', 'cows_all', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, -1, null, true),
      this.addTextCell('milkgroup_all', 'milk_all', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, 0, null, true),
      this.addTextCell('milkgroup_milkpercow', 'milk_per_cow', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, 1, null, true),
      this.addTextCell('milkgroup_Fett', 'Fett', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Fett'] : true),
      this.addTextCell('milkgroup_Eiw', 'Eiw', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Eiw'] : true),
      this.addTextCell('milkgroup_FEQ', 'FEQ', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['FEQ'] : true),
      this.addTextCell('milkgroup_Ff_Tm', 'Ff_Tm', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Ff_Tm'] : true),
      this.addTextCell('milkgroup_Zellz', 'Zellz', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Zellz'] : true),
      this.addTextCell('milkgroup_Keime', 'Keime', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Keime'] : true),
      this.addTextCell('milkgroup_He', 'He', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['He'] : true),
      this.addTextCell('milkgroup_Gef_P', 'Gef_P', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Gef_P'] : true),
      this.addTextCell('milkgroup_harns', 'harns', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['harns'] : true),
      this.addTextCell('milkgroup_PH', 'PH', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['PH'] : true),
      this.addTextCell('milkgroup_FFA', 'FFA', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['FFA'] : true),
      this.addTextCell('milkgroup_GFS', 'GFS', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['GFS'] : true),
      this.addTextCell('milkgroup_UFS', 'UFS', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['UFS'] : true),
      this.addTextCell('milkgroup_EUFS', 'EUFS', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['EUFS'] : true),
      this.addTextCell('milkgroup_MUFS', 'MUFS', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['MUFS'] : true),
      this.addTextCell('milkgroup_kgN', 'kgN', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['kgN'] : true),
      this.addTextCell('milkgroup_kgP2O5', 'kgP2O5', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['kgP2O5'] : true),
      this.addTextCell('milkgroup_kgP', 'kgP', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['kgP'] : true),
      this.addTextCell('milkgroup_Laktose', 'Laktose', 2, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['Laktose'] : true),
      this.addTextCell('milkgroup_price_per_l', 'price_per_l', 2, 6*this.state.fontSize, true, "€"),
      this.addTextCell('milkgroup_price', 'price', 2, 6*this.state.fontSize, true, "€"),
      this.addTextCell('§von Milch Roboter', 'datasource', 2, 6*this.state.fontSize, this.state.showfields ? this.state.showfields['datasource'] : true, "", "center", null, null, false),
      { accessor: 'edit',
      Header: t('table_edit'),
      width: 8.2*em,
      filterable: false,
      resizable: false,
      //Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, true, this.state.daily === (row.daily === 1) || row.id < 0) },
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, true, true) },
    }]
    return [...columns, ...columns2]
  }
  recalc_fields(org, value, index) {
    let data = this.state.data
    let row = data[index]
    let save = false
    //console.log(value.target.name, value.target.value, org)
    //console.log("row", row, row['cows_all'])
    if (row["milk_robot"] === null || row["milk_robot"] !== 1) {
      if (value.target.name.substring(0,6) === "group_") {
        let key = value.target.name
        if (key.substring(key.indexOf('_', 7), 50) === "_cow") {
          data[index]["cows_all"] += value.target.value-org
          let d = "group"+ key.substring(key.indexOf('_', 7), 5)+"_milk"
          data[index]["milk_all"] += (value.target.value-org)*row[d]
          data[index]['price'] =  Math.round(row['price_per_l']*data[index]["milk_all"]*100)/100
          save = true
        }
        if (key.substring(key.indexOf('_', 7), 50) === "_milk") {
          let d = "group"+ key.substring(key.indexOf('_', 7), 5)+"_cow"
          data[index]["milk_all"] += (value.target.value-org)*row[d]
          data[index]['price'] = Math.round(row['price_per_l']*data[index]["milk_all"]*100)/100
          save = true
        }
      }
      if (value.target.id === "day") {
        const t1 = new Date(value.target.value)
        const t2 = new Date(org)
        const timeDifference = Math.round((t1.getTime() - t2.getTime())/86400000, 0);
        for (let key in row) { if (key.substring(key.indexOf('_', 6), 50) === "_in_milk") {
          row[key] = parseInt(row[key])+timeDifference
          if (row[key] < 0) row[key] = 0
        }}
        data[index] = row
        save = true
      }
      if (value.target.name === "cows_all") {
        if (row['cows_all'] === undefined) row['cows_all'] = value.target.value
        //console.log("cows_all", row, row['milk_per_cow'], row['cows_all'])
        if (row['milk_per_cow']) data[index]['milk_all'] =  Math.round(row['milk_per_cow']*row['cows_all']*10)/10
        else if (row['milk_all']) data[index]['milk_per_cow'] =  Math.round(row['milk_all']/row['cows_all']*10)/10
        if (row['price_per_l']) data[index]['price'] =  Math.round(row['price_per_l']*data[index]['milk_all']*100)/100
        else if (row['price_per_l_save']) data[index]['price'] =  Math.round(row['price_per_l_save']*data[index]['milk_all']*100)/100
      }
      if (value.target.name === "milk_per_cow") {
        //if (row['cows_all'] === undefined) row['cows_all'] = 0
        if (row['milk_per_cow'] === undefined) row['milk_per_cow'] = value.target.value
        //console.log("milk_per_cow", row, row['milk_per_cow'], row['cows_all'])
        //console.log(data[index]['milk_all'], row['milk_per_cow'], row['cows_all'])
        if (row['cows_all']) data[index]['milk_all'] =  Math.round(row['milk_per_cow']*row['cows_all']*10)/10
        if (row['price_per_l']) data[index]['price'] =  Math.round(row['price_per_l']*row['milk_all']*100)/100
        else if (row['price_per_l_save']) data[index]['price'] =  Math.round(row['price_per_l_save']*row['milk_all']*100)/100
        save = true
      }
      if (value.target.name === "milk_all") {
        if (row['milk_all'] === undefined) row['milk_all'] = value.target.value
        //console.log("milk_all", data[index], row)
        //row['milk_per_cow'] =  row['milk_all']/row['cows_all']
        if (row['cows_all']) data[index]['milk_per_cow'] =  Math.round(row['milk_all']/row['cows_all']*100)/100
        if (row['price_per_l']) data[index]['price'] =  Math.round(row['price_per_l']*row['milk_all']*100)/100
        else if (row['price_per_l_save']) data[index]['price'] =  Math.round(row['price_per_l_save']*row['milk_all']*100)/100
        save = true
      }
      if (value.target.name === "price_per_l") {
        if (row['price_per_l'] === undefined) row['price_per_l'] = value.target.value
        //console.log("price_per_l", data[index], row)
        if (row['milk_all']) data[index]['price'] =  Math.round(row['price_per_l']*row['milk_all']*100)/100
        save = true
      }
      if (value.target.name === "price") {
        if (row['price'] === undefined) row['price'] = value.target.value
        //console.log("price", data[index], row)
        if (row['milk_all']) data[index]['price_per_l'] =  Math.round(row['price']/row['milk_all']*100)/100
        save = true
      }
      if (save) {
        //console.log("save")
        this.setState({
          data: data
        })
      }
    }
  }
  upload() {
    this.setState({
      showHideLoad: 1,
    })
  }
  closeDiv(delayReload = false) {
    this.setState({
      manageFields: false,
      showHideLoad: 0,
      selected: null,
    })
    if (delayReload) {
    }
    /*  const yourFunction = async () => {
        await delay(10000)
        console.log("Waited 10s")
        this.componentDidMount()
        console.log("Reload data")
      }
      yourFunction()
    }
    else this.componentDidMount()*/
    this.componentDidMount()
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    const columns = this.createColumns()
    //console.log(this.state.empty)
    //console.log(this.state.fontSize)
    //let setGroups = t("milkdaily_change_to_groups")
    //if (this.state.showfields !== null && this.state.showfields.groups) setGroups = t("milkdaily_remove_groups")
    // <button onClick={() => { this.changeGroupSetting() }}>{setGroups}</button>s
    //{userRight ? <button onClick={() => { this.setState({ managePrice: true, }) }}>{t("milkdaily_new_price")}</button> : null }
    return (
      <div>
        <div className="table_buttons">
          {userRight ? <button onClick={() => {  this.state.showBase = true; this.insertRow()}}>{t("addline")}</button> : null }
          <button onClick={() => { this.setState({ manageFields: true, }) }}>{t("show_advanced")}</button>
          {userRight ? <button onClick={() => { this.upload() }}>{t("milkdaily_upload")}</button> : null }
          <img src={require("./img/reload.png")} alt="*Reload" title="*Reload" onClick={() => { this.reload() } } onMouseOver={(e) => {e.currentTarget.src = require("./img/reload_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/reload.png")}} />
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_milkdaily_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{"font-size": this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          sortable = {false}
          //expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if ((rowInfo.index !== this.state.selected  || rowInfo.row.id < 0) && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: { background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white', color: rowInfo && rowInfo.row.incomplete === 1 ? '#AA0000' : null, }
            }
          }}
        />
        </div>
        {this.state.manageFields && <ManageCols showfields={this.state.showfields} closeDiv={() => { this.setState({ manageFields: false}); this.componentDidMount() }} page={"milkgroup"}/>}
        {this.state.managePrice && <ManagePrice price={this.state.data[0].price_per_l} startDate={this.state.data[0].day} closeDiv={() => { this.setState({ managePrice: false}); this.componentDidMount() }} page={"milkgroup"}/>}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.showHideLoad > 0 && <FileUpload id={this.state.showHideLoad} title={t('milkdaily_upload')} files={this.state.uploadFiles} upload="feed/load_milkdata.php" closeDiv={this.closeDiv} />}
      </div>
    )
  }
}

export default DailyMilk;
