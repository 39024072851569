import TableFunctions from '../datatables/api/TableFunctions.js'
//import ReactTable from "react-table-6"
import Translation from '../user/Translation.js'
import GetJsonFormPHP, { PostJsonFormPHP } from "../datatables/api/GetJsonFormPHP.js";
import UserProfile from '../user/UserProfile.js'
import '../datatables/styles/react-table.css'
//import { lighten } from '@mui/material';

function t(field) { return Translation.get(field) }

class Serviceprotokoll extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_service_protokoll.php",
      saveurl: "/users/set_service_protokoll.php",
      data: {city: "", }, empty: null, selected: -1,
      save: false, servicedate: '',
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + "?robot_id=" + UserProfile.getServiceRobotID()).then((result) => {
      this.setState({
        data: result.data,
        save: result.save,
        servicedate: result.servicedate,
      })
    })
  }
  handleInputChange(value, inputType = 0, min = null, max = null, decimals = null) {
    if (this.checkInputChars(value, inputType)) {
      const target = value.target.name
      let data = this.state.data
      let newVal = value.target.value
      let error = false
      if (min !== null && (inputType === 1 || inputType === 2) && value.target.value < min) error = true
      if (max !== null && (inputType === 1 || inputType === 2) && value.target.value > max) error = true
      if (inputType === 2) {
        if (decimals !== null && decimals > 0 && newVal !== "-" && newVal !== "+" && newVal !== ".") {
          newVal = newVal*10**decimals
          if (newVal < 0) newVal = Math.ceil(newVal)
          else  newVal = Math.floor(newVal)
          newVal = newVal/10**decimals
          if (parseFloat(value.target.value) === parseFloat(newVal)) newVal = value.target.value
        }
        else newVal = newVal*1;
      }
      if (inputType === 3) {
        let time = newVal.split(":");
        if (time[0] > 24) newVal = "24:"+time[1]
        if (time[1] > 59) newVal = time[0]+":59"
      }
      data.result[target] = newVal
      this.setState({
        data: data,
        fieldError: error,
      })
    }
  }
  handleChechboxChange(value) {
    let data = this.state.data
    data.result[value.target.name] = !data.result[value.target.name]
    //console.log(this.state.data.result)
  }
  /*handleRatioChange(value) {
    let data = this.state.data
    data.result[value.target.name] = value.target.value
  }*/
  handleSave() {
    //console.log(this.state.saveurl,this.state.data.result)
    PostJsonFormPHP(this.state.saveurl, this.state.data.result).then((result) => {
    })
  }

  render() {
    console.log("result: ", this.state.data.result)
    const inputChange = value => { this.handleInputChange(value) }
    const inputNumChange = value => { this.handleInputChange(value, 2, null, null, 2) }
    const checkboxChange = value => { this.handleChechboxChange(value) }
    //const ratioChange = value => { this.handleRatioChange(value) }
    const headLine = (line) => {
      return <tr className="infoline" key={"tr_" + line.fieldname}><td colSpan="2" width="50%" className="infoline"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td><td width="7%" className="infoline" align="center">OK</td><td width="8%" className="infoline" align="center">Tausch</td><td width="35%" align="center" className="infoline"><b>Info</b></td></tr>
    }
    const calcLine = (line) => {
      const nameChange = line.fieldname + "_c"
      return (
        <tr className={line.optional === 1 ? "optional" : null} key={"tr_" + line.fieldname}>
          <td colSpan="2"><span dangerouslySetInnerHTML={{__html: line.text}}></span></td>
          <td align="center">{line.infofield === 1 ? this.state.save ? <input type="text" name={line.fieldname} onChange={inputNumChange} defaultValue={this.state.data.result[line.fieldname]} className="insert_med right" /> :  <input type="text" name={line.fieldname} value={this.state.data.result[line.fieldname]} className="insert_med right" /> : line.infofield !== -1 ?
          this.state.save ? <input type="checkbox" name={line.fieldname} value="1" className="checkbox" onChange={checkboxChange} defaultChecked={this.state.data.result[line.fieldname]}/> : <input type="checkbox" name={line.fieldname} value="1" className="checkbox" onChange={checkboxChange} checked={this.state.data.result[line.fieldname]}/> : null}</td>
          <td align="center" className={line.force === 1 ? "force": null}>
            {line.exchange === 1 ? this.state.save ? <input type="checkbox" name={nameChange} value="1" className="checkbox" onChange={checkboxChange} defaultChecked={this.state.data.result[nameChange]} /> : <input type="checkbox" name={nameChange} value="1" className="checkbox" onChange={checkboxChange} checked={this.state.data.result[nameChange]}/>: null}
            </td>
          <td className="info">{line.optional === 1 ? <span>{t('serviceprotocol_optional')}</span> : line.info ? <span dangerouslySetInnerHTML={{__html: line.info}}></span> : null}</td>
        </tr>
      )
    }
    const company = this.state.data.company
    const robot = this.state.data.robot
    if (!company) return null
    else {
      const screwline = this.state.data.lines.auger
      let lineElements = screwline.map(function(line) {
        if (line.type === "head") return <tr><th  colSpan="5">{line.text}</th></tr>
        if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
        if (line.type === "info") return headLine(line)
        else return calcLine(line)
      })
      const sliderline = this.state.data.lines.slider
      let sliderElements = sliderline.map(function(line) {
        if (line.type === "head") return <tr><th  colSpan="5">{line.text}</th></tr>
        if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
        if (line.type === "info") return headLine(line)
        else return calcLine(line)
      })
      //const sliderline = this.state.data.lines.slider
      let steeringElements = this.state.data.lines.steering.map(function(line) {
        if (line.type === "head") return <tr><th  colSpan="5">{line.text}</th></tr>
        if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
        if (line.type === "info") return headLine(line)
        else return calcLine(line)
      })
      let powerElements = this.state.data.lines.power.map(function(line) {
        if (line.type === "head") return <tr><th colSpan="5">{line.text}</th></tr>
        if (line.type === "infoline") return <tr className="infoline" key={"tr_" + line.fieldname}><td className="infoline" colSpan="5"><b><span dangerouslySetInnerHTML={{__html: line.text}}></span></b></td></tr>
        if (line.type === "info") return headLine(line)
        else return calcLine(line)
      })
      return (
        <div align="center" className="table_80">
          <input type="hidden" name="robot_id" value={robot.id} />
          <table className="service_table_main" width="100%">
            <tbody>
            <tr>
              <th width="75%">{t('service_protocol')} &nbsp; {this.state.servicedate}</th>
              <td rowSpan="2" className="logo"><img src="https://connect.hetwin.at/public/img/Hetwin_Logo.png" alt="Hetwin Logo" width="90%" align="center" /><br />
                <font size="-2">Mitterweg 15 | 6336 Langkampfen <br />
                 +43 5332 85300<br />
                 info@hetwin.at<br /></font>
              </td>
            </tr>
            <tr><td><hr />{robot.typename} &nbsp; &nbsp; {robot.serial_number}</td></tr>
            </tbody>
            </table> <br/>
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%">
              <tbody>
              <tr>
                <th colSpan="4">{t('service_customerdata')}</th>
              </tr>
              <tr>
                <td width="15%"><b>{t('add_customer_name')}</b></td>
                <td width="35%">{company.company_name}</td>
                <td width="20%"><b>Programmversion</b></td>
                <td width="30%">{robot.firmware_version}</td>
              </tr>
              <tr>
                <td rowSpan ="2"><b>{t('address')}</b></td>
                <td rowSpan ="2"><span dangerouslySetInnerHTML={{__html: company.company_address}}></span></td>
                <td><b>{t('service_run_hours')}</b></td>
                <td>{robot.run_hours} {t('hours')}</td>
              </tr>
              <tr>
                <td><b>kWh Akku</b></td>
                <td>{robot.accu}</td>
              </tr>
              <tr>
                <td><b>Servicevertrag</b></td>
                <td><nobr><input type="radio" name="sevicecontract" value="1" className="checkbox" disabled />JA &nbsp; <input type="radio" name="sevicecontract" className="checkbox" value="0" checked />NEIN </nobr></td>
                <td><b>Seriennummer</b></td>
                <td>{robot.serial_number}</td>
              </tr>
              </tbody>
            </table><br />
            <h2 className="h2_Service">{robot.typename} &nbsp; &nbsp; {robot.serial_number}</h2>
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{lineElements}</tbody></table><br />
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{sliderElements}</tbody></table><br />
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{steeringElements}</tbody></table><br />
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>{powerElements}</tbody></table><br />
            <table className="service_table" cellSpacing="0" cellPadding="0" width="100%" border="0"><tbody>
              <tr><th colSpan="5">Notizen</th></tr>
              <tr><td><textarea name="notices" cols="150" rows="6" onChange={inputChange} disabled={!this.state.save}> </textarea></td></tr>
              <tr><td><span dangerouslySetInnerHTML={{__html: t('serviceprotocol_hint')}}></span></td></tr>
              {this.state.save ? <tr><td width="50%" align="center"><br ></br><button onClick={() => {this.handleSave()}} className="service_save">{t("save")}</button><br /><br /></td></tr> :null}
            </tbody></table><br />
          </div>

      )
    }
  }

}

export default Serviceprotokoll;
