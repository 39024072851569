import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable"
import AlertDialog from "../user/Dialog.js";
import Translation from '../user/Translation';
import UserProfile from '../user/UserProfile'
import GetJsonFormPHP from "./api/GetJsonFormPHP";
//import TextField from '@mui/material/TextField'
import {setTableFontSize} from './api/imports.js'


import imgDetail from './img/editdetail.png'
import './styles/react-table.css';

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      geturl: "/robots/get_drives.php?type=1",
      saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      data: null, empty: null, selected: null, // Table data
      confirmVisible: false, title: "", message: null, choise: true, // OK popup and Error Messages
      edit: false,
      position: [], ref: "edit_window",
      fontSize: setTableFontSize(),
    }
    this.handleClose = this.handleClose.bind(this)
  }
  reload(newId) {
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id) {
      let data = props.row.docu
      if (!props.row.docu) data = props.row.emptyFiles
      return {
        index: props.index,
        id: props.row.id,
        data: data,
        files: props.files,
        selected: null,
        position: props.position,
      }
    }
    return null
  }
  addSelectCellFile(header, accessor, options, cellwidth = 0, show = true, align = null, cellColor = null, addempty = false, edit = true, filterable = false, value = false, mouseoverOptions = null, ratio = false, editOnNew = false) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row}) => { let lan =  row.language; return this.selectCell(id , row, initialValue, options[lan], addempty, edit || (editOnNew && row.id <= 0), value ? initialValue : null, mouseoverOptions, ratio) },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+10*em)+'px'
    e1.style.top =(window.scrollY+5*em)+'px';
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("fontSize"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    return [
      this.addSelectCellFile('§filename', 'id', this.state.files),
      //this.addTextCell('§path', 'path', 0, 6*this.state.fontSize, supportRight, "", null, null, null, false),
      this.addTextCell('§language', 'language', 0, 4*this.state.fontSize, supportRight, "", null, null, null, false),
      this.addDateCell('create_time', 'create_date', false, 5.2, true, 'center', null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 5.2*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return this.edit(row, false, true, true)
        },
      }
    ]
  }

  render() {
    //console.log("Drives", this.state.maxDrives)
    //console.log(this.state.data);
    if (this.state.id !== 0) {
      //const supportRight = UserProfile.checkRight("support")
      const columns = this.createColumns()
      const saveData = value => { this.onFormSubmit() }
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("fontSize"))
        e1.style.left = (window.scrollX+15*em)+'px'
        e1.style.top =(window.scrollY+10*em)+'px';
      }
      let data = this.state.data
      console.log(data)
      //data = [{id: 1, filename: 'Hallo', path: 'x', language: 'de', type: 'robot-docu', status: 4}]
      return (
        <Draggable handle=".handle">
        <div className="edittable edittable_large_window" id="edit_window" ref={this.state.ref}>
        <div className="header">
            <div className="header_inline handle">{t('robottype_files')}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <form onSubmit={saveData}>
          <div style={{"fontSize": this.state.fontSize+"em"}}>
          <div className="table_60">
            <ReactTable
              data={data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              useFlexLayout = {true}
              showPaginationBottom={false}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onDoubleClick: (e, t) => {
                      this.setState({ selected: rowInfo.index, })
                    },
                    style: {
                      background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                      color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                    }
                }
              }}
            />
          </div></div>
          </form>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}

class Robottypes extends TableFunctions {
  constructor() {
    super()
    this.state={
      geturl: "/robots/get_types.php",
      saveurl: "/robots/set_types.php",
      pdfUrl: "/users/upload_service_pdf.php",
      data: [], empty: null, selected: null,  // Table data
      confirmVisible: false, title: '', message: null, choise: true, // OK popup and Error Messages
      languages: ["Deutsch"], files: null,
    }
    this.closeEdit = this.closeEdit.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        emptyFiles: result.empty_files,
        files: result.files,
        customers: result.customers,
        languages: result.languages
      })
    })
  }
  closeEdit(closediv = 0) {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount()
  }

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    const suRight = UserProfile.checkRight("superuser")
    const columns = [
      { accessor: 'id', show: false,},
      this.addTextCell('name', 'name', 0, 0, true, "", null, null, null, suRight),
      this.addTextCell('loadcapacity', 'loadcapacity', 2, 0, true, "", null, null, null, suRight),
      this.addTextCell('serviceinterval_h', 'service_interval_h', 1, 0, true, "", null, null, null, suRight),
      this.addTextCell('serviceinterval_time', 'service_interval_time', 1, 0, true, "", null, null, null, suRight),
      this.addTextCell('§image', 'img', 0, 5, true, "", null, null, null, suRight),
      this.addTextCell('robottype_defaulttable', 'datatable', 0, 5, true, "", null, null, null, suRight),
      this.addTextCell('robottype_numbers', 'num', 1, 5, true, "", null, null, null, false),
      this.addTextCell('robotytype_docus', 'docu_lans', 1, 5, true, "", null, null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              showPosition: [value.clientX, value.clientY],
            })
            //console.log(this.state.showPosition, value.clientX, value.clientY)
          }
          return (
            <div>
              <img src={imgDetail} alt={t("animal_detail")} title={t("animal_detail")} onClick={getDetails}/>
              {this.edit(row, false, false, suRight)}
            </div>
          )
        },
      }
    ]
    return columns
  }

  render() {
    const columns = this.createColumns()
    const suRight = UserProfile.checkRight("superuser")
    //console.log(this.state.languages)
    return (
      <div className="table_80">
        <div className="table_buttons">
          <button onClick={() => {this.insertRow()}}>{t("addline")}</button>
          <div className="uploadform"><form action={process.env.REACT_APP_API_URL +  "/" + this.state.pdfUrl} method="post" encType="multipart/form-data" target="_blank">
            Select PDF (DOCX) to upload: &nbsp;
            <input type="file" name="fileToUpload" id="fileToUpload" />
            {t('language')} <select name="language">
              {Object.entries(this.state.languages).map((t,k) => <option key={k} value={t[1]}>{t[1]}</option>) }
            </select> &nbsp;
            <input type="hidden" name="type" value="robot-docu" />
            <input type="submit" value={t("submit")} name="submit" />
          </form></div>
        </div>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && suRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : this.getRobotColor(rowInfo.row.id),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : 'black'
                }
            }
          }}
        />
        {this.state.showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex}  files = {this.state.files} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Robottypes;
