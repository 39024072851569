import React, { Component } from 'react';
import Draggable from "react-draggable";
import { PostJsonFormPHP } from "./GetJsonFormPHP";
import Translation from '../../user/Translation';


function t(field) { return Translation.get(field) }

class ManageCols extends Component {
  constructor() {
    super();
    this.state={
      saveurl: "/users/save_fieldoptions.php?id=",
      page: "feed",
      showfields: null,
      checked: null,
    }
    this.handleClose = this.handleClose.bind(this)
    this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this)
  }
  static getDerivedStateFromProps(props, state) {
    return {
      showfields: props.showfields,
      page: props.page,
    }
  }
  handleClose() {
    this.props.closeDiv();
  }
  toggleCheckboxChange(value) {
    let fields = this.state.showfields
    for (let key in fields) { if (key === value.target.value) fields[key] = !fields[key] }
    this.setState({
      showfields: fields
    })
  }
  handleSubmit() {
    PostJsonFormPHP(this.state.saveurl+this.state.page+"list", this.state.showfields).then(response => {
      this.props.closeDiv()
    })
  }

  render () {
    //console.log(this.state.showfields)
    //console.log(typeof this.state.showfields)
    const rows = [];
    let columns = [];
    let i = 0
    for (const key in this.state.showfields) {
      if (i> 0 && i % 50 === 0) {
        rows.push(<div key={"fieldrow"+i}className={"check_new_column"}>{columns}</div>)
        columns = []
      }
      columns.push(
        <div key={key} className={"check_row"}>
          <label><input type="checkbox" value={key} checked={this.state.showfields[key]} onChange={this.toggleCheckboxChange} className="input_number_4" />{t(this.state.page + "_" + key)}</label>
        </div>
      )
      i++
    }
    rows.push(<div className={"check_new_column"}>{columns}</div>)

    /*
            <table><tbody><tr><td>
            {Object.entries(this.state.showfields).map((v,k) => {
              return (
                <div key={v[0]} className={"checkbox"}>
                  <label><input type="checkbox" value={v[0]} checked={v[1]} onChange={this.toggleCheckboxChange} />{t(this.state.page + "_" + v[0])}</label>
                </div>
              )
            })}
            </td></tr></tbody></table>
    */
    return (
      <Draggable handle=".handle">
        <div className="edittable">
          <div className="header">
            <div className="header_inline handle">{t("change_field_options")}</div>
            <div className="header_inline close"><img src={require("../img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../img/close.png")}} /></div>
          </div>
          <div className="fields">{rows}</div>
          <div className="table_buttons" align="center"><button onClick={() => {this.handleSubmit()}}>{t("save")}</button></div>
        </div>
      </Draggable>
    )
  }
}

export default ManageCols