//import React from 'react'
//import ReactDOM from 'react-dom'
import './styles/index.css'
import './styles/style.css'
//import App from './App'
import reportWebVitals from './reportWebVitals'

/*ReactDOM.render(
  <App />,
  document.getElementById('root')
)*/




import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
)
reportWebVitals()
