import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import {getStatusColors, setTableFontSize} from './api/imports.js';
import Info from "./api/Info";
import './styles/react-table.css';

function t(field) { return Translation.get(field) }

class Orders extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_orders.php",
      saveurl: "/feed/set_orders.php",
      data: [], empty: null, selected: null, // Table data
      customers: null, feed: null, silo: null, // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      showHelp: false, showHelpId: null, // Info popup
      statusColor: getStatusColors(), fontSize: setTableFontSize(),
    }
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({
        empty: result.empty,
        data: result.data,
        customers: result.customers,
        feed: result.feed,
        silo: result.silo,
      })
    });
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const userRight = UserProfile.checkRight("custuser")
    const columns = [
      { accessor: 'id',show: false,},
      { accessor: 'customer_id', show: false,},
      { accessor: 'received', show: false,},
      this.addSelectCell("feed_name", "feed_id", this.state.feed),
      this.addSelectCell("silo_name", "silo_id", this.state.silo, 15),
      this.addTextCell('order_volume', 'amount', 1, 6.5*this.state.fontSize),
      this.addTextCell('order_price', 'price', 2, 6*this.state.fontSize, true, "€"),
      this.addDateCell('order_date', 'orderdate', false, 10*this.state.fontSize, true, 'center'),
      this.addDateCell('deliver_date', 'deliverdate', false, 10*this.state.fontSize, true, 'center'),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let del = false
          if (row.received === 0 && userRight) del = true
          return this.edit(row, false, del, userRight)
        },
      }
    ]
    return columns
  }

  render() {
    const columns = this.createColumns()
    const traderRight = UserProfile.checkRight("trader")
    const userRight = UserProfile.checkRight("custuser")
    return (
      <div className={traderRight ? "table_100": "table_80"}>
        <div className="table_buttons">
          {userRight ? <button onClick={() => {this.insertRow()}}>{t("addline")}</button> : null }
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_orders_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : rowInfo.row.received === 0 ? this.getStatusBackColor(2) : this.getStatusBackColor(4),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.amount<=0 ? this.state.statusColor[7] : rowInfo.row.received === 0 ? 'black' : this.state.statusColor[4],
                }
            }
          }}
        />
        </div>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Orders;
