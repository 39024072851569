import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import {getStatusColors, setTableFontSize} from './api/imports.js';
import Info from "./api/Info";
//import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';

import './styles/react-table.css';

function t(field) { return Translation.get(field) }

class Silo extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_silo.php",
      saveurl: "/feed/set_silo.php",
      data: [], empty: null, selected: null, // Table data
      customers: null, feed: null,  // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      showHelp: false, showHelpId: null, // Info popup
      historic: false,
      fontSize: setTableFontSize(),
      statusColor: getStatusColors(),
    }
  }

  reload() {
    this.componentDidMount(this.state.historic)
  }
  componentDidMount(historic = false) {
    let addLink = ""
    if (historic) addLink = "?historic=1"
    GetJsonFormPHP(this.state.geturl+ addLink).then((result) => {
      this.setState({
        empty: result.empty,
        data: result.data,
        customers: result.customers,
        feed: result.feed,
      })
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const userRight = UserProfile.checkRight("custuser")
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'customer_id', show: false, },
      { accessor: 'status', show: false, },
      this.addTextCell('name', 'name'),
      this.addSelectCell("feed_name", "feed_id", this.state.feed),
      this.addTextCell('silo_volume', 'volume', 2, 6.5*this.state.fontSize, true, "kg"),
      this.addTextCell('silo_filling_level', 'filling_level', 1, 6*this.state.fontSize,true, "kg"),
      this.addTextCell('silo_alarm_level', 'alarm_level', 1, 6*this.state.fontSize,true, "kg"),
      this.addTextCell('silo_missing', 'fill_miss', 1, 6*this.state.fontSize, true, "kg", null, this.getColor('readOnly'), null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 8.5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          let edit = true
          if (row.status === 5) edit = false
          return this.edit(row, false, userRight, edit && userRight, false, false, false, userRight)
        },
      }
    ]
    return columns
  }

  render() {
    const showHistoric = value => {
      if (!this.state.historic) this.setState({ historic: true, })
      else this.setState({ historic: false, })
      this.componentDidMount(!this.state.historic)
    }
    const userRight = UserProfile.checkRight("custuser")
    const columns = this.createColumns()
    //console.log(this.state.data)
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    return (
      <div className="table_80">
        <div className="table_buttons">
          <button onClick={() => {showHistoric()}}>{buttonHistoric}</button>
          {userRight ? <button onClick={() => {this.insertRow()}}>{t("addline")}</button> : null }
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_silo_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          maxWidth = {600}
          sortable = {false}
          //multiSort = {true} // https://www.skypack.dev/view/react-table-6?from=pika
          expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.volume <= 0 ? this.state.statusColor[7] : this.state.statusColor[4],
                  fontSize: rowInfo.row.status > 4 ? "0.8em" : null
                }
            }
          }}
        />
        </div>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Silo;
