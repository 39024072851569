import React, { Component } from 'react'
//import { Link } from 'react-router-dom'
import { Route, Routes, Link, useLocation } from 'react-router-dom'
import Translation from '../user/Translation'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP"
import UserProfile from '../user/UserProfile'

import HomeStats from './HomeStats.js'
//import Robots from './Robots.js'
//import Aranom from './robots/Aranom.js'
//import Astor from './robots/Astor.js'
//import Athos from './robots/Athos.js'
//import Avenger from './robots/Avenger.js'
import Contact from './Contact.js'

import { useNavigate } from "react-router-dom"

function t(field) { return Translation.get(field) }

function SideBar() {
  const navigate = useNavigate()
  if (UserProfile.checkRight("admin")) {
    navigate("/profile/robots")
  }
  const location = useLocation()
  const path = location.pathname.split("/")
  let second = path[2]
  let id= null
  if (path[2] && path[2].indexOf(":") === 0) {
    console.log(path[2], "?", id)
    id = path[2].substring(1, path[2].length)
    window.sessionStorage.setItem("robot_id", id)
  }
  if (path[3] && path[3].indexOf(":") === 0) {
    if (id === null) {
      id = path[3].substring(1, path[3].length)
      //console.log(path[2], "-", id)
      window.sessionStorage.setItem("robot_id", id)
    }
    else window.sessionStorage.setItem("robot_name", path[3].substring(1, path[3].length));
  }
  //console.log(path)
  function over(e) {
    switch(e.target.id) {
      /*case "nav_animalgroup":
        e.currentTarget.src =  require("../img/navi/mouseover/animalgroup.png")
        break
      case "nav_box":
        e.currentTarget.src =  require("../img/navi/mouseover/box.png")
        break
      case "nav_route":
        e.currentTarget.src =  require("../img/navi/mouseover/route.png")
        break
      case "nav_drives":
        e.currentTarget.src =  require("../img/navi/mouseover/drives.png")
        break
      case "nav_milk":
        e.currentTarget.src =  require("../img/navi/mouseover/milk.png")
        break*/
      case "nav_contact":
        e.currentTarget.src =  require("../img/navi/mouseover/contact.png")
        break
      default:
    }
  }
  function out(e) {
    switch(e.target.id) {
      /*case "nav_animalgroup":
        if (second === "animalgroup") e.currentTarget.src = require("../img/navi/active/animalgroup.png")
        else e.currentTarget.src =  require("../img/navi/animalgroup.png")
        break
      case "nav_box":
        if (second === "boxes") e.currentTarget.src = require("../img/navi/active/box.png")
        else e.currentTarget.src =  require("../img/navi/box.png")
        break
      case "nav_route":
        if (second === "routes") e.currentTarget.src = require("../img/navi/active/route.png")
        else e.currentTarget.src =  require("../img/navi/route.png")
        break
      case "nav_drives":
        if (second === "drives") e.currentTarget.src = require("../img/navi/active/drives.png")
        else e.currentTarget.src =  require("../img/navi/drives.png")
        break
      case "nav_milk":
        if (second === "milk") e.currentTarget.src = require("../img/navi/active/milk.png")
        else e.currentTarget.src =  require("../img/navi/milk.png")
        break*/
      case "nav_contact":
        if (second === "contact") e.currentTarget.src = require("../img/navi/active/contact.png")
        else e.currentTarget.src =  require("../img/navi/contact.png")
        break
      default:
    }
  }
  let add = ""
  if (path[1] !== "home") add = "home/"
  /*<Link to={add + "Aranom"}><img id="nav_Aranom" src={require("../img/robots/HC_Aranom_r.png")} alt="Aranom" title="Aranom" className="robot" /></Link>
  <Link to={add + "Astor"}><img id="nav_Astor" src={require("../img/robots/HC_Astor_r.png")} alt="Astor" title="Astor" className="robot" /></Link>
  <Link to={add + "Athos"}><img id="nav_Athos" src={require("../img/robots/HC_Athos_r.png")} alt="Athos" title="Athos" className="robot" /></Link>
  <Link to={add + "Avenger"}><img id="nav_Avenger" src={require("../img/robots/HC_Avenger_r.png")} alt="Avenger" title="Avenger" className="robot" /></Link>*/

  return (
    <div className="nav_left_main">
      <Link to={add + "contact"} className="bottom"><img className="bottom" id="nav_contact" src={second === "contact" ? require("../img/navi/active/contact.png") : require("../img/navi/contact.png")} alt={t('nav_contact')} title={t('nav_contact')} onMouseOver={over} onMouseOut={out}/></Link>
    </div>
  )
}

class Home extends Component {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_robots.php?main=1",
      data: [],
      robottypes: [],
    }
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({
        data: result.data,
        robottypes: result.robottypes,
      })
    })
  }
  setNewRobot(id, name) {
    window.sessionStorage.setItem("robot_id", id)
    window.sessionStorage.setItem("robot_name", name)
  }

  render() {
    //<Route path="Aranom" element={<Aranom />} />
    //<Route path="Astor" element={<Astor />} />
    //<Route path="Athos" element={<Athos />} />
    //<Route path="Avenger" element={<Avenger />} />
    return (
      <div>
        <div className="nav2">
          <nav id="navigation_2">< SideBar /></nav>
        </div>
        <div className="content">
        <div className="body">
        <div className="tablebody">
          <Routes>
            <Route path="/*" element={<HomeStats />} />
            <Route path="home" element={<HomeStats />} />
            <Route path="contact" element={<Contact />}/>
          </Routes>
        </div>
        </div>
        </div>
      </div>
    )
  }
}




/*
      <div>
        <div className="nav2">
          <nav id="navigation_2">
          <div className="nav_left_main">
            <Link to="robot/animalgroup"><img id="nav_animalgroup" src={"img/HC_Aranom_r.png"} alt="Aranom" title="Aranom" className="robot" /></Link>
            <Link to="robot/animalgroup"><img id="nav_animalgroup" src={"img/HC_Astor_XXL_r.png"} alt="Astor" title="Astor" className="robot" /></Link>
            <Link to="robot/animalgroup"><img id="nav_animalgroup" src={"img/HC_Athos_XXL_r.png"} alt="Athos" title="Athos" className="robot" /></Link>
            <Link to="robot/animalgroup"><img id="nav_animalgroup" src={"img/HC_Avenger_r.png"} alt="Avenger" title="Avenger" className="robot" /></Link>
          </div>
          </nav>
        </div>
        <div className="content">
          <div className ="home_dashboard_main">
          <div className="home_dashboard" id={"dboard_" + i++}>
            <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
              <tr><td width="20%"><img id="nav_profile" src={require("../img/feeding.png")} alt={t('home_feeded')}  title={t('home_feeded')} width="50"/></td><td><Link to="stats/feeds"><b>{t("home_feeded")}</b></Link></td></tr>
            </tbody></table>
            <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
              <tr><td title={t('feed_FM')}>{t('feed_FM_short')} {t('yesterday')}</td><td align="right" title={t('yesterday')} className="lastday">{FM} kg</td></tr>
              <tr><td title={t('feed_FM')}>{t('feed_FM_short')} 10 {t('days')}</td><td align="right" title={FM_count+" Tage Schnitt"} className="day10">{FM_av} kg</td></tr>
              <tr><td title={t('feed_FM')}>{t('feed_FM_short')} {t('today')}</td><td align="right" title={t('today')} className="day10"><i>{FMToday} kg</i></td></tr>
              <tr><td title={t('feed_T')}>{t('feed_T_short')} {t('yesterday')}</td><td align="right" title={t('yesterday')} className="lastday">{TM} kg</td></tr>
              <tr><td title={t('feed_T')}>{t('feed_T_short')} 10 {t('days')}</td><td align="right" title={FM_count+" Tage Schnitt"} className="day10">{TM_av} kg</td></tr>
              <tr><td title={t('feed_T')}>{t('feed_T_short')} {t('today')}</td><td align="right" title={t('today')} className="day10"><i>{TMToday} kg</i></td></tr>
            </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options} data={data1} className="stats"/>
            </div>
          </div>
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td width="20%"><img id="nav_milk" src={require("../img/euter.png")} alt={t('nav_milk')}  title={t('nav_milk')} width="50"/></td><td><Link to="robot/milk"><b>{t("home_milk")}</b></Link></td></tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td>Ø {t('yesterday')}</td><td align="right" className="lastday">{Milk} l</td></tr>
                <tr><td>Ø 10 {t('days')}</td><td align="right" className="day10">{Milk_av} l</td></tr>
                <tr><td title={t('home_eff_long')}>{t('home_eff')} {t('yesterday')}</td><td align="right" title={"[kg "+t('home_milk')+" / kg "+t('feed_T_short')+"]"} className="lastday">{Milk_eff}</td></tr>
                <tr><td title={t('home_efflong')}>{t('home_eff')} 10 {t('days')}</td><td align="right" title={"[kg "+t('home_milk')+" / kg "+t('feed_T_short')+"]"} className="day10">{Milk_eff_av}</td></tr>
                <tr><td title={t('home_IOFC_long')}>{t("home_IOFC")} {t('yesterday')}</td><td align="right" className="lastday">{IOFC} €</td></tr>
                <tr><td title={t('home_IOFC_long')}>{t("home_IOFC")} 10 {t('days')}</td><td align="right" className="day10">{IOFC_av} €</td></tr>
              </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options2} data={data2} className="stats"/>
            </div>
          </div>
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td width="20%"><img id="nav_energy" src={require("../img/energy.png")} alt={t('nav_energy')}  title={t('nav_energy')} width="50"/></td><td><Link to="stats/energy"><b>{t("home_energy")}</b></Link></td></tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td>{t("home_feeder") + " " + t('yesterday')}</td><td align="right" title={t("home_feeder") + " " + t('yesterday')} className="lastday">{EnergyFeeder} kWh</td></tr>
                <tr><td>{t("home_feeder") + " 10 " + t('days')}</td><td align="right" title={t("home_feeder") + " 10 " + t('days')} className="day10">{EnergyFeederAv} kWh</td></tr>
                <tr><td>{t("home_robot") + " " + t('yesterday')}</td><td align="right" title={t("home_robot") + " " + t('yesterday')} className="lastday">{EnergyRobot} kWh</td></tr>
                <tr><td>{t("home_robot") + " 10 " + t('days')}</td><td align="right" title={t("home_robot") + " 10 " + t('days')} className="day10">{EnergyRobotAv} kWh</td></tr>
              </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options3} data={data3} className="stats"/>
            </div>
          </div>
        </div>

      </div>

*/
export default Home