import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js';
import { Chart, Scatter, Line } from 'react-chartjs-2'; //  Bar,
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
import { format } from 'date-fns';
ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend,);

function t(field) { return Translation.get(field) }

class ChartAnimalGroupRecipe extends Component{
  constructor() {
    super()
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-31)
    this.state={
      geturl: "/charts/animalgroup_recipe.php?group_id=",
      data: [],
      label: [],
      feeds: null,
      animalgroups: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      selected: 0,
      selectedGroup: 0,
      fixScale : true,
      maxAmount: 100,
      minAmount: -5,
      maxMilkAmount: 50,
      showScale: { milk: true, percent: false, gramm: false, price: true },
      nutrids: "0",
      TM: "0",
      showHelp: false, showHelpId: null, // Info popup
    }
    this.getData = this.getData.bind(this)
  }
  componentDidMount(feed = this.state.selected) {
    let group = this.state.selectedGroup
    if (window.sessionStorage.getItem("animalgroup_id") !== 0 && this.state.selectedGroup === 0) {
      this.setState({
        selectedGroup: window.sessionStorage.getItem("animalgroup_id"),
      })
      group = window.sessionStorage.getItem("animalgroup_id")
    }
    GetJsonFormPHP(this.state.geturl + group + "&feed=" + feed + "&start=" + this.state.startDate + "&end=" + this.state.endDate + "&TM=0").then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        animalgroups: result.animalgroups,
        maxAmount: result.max[0],
        minAmount: result.max[1],
        maxMilkAmount: result.max[2],
        selectedGroup: result.group,
        showScale: result.scales,
      })
    })
  }
  setfeedType(feed) {
    this.setState({ selected: parseInt(feed), })
    this.componentDidMount(feed)
  }
  getData(group, startDate, endDate, nutrids = this.state.nutrids, TM = this.state.TM) {
    window.sessionStorage.setItem("animalgroup_id", group)
    GetJsonFormPHP(this.state.geturl + group + "&feed=" + this.state.selected + "&start=" + startDate + "&end=" + endDate + "&nutrids=" + nutrids + "&TM=" + TM).then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        animalgroups: result.animalgroups,
        maxAmount: result.max[0],
        minAmount: result.max[1],
        maxMilkAmount: result.max[2],
        selectedGroup: group,
        showScale: result.scales,
        nutrids: nutrids,
      })
    })
  }
  getOptionsChartFix() {
    return {
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_rel_per_day"),
          font: { size: 24, },
        },
        legend: {
          position: 'bottom',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  //console.log(context.dataset)
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      elements: {
        line: { tension: 0.4 }
      },
      responsive: true,
      //categoryPercentage: 0.8,
      barPercentage: 0.9,
      scales: {
        x: { stacked: true, },
        /*xScales: {
          stacked: true,
          //position: "none",
          //categoryPercentage: 1.2,
          //barPercentage: 0.1,
        },*/
        y: {
          stacked: true,
          min: this.state.minAmount,
          max: this.state.maxAmount,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'  // '●' +
            else return ""
          } }
        },
        y1: {
          display: this.state.showScale.milk,
          min: 0,
          max: this.state.maxMilkAmount,
          position: 'right',
          grid: {
            display: true,
            color: 'rgba(0, 0, 255, 0.2)',
            borderDash: [5,5],
            lineWidth: 0.5
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              //console.log(value, index, ticks)
              if (value >= 0 ) return value + ' l' // '◆' +
            else return ""
          } },
        },
        y2: {
          min: 0,
          position: 'none',
          grid: {
            display: false,
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + ' ' // '◆' +
            else return ""
          } },
        },
      }
    }
  }
  getOptionsChartVar() {
    return {
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_rel_per_day"),
          font: { size: 24, },
        },
        legend: {
          position: 'right',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  if (context.parsed.y>0) label += context.parsed.y + " kg";
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                //return null
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      elements: {
        line: { tension: 0.4 }
      },
      categoryPercentage: 0.95,
      barPercentage: 0.9,
      responsive: true,
      scales: {
        x: { stacked: true,  },
        y: {
          stacked: true,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'
            else return ""
          } },
        },
        y1: {
          display: this.state.showScale.milk,
          position: 'right',
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' l'
            else return ""
          } },
        },
        y2: {
          min: 0,
          position: 'none',
          grid: {
            display: false,
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + ' ' // '◆' +
            else return ""
          } },
        },
      },
    }
  }
  render() {
    //console.log(this.state.endDate)
    const changeFeed = value => { this.setfeedType(value.target.value) }
    const changeGroup = value => { this.getData(value.target.value, this.state.startDate, this.state.endDate, this.state.nutrids) }
    const startChange = value => { this.setState({ startDate: value.target.value, }) }
    const endChange = value => { this.setState({ endDate: value.target.value, }) }
    const changeTM = value => { this.setState({ TM: value.target.value}); this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate, this.state.nutrids, value.target.value) }
    let options = this.getOptionsChartFix()
    if (!this.state.fixScale) options = this.getOptionsChartVar()
    let data1 = this.state.data
    //if (this.state.selected !== 0) data1 = [this.state.data.find((el, i) => i === this.state.selected-1)]
    //console.log(data1)
    let labels = this.state.label
    const data = {
      labels,
      datasets: data1,
    }
    labels = ['0', '1']
    const data2 = {
      labels,
      datasets: [
        {
          label: 'Dataset 1',
          borderColor: 'rgb(255, 99, 132)',
          borderWidth: 2,
          fill: false,
          data: [1,2],
        },
      ],
    }
    return (
      <div>
        <table width="100%" border="0">
          <thead><tr>
            <td>{t("choose")} {t("chart_select_feed")}</td>
            <td>{t("chart_animalgroups")}</td><td>{t("start_date")}</td><td>{t("end_date")}</td><td> </td><td> </td><td> </td><td> </td>
          </tr></thead>
          <tbody><tr>
            <td>
              <select name="feedlist" value={this.state.selected} onChange={(value) => {changeFeed(value)}} style={null} className="chartselect">
                { (this.state.feeds !== null) ? Array.isArray(this.state.feeds) ? Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>)
                  : Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td>
            <select name="grouplist" value={this.state.selectedGroup} onChange={(value) => {changeGroup(value)}} className="chartselect">
              { (this.state.animalgroups !== null) ? Array.isArray(this.state.animalgroups) ? Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, backgroundColor: t[1].bgcolor, fontWeight: t[1].weight} : null }>{t[1].label}</option>)
                  : Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={startChange} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={endChange} className="datefield"/></td>
            <td>
              <nobr><input type="radio" id="FM" name="TM" value="0" checked={this.state.TM === "0" ? true : false} onChange={changeTM} /><label htmlFor="FM">{t('feed_FM')}  ({t('feed_FM_short')})</label></nobr><br />
              <nobr><input type="radio" id="TM" name="TM" value="1" checked={this.state.TM === "1" ? true : false} onChange={changeTM} /><label htmlFor="TM">{t('feed_T')} ({t('feed_T_short')})</label></nobr>
            </td>
            <td align="center"><nobr>{this.state.nutrids === "0" ? <span><input id="fix_scale" type="checkbox"defaultChecked={this.state.fixScale}  onChange={() => {this.setState({ fixScale: !this.state.fixScale, })}}/>{t("chart_fix_scale")}</span> : null }</nobr></td>
            <td width="15%" align="center"><button onClick={() => {this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate)}} className="button">{t("show")}</button></td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_animals_chart_recipe", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>
        <Line data={data2} className="hidden"/>
        <Scatter data={data2} className="hidden"/>
        <Chart type="bar" options={options} data={data} className="stats"/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}

export default ChartAnimalGroupRecipe;