import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import Draggable from "react-draggable";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP from "./api/GetJsonFormPHP";
import Info from "./api/Info";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import getStatusVars, {getStatusColors, getFeedColors, setTableFontSize} from './api/imports.js';
import './styles/tablestyles.css';

import './styles/react-table.css';
import imgDetail from './img/editdetail.png';
import imgTrue from './img/true.gif';
import imgAttention from './img/attention.png';
import imgArrow from './img/arrow_right.png';
import imgOk from './img/ok.png';
import imgFalse from './img/false2.png';
import imgWarnOnline from './img/careful.png'
//import imgCopy from './img/copy.png'

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super();
    this.state = {
      geturl: "/feed/get_recipe_feeds.php",
      saveurl: "/feed/set_recipe_feeds.php",
      data: [], empty: null, selected: null,  // Table data
      position: [], position_offset: [45,2],  // Window postitioning
      languages: null, customers: null, // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      feedColor: getFeedColors(), fontSize: setTableFontSize(),  // Status and colors
      nutrids: [], feedMax: null,
      id: null,
      row: null,
      edit: false,
    };
    this.handleClose = this.handleClose.bind(this)
    this.reload = this.reload.bind(this)
  }
  reload(newId) {
    //this.props.reload(newId)
    let id = this.state.id
    if (newId) id = newId
    GetJsonFormPHP(this.state.geturl + "?id=" + id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      //console.log(this.state.geturl + "?id=" + id, result)
      this.setState({
        data: result.data,
        nutrids: result.nutrids,
        selected: null,
      })
    })
    this.props.reload(newId)
  }
  handleClose() {
    this.props.closeDiv()
  }
  static getDerivedStateFromProps(props, state) {
    const userRight = UserProfile.checkRight("custuser")
    if (state.id !== props.row.id) {
      const feeds = props.feed
      let empty = []
      empty = { id: -1, recipe_id: props.row.id, robotfeed_org_id: 0, price: 0, position: 1, amount: 0}
      Object.entries(feeds).map((t,k) => {if (empty.robotfeed_org_id === 0) {empty.robotfeed_org_id = t[1]['value']; empty.feed_name = t[1]['label']; } return null})
      let edit = false
      if (props.row.id > 0 && props.row.status <= 4 && userRight) edit = true
      if (props.row.used === 1) edit = false
      let feed = []
      Object.entries(props.feed).map((t,k) => {
        const f = {value: t[0], label: t[1], color: state.feedColor[1]}
        feed = [...feed, f]
        return null}
      )
      console.log(empty)
      console.log(props.row.feeds)
      return {
        id: props.row.id,
        row: props.row,
        data: props.row.feeds,
        nutrids: props.row.nutrids,
        languages: props.languages,
        customers: props.customers,
        empty: empty,
        feed: props.feed,
        edit: edit,
        selected: null,
        position: props.position,
        feedMax: props.feedMax,
      }
    }
    return null
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+10*em)+'px'
    e1.style.top =(window.scrollY+5*em)+'px';
    /*let e1 = document.getElementById("edit_window")
    e1.style.left = (this.state.position[0]-this.state.position_offset[0]*em)+'px';
    e1.style.top =(this.state.position[1]-this.state.position_offset[1]*em)+'px';*/
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    let columns = [
      { accessor: 'id', show: false,},
      { accessor: 'recipe_id', show: false,},
      { accessor: 'feedtype', show: false,},
      { accessor: 'status', show: false,},
      //this.addSelectCell("feed_type", "robotfeed_org_id", this.state.feed, 8.5),
      //this.addTextCell('name', 'feed_name', 1, 6),
    ]
    if (this.state.edit) columns = [...columns, this.addSelectCell("feed_type", "robotfeed_org_id", this.state.feed)]
    else columns = [...columns, this.addTextCell('name', 'feed_name', 1, 0, true, "", "left"),]
    columns = [...columns,
      this.addTextCell('robotfeed_sequence', 'position', 1, 4*this.state.fontSize, true, '', null, this.getColor('readOnly'), null, false),
      this.addTextCell('feed_amount', 'amount', 2, 4*this.state.fontSize, true, "kg"),
      //this.addNumberCell('feed_amount', 'amount', 2, 0, 50, 4, true, "kg"),
      this.addTextCell('feed_price', 'price', 1, 4*this.state.fontSize, true, "€", null, this.getColor('readOnly'), null, false),
      { accessor: 'status', show: false,},
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 7.5*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          if (!row.status || row.status < 5) return this.edit(row, false, this.state.edit, this.state.edit)
          else return this.edit(row,  this.state.edit,  this.state.edit, false)
        },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? 'lightgrey' : null } }),
      }
    ]
    return columns
  }
  createColumnsNutrids() {
    return [
      this.addTextCell('name', 'name', 0, 7.5*this.state.fontSize, true, "", null, null, null, false),
      this.addTextCell('feed_nutrid_base_value', 'value', 1, 4*this.state.fontSize, true, "", null, null, null, false),
      this.addTextCell('feed_unit', 'unit', 0, 5*this.state.fontSize, true, "", null, null, null, false),
      this.addTextCell('feed_sum', 'sum', 2, 5*this.state.fontSize, true, "kg", null, null, null, false),
    ]
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    //console.log("data", this.state.data)
    //console.log("nutrids", this.state.nutrids)
    const columns = this.createColumns()
    const columnsNutrids = this.createColumnsNutrids()
    if (this.state.id !== 0) {
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
        e1.style.left = (window.scrollX+15*em)+'px'
        e1.style.top =(window.scrollY+10*em)+'px';
        //e1.style.left = (this.state.position[0]-this.state.position_offset[0]*em)+'px';
        //e1.style.top = (this.state.position[1]-this.state.position_offset[1]*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
          <div className="header">
            <div className="header_inline handle">{t('animals_org_recipe_id')}: {this.state.row.name}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <div>
            <table className="recipe_feeds"><tbody><tr><td valign="top">
              <div style={{fontSize: this.state.fontSize+"em"}}>
              <ReactTable
                data = {this.state.data}
                columns = {columns}
                minRows = {0}
                previousText = {'<'}
                nextText = {'>'}
                sortable = {false}
                useFlexLayout = {true}
                showPaginationBottom = {false}
                getTrProps = {(state, rowInfo, column, instance) => {
                  return {
                      onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && (!rowInfo.row.status || rowInfo.row.status < 5) && rowInfo.row.status !== 1 && userRight) { console.log(rowInfo.row.status); this.setState({ selected: rowInfo.index, }) } },
                      style: {
                        background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : rowInfo.row.position === -1 ? '#FFCCCC' : 'white',
                        color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : rowInfo.row.position === -1 ? '#999999' : this.state.feedColor[rowInfo.row.feedtype]
                      }
                  }
                }}
              />
              </div>
            </td>
            <td valign="top">
            <div style={{fontSize: this.state.fontSize+"em"}}>
              <ReactTable
                data = {this.state.nutrids}
                columns = {columnsNutrids}
                minRows = {0}
                previousText = {'<'}
                nextText = {'>'}
                sortable = {false}
                useFlexLayout = {true}
                showPaginationBottom = {false}
                getTrProps = {(state, rowInfo, column, instance) => {
                  return {
                      style: { background: '#F9F5E0', }
                  }
                }}
              />
              </div>
            </td></tr></tbody></table>
          </div>
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}

class Recipes extends TableFunctions {
  constructor() {
    super()
    this.state={
      geturl: "/feed/get_recipe.php",
      saveurl: "/feed/set_recipe.php",
      updateurl: "/feed/send_recipe.php",
      data: [], empty: null, selected: null,  // Table data
      customers: null, feed: null, feedMax: null, // Select input options
      confirmVisible: false, title: null, message: null, choise: true, yesNo: [t('no'), t('yes')],  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), fontSize: setTableFontSize(),  // Status and colors
      showHelp: false, showHelpId: null, // Info popup
      showHideEdit: false, selectedRow: null, advancedIndex: null, showPosition: [],   // Advanced insert window
      historic: 0, archive: false,
      robotOnline: 0,
    }
    this.closeFeeds = this.closeFeeds.bind(this)
    this.reload = this.reload.bind(this)
  }
  reload(newId) {
    this.componentDidMount(this.state.historic, newId)
  }
  componentDidMount(historic = 0, newId = null, archive = false) {
    let addLink = ""
    if (historic) addLink = "?historic=1"
    else if (archive)  addLink = "?archive=1"
    GetJsonFormPHP(this.state.geturl+addLink).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      if (newId != null) {
        result.data.map((t,k) => { if (parseInt(newId) === parseInt(t.id)) this.setState({ selectedRow: t, advancedIndex: k,}); return null})
      }
      this.setState({
        data: result.data,
        customers: result.customers,
        feed: result.feed,
        selected: null,
        feedMax: result.feed_max,
        //showHideEdit: false,
        robotOnline: result.robot_online,
      })
    })
  }
  showHistoric() {
    if (this.state.historic === 0) this.setState({ historic: 1, archive: false})
    else this.setState({ historic: 0, })
    this.componentDidMount(!this.state.historic)
  }
  closeFeeds() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount(this.state.historic)
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'used', show: false,},
      { accessor: 'sps_nr',
        Header: t('recipe_sps_nr'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right" } }),
        Cell: ({value: initialValue, column: { id }, row,}) => { if (supportRight) return this.textCell(initialValue, id, row, 1); else return parseInt(initialValue) < 0 ? null : initialValue },
        width: 3.8*em,
      },
      this.addSelectCell("customer", "customer_id", this.state.customers, 8*this.state.fontSize, false, null, this.getColor('cellAdmin')),
      this.addTextCell('recipe_name', 'name'),
      this.addSelectCell("recipe_screw", "screw_no_return", this.state.yesNo, 4*this.state.fontSize, this.state.showfields ? this.state.showfields['screw_no_return'] : true, "center", null, false, true, false, false, null, true),
      { accessor: 'feed_text', show: false,},
      { accessor: 'num_feeds',
        Header: t('recipe_numfeeds'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right", backgroundColor: this.getColor('readOnly') } }),
        Cell: ({value: initialValue, column: { id }, row,}) => { return (<div title={row.feed_text}>{initialValue}</div>) },
        width: 5*em,
      },
      this.addTextCell('recipe_amount', 'amount', 1, 5*this.state.fontSize, true, " kg", null, this.getColor('readOnly'), null, false),
      this.addTextCell('recipe_TM_amount', 'TM_amount', 1, 5*this.state.fontSize, true, " kg", null, this.getColor('readOnly'), null, false),
      this.addTextCell('recipe_price', 'price', 1, 6*this.state.fontSize, true, "€", null, this.getColor('readOnly'), null, false),
      this.addTextCell('recipe_volume', 'volume', 2, 6*this.state.fontSize, true, "m<sup>3</sup>", null, this.getColor('readOnly'), null, false),
      { accessor: 'check',
        Header: t('recipe_check'),
        Cell: ({value: initialValue, column: { id }, row,}) => {
          if (row.check !== null && row.amount > 0 && row.used < 10) {
            if (row.status === 3) return (<div width="100%" align="center"><img src={imgArrow} alt={t("-->")} title={t("transfer_to_robot")} /></div>)
            else if (row.status >= 4) {
              if (row.status === 4)return (<div width="100%" align="center"><img src={imgTrue} alt={t("status_active")} title={t("status_active")} /></div>)
              //if (row.status > 4) return (<div width="100%" align="center">--</div>)
              if (row.status > 4) return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
            }
            else return (<div width="100%" align="center"><img src={imgOk} alt={t("ok")} title={t("ok")} /></div>)
          }
          else {
            let title = ""
            if (row.id < 0) title= title + t("animals_not_saved")
            if (row.drives === null) title= title + t("animals_drives_missing")
            if (row.amount <= 0) {
              if (title !== "") title = title + "\n"
              title = title + t("recipe_no_feeds")
            }
            if (row.used === 10) title= title + t("animals_deleted_feeds")
            return (<div width="100%" align="center"><img src={imgAttention} alt={title} title={title} /></div>)
          }
        },
        width: 4.5*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: "center", backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : this.getColor('readOnly') } }),
      },
      this.addSelectCell("status", "status", this.state.status, 5*this.state.fontSize, supportRight, 'center', this.getColor('cellAdmin')),
      this.addTextCell('last_user_name', 'user', 0, 6*this.state.fontSize, supportRight, "", null, null, null, false),
      this.addDateCell('create_time', 'create_time', false, 5.6*this.state.fontSize, true, 'center', this.getColor('readOnly'), null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: (supportRight ? 10.4 : 8)*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              showPosition: [value.clientX, value.clientY],
            })
          }
          let del = false, edit = false, update = false, archiv = false
          if (row.id > 0 && row.status <= 4) edit = true
          if ((row.status === 1 || row.status === 2) && this.state.selected !== row.id) update = true
          if (row.status === 4 && row.used === 0) archiv = true
          if (row.status < 2) del = true
          if (row.used === 1) edit = false
          //console.log(edit, row)
          return (
            <div>
              {row.id > 0 ? <img src={imgDetail} alt={t("feed_detail")} title={t("feed_detail")} onClick={getDetails}/> : null}
              {this.edit(row, false, del && userRight, edit && userRight, update && userRight, archiv && userRight)}
              {row.status < 4 && this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null}
            </div>
          )
        },
      }
    ]
    return columns
  }

  render() {
    //console.log(window.sessionStorage.getItem("robot_load"))
    //const closeHelp = value => { this.showHideHelp(false) }
    const columns = this.createColumns()
    const supportRight = UserProfile.checkSupport()
    const userRight = UserProfile.checkRight("custuser")
    const { showHideEdit } = this.state;
    let buttonHistoric = t('show_history')
    if (this.state.historic === 1) buttonHistoric = t('hide_history')
    let buttonArchive = t('show_archive')
    return (
      <div className={supportRight ? "table_100": "table_100"}>
        <div className="table_buttons">
          {userRight ? <button className="only_support" onClick={() => {this.insertRow()}}>{t("addline")}</button> : null}
          <button onClick={() => {this.showHistoric()}}>{buttonHistoric}</button>
          {supportRight ? <button className="only_support" onClick={() => {this.setState({ historic: false, archive: !this.state.archive, }); this.componentDidMount(false, null, !this.state.archive)}}>{buttonArchive}</button> : null}
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_recipe_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {25}
          pageSizeOptions = {[25, 50, 100]}
          //useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                  //color: rowInfo && rowInfo.index === this.state.selected ? 'white' : this.state.statusColor[rowInfo.row.status],
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.id < 0 ? 'red' : this.getStatusColor(rowInfo.row.status),
                  fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                }
            }
          }}
        />
        </div>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} languages = {this.state.languages} customers = {this.state.customers} feed = {this.state.feed} feedMax = {this.state.feedMax} position = {this.state.showPosition} closeDiv={this.closeFeeds} reload={this.reload}  />}
      </div>
    )
  }
}
export default Recipes;
