import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import GetJsonFormPHP from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import getStatusVars, {getStatusColors, getFeedColors, getFeedBackColors, setTableFontSize} from './api/imports.js';
import AlertDialog from "../user/Dialog.js";
import dateFormat from 'dateformat';
import FileUpload from "../user/Upload.js";
import Info from "./api/Info";
import ManageCols from "./api/ManageFields";

import './styles/react-table.css';
//import imgTrue from './img/true.gif';
//import imgAttention from './img/attention.png';
import imgCopy from './img/copy.png';

function t(field) { return Translation.get(field) }

class Feed extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_feed.php",
      saveurl: "/feed/set_feed.php",
      updateurl: "/feed/send_feed.php",
      orgData: [], data: [], empty: null, selected: null, // Table data
      customers: null, density: null, densityMouseover: null, fillingkind: null, feedbase: null, feedtype: null, units: ['g', 'kg'], // Select input options
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), feedColor: getFeedColors(), fontSize: setTableFontSize(),  // Status and colors
      showHelp: false, showHelpId: null, // Info popup
      historic: false,
      showfields: null, manageFields: false,
      showHideLoad: 0,
      showBase: false,
      filterFeed: "",   // Filter Options
      archive: false,
    }
    this.closeDiv = this.closeDiv.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
  }
  reload(historic) {
    this.componentDidMount(historic)
  }
  componentDidMount(historic, archiv = this.state.archive) {
    let addLink = ""
    if (historic) addLink = "?historic=1"
    if (archiv) addLink = "?archiv=1"
    GetJsonFormPHP(this.state.geturl+addLink).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        orgData: result.data,
        customers: result.customers,
        density: result.density,
        densityMouseover: result.density_mouseover,
        fillingkind: result.fillingkind,
        feedtype: result.feedtype,
        feedbase: result.feedbase,
        showfields: result.showfields,
        showBase: false,
      })
      if (this.state.filterFeed !== "") this.setfilter()
    });
  }
  showHistoric() {
    this.setState({ historic: !this.state.historic, archive: false, })
    this.componentDidMount(!this.state.historic, false)
  }
  checkUsage() {
    GetJsonFormPHP(this.state.checkurl).then((result) => {
      this.componentDidMount(this.state.historic);
    })
  }
  closeDiv() {
    this.setState({
      manageFields: false,
      showHideLoad: 0,
      selected: null,
    })
    this.componentDidMount()
  }
  addCol(name, isInt = 1) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return {
      Header: t('feed_' + name),
      columns: [{
        accessor: name,
        show: this.state.showfields ? this.state.showfields[name] : true,
        Header: name,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, isInt) },
        getProps: (state, rowInfo) => ({ style: { textAlign: "right", backgroundColor:  rowInfo.row.global === -1 ? "#AAAAAA" : rowInfo.row.status >= 5 ? 'lightgrey' : '#F9F5E0', color: !rowInfo.row.used ? "#999999": null } }),
        //getProps: (state, rowInfo, column) => { return { textAlign: "right", style: { color: !rowInfo.row.used ? "#999999": null } } },          width: 4.5*em,
        width: 4.5*em,
      }]
    }
  }
  setfilter(value) {
    let filter1 = this.state.filterFeed
    if (value && value.target.name === "filter_feedname") filter1 = value.target.value
    let  a = []
    if (filter1 !== "") {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.name.toLowerCase().includes(filter1.toLowerCase()) || t.id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    //console.log(a)
    this.setState({
      data: a,
      filterFeed: filter1,
      selected: null,
    })
  }
  upload(row) {
    console.log(row)
    this.setState({
      showHideLoad: row.id,
    })
  }
  handleCopy(row) {
    //row.action="COPY"
    //console.log(row)
    console.log("saveurl:", this.state.saveurl + "?copyID=" + row.id)
    GetJsonFormPHP(this.state.saveurl + "?copyID=" + row.id).then((result) => {
      if (result.error) {
        this.setState({
          confirmVisible: true,
          title: t("error"),
          message: t(result.error),
          choise: false,
          selected: null,
        })
      }
      this.reload()
    })
  }

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'block',
      backgroundColor: color,
      borderRadius: 0.5*em,
      content: '" "',
      //display: 'block',
      marginTop: 0.25*em,
      marginLeft: 0.5*em,
      height: 0.5*em,
      width: 0.5*em,
    })
    return [
      { accessor: 'id', show: false,},
      { accessor: 'org_id', show: false,},
      { accessor: 'used', show: false,},
      { accessor: 'global', show: false,},
      { accessor: 'customer_id', show: false,},
      { accessor: 'status', show: false,},
      { Header: "",    // feedtype
        columns: [{
          accessor: 'feedtype',
          Header: '',
          selector: (row, index) => index+1,
          Cell: ({value: initialValue, column: { id }, row,}) => {
            if (this.state.selected === row._index) return this.selectCell("feedtype", row, row.feedtype, this.state.feedtype)
            else return <span style={dot(this.state.feedColor[row.feedtype])}></span>
          },
          width: 2*em,
        }]
      },{ Header: t('feed_name'),
        columns: [{
          accessor: 'name',
          Header: 'ADR-Bezeichung',
          selector: (row, index) => index+1,
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row) },
          getProps: (state, rowInfo) => ({ style: { color: !rowInfo.row.used ? "#999999": null, } }),
        }]
      },{ Header: t('feed_bases_on'),
        columns: [{
          show: this.state.showBase,
          accessor: 'base_id',
          selector: (row, index) => index+1,
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.selectCell("base_id", row, row.base_id, this.state.feedbase) },
          getProps: (state, rowInfo) => ({ style: { color: !rowInfo.row.used ? "#999999": null, } }),
        }]
      },{ Header: t('robotfeed_fillingkind_id'),
        show: this.state.showfields ? this.state.showfields['robotfeed_fillingkind_id'] : true,
        columns: [{
          accessor: 'fillingkind_id',
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.selectCell("fillingkind_id", row, row.fillingkind_id, this.state.fillingkind) },
          getProps: (state, rowInfo) => ({ style: { textAlign: "center", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 6*em,
        }]
      },{ Header: t('feed_feeddensity_id'),
        show: this.state.showfields ? this.state.showfields['feeddensity_id'] : true,
        columns: [{
          accessor: 'feeddensity_id',
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.selectCell("feeddensity_id", row, row.feeddensity_id, this.state.density, null, true, null, this.state.densityMouseover) },
          getProps: (state, rowInfo) => ({ style: { textAlign: "center", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 6*em,
        }]
      /*},{ Header: t('feed_unit'),  //this.addSelectCell("feed_unit", "unit", this.state.units, 4, true, "center"),
        show: this.state.showfields ? this.state.showfields['robotfeed_fillingkind_id'] : true,
        columns: [{
          accessor: 'unit',
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.selectCell("unit", row, row.unit, this.state.units) },
          getProps: (state, rowInfo) => ({ style: { textAlign: "center", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 6*em,
        }]*/
      },{ Header: t('feed_price'),
        columns: [{
          accessor: 'price',
          Header: '[€/100kg]',
          show: this.state.showfields ? this.state.showfields['price'] : true,
          Cell: ({value: initialValue, column: { id }, row,}) => {  if (row.global >= 0) return this.textCell(initialValue, id , row, 2, "€") },
          getProps: (state, rowInfo) => ({ style: { textAlign: "right", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 5*em,
        }]
      },{ Header: t('feed_maxinsert'),
        columns: [{
          accessor: 'maxinsert',
          show: this.state.showfields ? this.state.showfields['maxinsert'] : true,
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, 1) },
          getProps: (state, rowInfo) => ({ style: { textAlign: "right", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 5*em,
        }]
      },{ Header: t('feed_T'),
        columns: [{
          accessor: 'T',
          Header: 'T',
          show: true,
          Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, 1) },
          getProps: (state, rowInfo) => ({ style: { textAlign: "right", color: !rowInfo.row.used ? "#999999": null, } }),
          width: 4.5*em,
        }]
      },
      //this.addCol('T'),
      this.addCol('XA'),
      this.addCol('OM'),
      this.addCol('XP'),
      this.addCol('XL'),
      this.addCol('XF'),
      this.addCol('XX'),
      this.addCol('XS'),
      this.addCol('XZ'),
      this.addCol('XS_XZ_'),
      this.addCol('BXS_'),
      this.addCol('KH'),
      this.addCol('BXS'),
      this.addCol('XZ_UXS'),
      this.addCol('SXF'),
      this.addCol('CA', 2),
      this.addCol('P', 2),
      this.addCol('Nitrat', 2),
      this.addCol('NA', 2),
      this.addCol('MG', 2),
      this.addCol('KA', 2),
      this.addCol('S', 2),
      this.addCol('CL', 2),
      this.addCol('DCAB', 2),
      this.addCol('VOM'),
      this.addCol('VXP'),
      this.addCol('VXL'),
      this.addCol('VXF'),
      this.addCol('VXX'),
      this.addCol('UDP'),
      this.addCol('HFT'),
      this.addCol('ELOS', 2),
      this.addCol('NXP'),
      this.addCol('RNB', 2),
      this.addCol('ME', 2),
      this.addCol('NEL', 2),
      this.addCol('GE', 2),
      this.addCol('FE'),
      this.addCol('MN'),
      this.addCol('CU'),
      this.addCol('CO', 2),
      this.addCol('SE', 2),
      this.addCol('ZN'),
      this.addCol('J', 2),
      this.addCol('MO', 2),
      this.addCol('A'),
      this.addCol('D'),
      this.addCol('E'),
      this.addCol('B1'),
      this.addCol('B'),
      this.addCol('NIA'),
      this.addCol("LYS"),
      this.addCol("MET"),
      this.addCol("NDF"),
      this.addCol("NDF_ORG", 2),
      this.addCol("ADL"),
      this.addCol("ADF"),
      this.addCol("ADF_ORG", 2),
      this.addCol("NFC"),
      this.addCol("PE"),
      this.addCol("SXP", 2),
      this.addCol("UIP", 2),
      this.addCol("DIP", 2),
      this.addCol("SW", 2),
      this.addCol("BXS_P"),
      this.addCol("KHG"),
      this.addCol("KHA"),
      this.addCol("XPG"),
      this.addCol("XPA"),
      this.addCol("CFXP", 2),
      this.addCol("BSK1"),
      this.addCol("BSK2"),
      this.addCol("BSK3"),
      this.addCol("BSK4"),
      this.addCol("BSK5"),
      this.addCol("BEW_ART"),
      this.addCol("FW_EDIT"),
      this.addCol("NO_AKTIV"),
      this.addCol("NR_FURAT"),
      this.addCol("IDATUM"),
      this.addCol("L1"),
      this.addCol("D3_OS"),
      this.addCol("B2_OS"),
      this.addCol("B6_OS"),
      this.addCol("K1_OS"),
      this.addCol("B12_OS"),
      this.addCol("C_OS"),
      this.addCol("Biotin_OS"),
      this.addCol("B5_OS"),
      this.addCol("B3_OS"),
      this.addCol("Betain"),
      this.addCol("Glukose"),
      this.addCol("Fruktose"),
      this.addCol("Saccharose"),
      this.addCol("Fruktane"),
      this.addCol("Sum_WKL"),
      this.addCol("NDIXP"),
      this.addCol("Cystin"),
      this.addCol("Threonin"),
      this.addCol("Valin"),
      this.addCol("Histidin"),
      this.addCol("As"),
      this.addCol("Pb"),
      this.addCol("Cd"),
      this.addCol("HG"),
      this.addCol("PNDF"),
      this.addCol("pesinunl_XP"),
      this.addCol("Afraktion"),
      this.addCol("B1Fraktion"),
      this.addCol("B2Fraktion"),
      this.addCol("B3Fraktion"),
      this.addCol("Cfraktion"),
      this.addCol("UDP5"),
      this.addCol("EssigS"),
      this.addCol("PropionS"),
      this.addCol("isoButterS"),
      this.addCol("nButterS"),
      this.addCol("IsoValerianS"),
      this.addCol("nValerianS"),
      this.addCol("MilchsS"),
      this.addCol("Ethanol"),
      this.addCol("12Propan"),
      this.addCol("Propanol"),
      this.addCol("NH3zuN"),
      this.addCol("pepsinunlzuXP"),
      this.addCol("Proeinlos"),
      this.addCol("Gelbkeime"),
      this.addCol("Pseudomona"),
      this.addCol("Enterobacter"),
      this.addCol("aerob_Bak"),
      this.addCol("KG1"),
      this.addCol("Kokken"),
      this.addCol("Staphylok"),
      this.addCol("Bacillus_sp"),
      this.addCol("KG2"),
      this.addCol("KG3"),
      this.addCol("Fusarium"),
      this.addCol("Acremonium"),
      this.addCol("Aureobasidium"),
      this.addCol("Schwartz"),
      this.addCol("Ustilago"),
      this.addCol("Verticillium"),
      this.addCol("KG4"),
      this.addCol("Penicilium"),
      this.addCol("Geotrichum"),
      this.addCol("Aspergillus"),
      this.addCol("Monascus"),
      this.addCol("Scopulariopsis"),
      this.addCol("Wallemia"),
      this.addCol("KG5"),
      this.addCol("Mucorales"),
      this.addCol("Rhizopus"),
      this.addCol("KG6"),
      this.addCol("Candida"),
      this.addCol("Saccharomy"),
      this.addCol("KG7"),
      this.addCol("Konserv"),
      this.addCol("Pufferkap"),
      this.addCol("ZEA"),
      this.addCol("DON"),
      this.addCol("OCHRA"),
      this.addCol("T2/HT2"),
      this.addCol("Aflatoxin"),
      this.addCol("Fuminosin"),
      this.addCol("ZEA_ELISA"),
      this.addCol("DON_ELISA2"),
      this.addCol("XS3h"),
      this.addCol("XS7h"),
      this.addCol("NDFD30h"),
      this.addCol("NDFD120h"),
      this.addCol("NDFD240h"),
      this.addCol("NDFD12h"),
      this.addCol("NDFD72h"),
      this.addCol("uNDFom30"),
      this.addCol("uNDFom120"),
      this.addCol("uNDFom240"),
      this.addCol("BA"),
      this.addCol("DLG"),
      this.addCol("DdXP"),
      { Header: t('create_time'),
        columns: [{
          accessor: 'last_update',
          //show: supportRight,
          show: true,
          getProps: (state, rowInfo) => ({ style: { color: "#999999" } }),
          Cell: ({value: initialValue, column: { id }, row,}) => { if (row.global >= 0) return (<span>{dateFormat(initialValue, t('dateformat'))}</span>) }, //  + " HH:MM"
          width: 5.6*em,
        }]
      },{ Header: <div className="admin">{t('last_user_name')}</div>,
        columns: [{
          accessor: 'user',
          show: supportRight,
          getProps: (state, rowInfo) => ( rowInfo && rowInfo.row.global >= 0 ? { style: { backgroundColor: '#F9EEEE', color: "#999999" } } : ""),
          width: 6*em,
        }]
      },{ Header: t('table_edit'),
        columns: [{
          accessor: 'edit',
          width: 10.4*em,
          resizable: false,
          sortable: false,
          Cell: ({value: initialValue, column: { id }, row,}) => {
            const copyEntry = value => { this.handleCopy(row) }
            if (row.global >= 0) {
              let del = false, edit = false, archiv = false, upload = false // update = false,
              if (row.status < 2) del = true
              if (row.id > 0 && row.status <= 4) edit = true
              //if ((row.status === 1 || row.status === 2) &&  this.state.selected !== row.id) update = true
              if (row.status === 4 && !row.used) archiv = true
              if (supportRight) edit = true
              if (supportRight || row.global === 0) upload = true
              return (
                <div>
                  {this.edit(row, false, del && userRight, edit && userRight, false, archiv && userRight, upload && userRight)}
                  {this.state.selected !== row._index && userRight ? <img src={imgCopy} alt={t("copy")} title={t("copy")} onClick={copyEntry}/> : null}
                </div>
              )
            }
            else return null
          },
        }]
      }
    ]
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    const columns = this.createColumns()
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    let buttonArchive = t('show_archive')
    //if (this.state.showfields) console.log(this.state.showfields)
    const filterChange  = value => { this.setfilter(value) }
    return (
      <div>
        <div className="table_buttons">
          <button className={this.state.historic ? "button_active" : null} onClick={() => {this.showHistoric()}}>{buttonHistoric}</button>
          <button onClick={() => {this.setState({ manageFields: true, })}}>{t("show_advanced")}</button>
          { userRight ? <button onClick={() => {  this.state.showBase = true; this.insertRow()}}>{t("addline")}</button> : null }
          <button className={this.state.archive ? "button_active" : null} onClick={() => {this.setState({ historic: false, archive: !this.state.archive, }); this.componentDidMount(false, !this.state.archive)}}>{buttonArchive}</button>
          <span>
            {t('filter_feedname')}:&nbsp;
            <input type="text" name="filter_feedname" value={this.state.filterField} onChange={filterChange} onKeyDown={filterChange}/> &nbsp;
          </span>
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_feed_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          //headerHeight={[30, 40, 50]}
          //headerRenderer={headerRenderer}
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {25}
          pageSizeOptions = {[10, 25, 50, 100]}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                //onDoubleClick: (e, t) => {console.log(rowInfo.index,"!==", this.state.selected, rowInfo.row.status, ); if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && !rowInfo.row.used && userRight) { this.setState({ selected: rowInfo.index, }) } },
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' :  rowInfo.row.status === 4 ? getFeedBackColors()[rowInfo.row.feedtype] : "lightgrey",
                  //background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : rowInfo.row.global === -1 ? "#AAAAAA" : rowInfo.row.global === 1 ? '#EEEEFF' : rowInfo.row.status >= 5 ? 'lightgrey' :'white',
                  //color: rowInfo && rowInfo.index === this.state.selected ? 'white' : this.state.feedColor[rowInfo.row.feedtype],
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.global === 1 ? "blue" : rowInfo.row.status > 4 ? '#666666' : this.state.feedColor[rowInfo.row.feedtype],
                  //fontWeight: rowInfo.row.global === 1 ? "bold" : null,
                  fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                }
            }
          }}
        />
        </div>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.manageFields && <ManageCols showfields={this.state.showfields} closeDiv={this.closeDiv} page={"feed"}/>}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.showHideLoad > 0 && <FileUpload id={this.state.showHideLoad} title={t('feed_upload')} files={'.ads'} upload="feed/load_ingredient.php" closeDiv={this.closeDiv} />}
      </div>
    )
    //        {this.state.showHideEdit && <ManageFeedFields showfields={this.state.showfields} closeDiv={this.closeDiv} />}
  }
}
export default Feed;
