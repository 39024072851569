import React, { Component } from 'react';
import Draggable from "react-draggable";
import GetJsonFormPHP from "./GetJsonFormPHP";
//import Translation from '../../user/Translation';

//import imgClose from '../img/close.png';

//function t(field) { return Translation.get(field) }

class Info extends Component {
  constructor() {
    super();
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    this.state={
      geturl: "/users/translations.php",
      id: null,
      id1: null,  // is needed, because id will always be overwritten by props!
      title: "",
      infoText: "",
      buttons: null,
      position: [20*em,12*em], position_offset: [10,0],  // Window postitioning
      last: [],
    }
  }

  componentDidMount(id) {
    if (!id) id = this.state.id
    this.fetchTranslation(id, null)
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("info_window")
    //console.log(e1.style)
    //e1.style.left = (this.state.position[0]-this.state.position_offset[0]*em)+'px';
    e1.style.top =(this.state.position[1]-this.state.position_offset[1]*em)+'px';
  }
  fetchTranslation(id, last = null) {
    //console.log("set ids", id, this.state.id1)
    //this.setState({id: id, id1: id,})
    GetJsonFormPHP(this.state.geturl+"?id="+id).then((result) => {
      //console.log(id, this.state.id1, this.state.title, "finished fetch")
      this.setState({
        id: id,
        id1: id,
        title: result.data.title,
        infoText: result.data.text,
        buttons: result.data.buttons,
        last: last,
      })
      if (this.props.reset) this.props.reset();
      //console.log("new state", this.state.id, this.state.id1, this.state.title)
    })
  }

  static getDerivedStateFromProps(props, state) {
    //console.log("call info", props)
    let pos = []
    if (props.position) pos = props.position
    else pos = state.position
    //console.log(pos)
    if (props.id !== null) return {
      id: props.id,
      position: pos,
      //id1: props.id,
    }
    else return {
      position: pos,
    }
  }

  render () {
    //console.log("start")
    if (this.state.id !== this.state.id1) {
      //console.log(this.state.id, this.state.id1)
      this.fetchTranslation(this.state.id)
    }
    const newInfo = value => {
      let last = this.state.last
      if (last === null ) last = [{id: this.state.id1, value: this.state.title, }]
      else last = [...last, {id: this.state.id1, value: this.state.title, }]
      //this.setState({ last: last, id1: value.target.id, });
      this.fetchTranslation(value.target.id, last)
    }
    const oldInfo = value => {
      let last = this.state.last
      last.splice(-1)
      //this.setState({ last: last});
      this.fetchTranslation(value.target.id, last)
    }
    const handleClose = value => { this.props.closeDiv(); }
    const content = this.state.infoText
    const title = this.state.title
    let last = this.state.last
    if (this.state.last) last = last.at(-1)
    //console.log("last", this.state.last, last)
    /*let e1 = document.getElementById("info_window")
    if (e1){
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      e1.style.left = (this.state.position[0]-this.state.position_offset[0]*em)+'px';
      e1.style.top = (this.state.position[1]-this.state.position_offset[1]*em)+'px';
    }*/
    return (
      <Draggable handle=".handle">
        <div className="infotable" id="info_window">
          <div className="header">
            <div className="header_inline handle">{title}</div>
            <div className="header_inline close"><img src={require("../img/close.png")} alt="X" className="close" onClick={handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../img/close.png")}} /></div>
          </div>
          <div className="body">
            <div dangerouslySetInnerHTML={{__html: content}} /><br/>
            <div>{this.state.buttons ? this.state.buttons.map((t,k) => <span key={t.id}><button className="helplink" onClick={newInfo} id={t.id}>{t.value}</button> &nbsp;</span>) : null }</div>
            {last ? <span key={last.id}><button onClick={oldInfo} id={last.id}>→ {last.value}</button> &nbsp;</span> : null }
          </div>
        </div>
      </Draggable>
    )
  }
}

export default Info;