import axios from 'axios'
import React,{Component} from 'react'
import Draggable from "react-draggable"
import Translation from '../user/Translation'

import imgClose from '../datatables/img/close.png'

function t(field) { return Translation.get(field) }

class FileUpload extends Component {
  constructor() {
    super()
    this.state={
      uploadurl: "feed/load_ingredient.php",
      id: 0,
      selectedFile: null,
      title: t("change_field_options"),
      files: [],
    }
    this.onFileChange = this.onFileChange.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }
  static getDerivedStateFromProps(props, state) {
    return {
      id: props.id,
      title: props.title,
      files: props.files,
      uploadurl: props.upload,
    }
  }
  onFileChange = event => {
    this.setState({ selectedFile: event.target.files[0], })
    //console.log(this.state.selectedFile)
  }
  // On file upload (click the upload button)
  onFileUpload = () => {
    const formData = new FormData()
    formData.append(
      "file",
      this.state.selectedFile,
      this.state.selectedFile.name
    )
    const url = process.env.REACT_APP_API_URL + "/" + this.state.uploadurl + "?id=" + this.state.id
    axios.post(url, formData)
    console.log("upload to:", process.env.REACT_APP_API_URL + "/" + this.state.uploadurl + "?id=" + this.state.id)
    this.handleClose()
  }
  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      //File Type: {this.state.selectedFile.type}<br/>
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return (
        <div>
          <h3>{t('upload_file_details')}:</h3>
          <b>{t('upload_file_name')}:</b> {this.state.selectedFile.name}<br/>
          <b>{t('upload_file_date')}:</b> {this.state.selectedFile.lastModifiedDate.toLocaleDateString('de-DE', options)}<br/>
          <button onClick={this.onFileUpload} className="button">{t('upload_start')}!</button>
        </div>
      )
    } else {
      return (
        <div><h4>{t('upload_choose')}</h4></div>
      )
    }
  }
  handleClose() {
    this.props.closeDiv(true)
  }

  render() {
    //console.log(this.state.id)
    //<h3> File Upload using React!</h3>
    return (
      <Draggable handle=".handle">
      <div className="edittable">
      <div className="handle"><div className="header">{this.state.title}<img src={imgClose} alt={t('close')} className="close"  onClick={this.handleClose}/></div></div>
        <div align="center">
          <div>
              <br />
              <div>{t('upload_allowed')}: {this.state.files}</div><br />
              <label for="file-upload" class="button">
                  Upload File
              </label>
              <input id="file-upload" type="file" onChange={this.onFileChange} accept={this.state.files} className="file"/> <br />
          </div>
          {this.fileData()}
        </div>
      </div>
    </Draggable>
    )
  }
}

export default FileUpload