import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line } from 'react-chartjs-2';
//import { Bar } from 'react-chartjs-2';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP.js";
//import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation.js';
import Info from "../datatables/api/Info.js";
import { format } from 'date-fns';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function t(field) { return Translation.get(field) }

class CharEfficiency extends Component{
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-31)
    this.state={
      geturl: "/charts/efficiency.php?",
      data: [],
      label: [],
      feeds: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      selected: 0,
      fixScale : false,
      maxAmount: 100,
      minAmount: -5,
      TM: "0",
      eventList: [], eventEmpty: [], // event list for edit
      showHelp: false, showHelpId: null, // Info popup
    }
    this.getData = this.getData.bind(this)
    this.reload = this.reload.bind(this)
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + "start=" + this.state.startDate + "&end=" + this.state.endDate).then((result) => {
      //console.log("result", result)
      this.setState({
        data: result.data.data,
        label: result.data.label,
      })
    })
  }
  reload() {
    this.componentDidMount()
  }
  getData(startDate, endDate) {
    GetJsonFormPHP(this.state.geturl + "start=" + startDate + "&end=" + endDate ).then((result) => {
      this.setState({
        data: result.data.data,
        label: result.data.label,
      })
    })
  }

  render() {
    //console.log(this.state)
    let title = "" //t("chart_feeds_per_day")
    const options = {
      plugins: {
        title: {
          display: true,
          text: title,
          font: { size: 24, },
        },
        legend: {
          position: 'right',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  var d = context.dataset
                  var index = context.dataIndex
                  if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                }
                //console.log(context)
                if (context.parsed.y>0) label += context.parsed.y
                return label
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      elements: {
        line: {
          tension: 0
        }
      },
      responsive: true,
      scales: {
        x: { stacked: false, },
        y: {
          stacked: false,
          min: 0,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value
            else return ""
          } }
        },
      },
    }
    //let data1 = this.state.data
    const labels = this.state.label
    //console.log(labels)
    //console.log(data1)
    const data = {
      labels,
      datasets: this.state.data,
    }
    return (
      <div>
        <table width="60%" border="0">
          <thead><tr><td>{t("start_date")}</td><td>{t("end_date")}</td><td> </td></tr></thead>
          <tbody><tr>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ startDate: value.target.value, }) }} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ endDate: value.target.value, }) }} className="datefield"/></td>
            <td width="15%" align="center"><button onClick={() => {this.getData(this.state.startDate, this.state.endDate)}} className="button">{t("show")}</button></td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_efficiency_charts",}) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>

        <Line options={options} data={data} className="stats"/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}

export default CharEfficiency;