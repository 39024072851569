//import { get } from "https"

var Translation = (function() {
  //var language = null
  //var field_list = []

  var getLanguage = function() {
    return localStorage.getItem('language_id')
  }
  var clearTranslations = function() {
    localStorage.setItem('language_id', null)
    localStorage.clear()
  }

  var translation = function(field) {
    //console.log("get translation: "+field)
    //console.log(field_list)
    //return field_list[field]
    if (localStorage.getItem("lang_" + field) !== null) {
      return localStorage.getItem("lang_" + field)
    }
    else return <span className="notranslation">{field}</span>
  }

  var setTranslations = function(lan, fields) {
    localStorage.setItem('language_id', lan)
    //console.log(fields)
    //console.log("show up language fields " + fields.customer)

    for (const [key, value] of Object.entries(fields)) {
      //console.log(`${key}: ${value}`)
      localStorage.setItem('lang_' + key, value)
    }
    //localStorage.setItem('language_fields', fields)
    //let myfields =localStorage.getItem('language_fields')
    //console.log(myfields)
    //console.log("localstorage:")
    //console.log(localStorage)
  }

  return {
    getLanguage: getLanguage,
    get: translation,
    set: setTranslations,
    clear: clearTranslations
  }

})()
/*
function t(field) {
  return localStorage.getItem("lang_" + field)
}*/

export default Translation
