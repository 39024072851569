import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Translation from '../user/Translation.js'
import GetJsonFormPHP from "./api/GetJsonFormPHP.js"
import {setTableFontSize} from './api/imports.js'

import './styles/react-table.css'
//import imgFalse from './img/false.png'
//import imgTrue from './img/true.gif'

function t(field) { return Translation.get(field) }

class ErrorTranslations extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_translations_error.php",
      saveurl: "/users/set_translations_error.php",
      pdfUrl: "/users/upload_service_pdf.php",
      orgData: [], data: [], empty: null, selected: null,  // Table data
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      filterField: "", filterLan: "", // Filter Options
      languages: ["Deutsch"], files: null,
      fontSize: setTableFontSize(0.9),
    }
    //this.setfilter = this.setfilter.bind(this)
    this.handleErrorChange = this.handleErrorChange.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      //if (result.data === null) result.data = [this.state.empty]
      //console.log(result)
      this.setState({
        orgData: result.data,
        data: result.data,
        languages: result.languages,
        files: result.files,
        empty: result.empty, //{org_id: -1, id: "", en: "", de: "", fr: "", it: ""},
      })
      //if (this.state.filterField !== "" || this.state.filterLan !== "") this.setfilter()
    })
  }
  setfilter(value) {
    let filterField = this.state.filterField
    let filterLan = this.state.filterLan
    if (value && value.target.name === "filter_field") filterField = value.target.value
    if (value && value.target.name === "filter_lans") filterLan = value.target.value
    let  a = []
    if (filterField !== "") {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.id.toString().toLowerCase().includes(filterField.toLowerCase()) || t.org_id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    let  a1 = []
    if (filterLan !== "") {
      // eslint-disable-next-line array-callback-return
      a.map((t,k) => {
        const filter = filterLan.toLowerCase()
        if (t.en.toLowerCase().includes(filter) || t.de.toLowerCase().includes(filter) || t.fr.toLowerCase().includes(filter) || t.it.toLowerCase().includes(filter) || t.org_id === -1) a1 = [...a1, t]
      })
    }
    else a1 = a
    this.setState({
      data: a1,
      filterField: filterField,
      filterLan: filterLan,
      selected: null,
    })
  }
  errorListCell(initialValue, id , row, rows = 3) { // id = 'de'
    const inputChange = value => { this.handleErrorChange(row._index, id, value) }
    const _handleKeyDown = e => { if (e.key === 'Enter') this.handleSubmit(row) }
    //const position = 1
    if (!initialValue) initialValue = {description: "", error: "", options: "", solution: "", solution_service: "", service_pdf: ""}
    if (this.state.selected === row._index) {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      return (
        <table>
          <tr>{id === "de" ?<td>Description </td> : null}<td><textarea name={id+"_description"} rows={2} cols={60} style={{minHeight: 2*em*1.2}} onChange={inputChange} value={initialValue.description} className="errorlist_description" /></td></tr>
          <tr>{id === "de" ?<td>Error </td> : null}<td><textarea name={id+"_error"} rows={2} cols={60} style={{minHeight: 2*em*1.2}} onChange={inputChange} value={initialValue.error} className="errorlist_error" /></td></tr>
          <tr>{id === "de" ?<td>Options (§1§) </td> : null}<td><input name={id+"_options"} onChange={inputChange} value={initialValue.options} className="errorlist_option" /></td></tr>
          <tr>{id === "de" ?<td>Solution </td> : null}<td><textarea name={id+"_solution"} rows={3} cols={60} style={{minHeight: 3*em*1.2}} onChange={inputChange} value={initialValue.solution} className="errorlist_solution" /></td></tr>
          <tr>{id === "de" ?<td>Solution service </td> : null}<td><textarea name={id+"_solution_service"} rows={3} cols={60} style={{minHeight: 3*em*1.2}} onChange={inputChange} value={initialValue.solution_service} className="errorlist_solution_service" /></td></tr>
          <tr>{id === "de" ?<td>Service PDF</td> : null}<td>
            <select name={id+"_service_pdf"} value={initialValue.service_pdf} onChange={inputChange} onKeyDown={_handleKeyDown} >
              <option key="" value=""></option>
              {this.state.files[id] ? Object.entries(this.state.files[id]).map((t,k) => <option key={k} value={t[1]}>{t[1]}</option>) : null }
            </select>
          </td></tr>
        </table>
      )
    }
    else {
      return (
        <div>
          <div>
            {initialValue.description ? <span title={initialValue.description} className="errorlist_description"><span dangerouslySetInnerHTML={{__html: initialValue.description.replaceAll("\n", "<br/>")}}></span><br /></span> : null }
            {initialValue.error ? <span title={initialValue.error} className="errorlist_error"><span dangerouslySetInnerHTML={{__html: initialValue.error.replaceAll("\n", "<br/>")}}></span><br /></span> : null }
            {initialValue.options ? <span title={initialValue.options} className="errorlist_option"><span dangerouslySetInnerHTML={{__html: initialValue.options}}></span><br /></span> : null }
            {initialValue.solution ? <span title={initialValue.solution} className="errorlist_solution"><span dangerouslySetInnerHTML={{__html: initialValue.solution.replaceAll("\n", "<br/>")}}></span><br /></span> : null }
            {initialValue.solution_service ? <span title={initialValue.solution_service_service} className="errorlist_solution"><span dangerouslySetInnerHTML={{__html: initialValue.solution_service.replaceAll("\n", "<br/>")}}></span></span> : null }
            {initialValue.service_pdf ? <a href={"https://connect.hetwin.at/public/uploads/service_pdf/" + id + "/" + initialValue.service_pdf} rel="noreferrer" target="_blank"><span title={initialValue.service_pdf} className="errorlist_solution_pdf">{initialValue.service_pdf}</span></a> : null }
          </div>
        </div>
      )
    }
  }
  handleErrorChange(index, target, value) {
    let newVal = value.target.value
    let fieldname = value.target.name.substring(3,100)
    let data = this.state.data
    let row = data[index]
    let field = row[target]
    console.log(field, fieldname, newVal)
    //for (let key in field) { if (key === fieldname) { field[key] = newVal } }
    field[fieldname] = newVal
    //for (let key in row) { if (key === target) { row[key] = field } }
    row[target] = field
    let error = false
    data[index] = row
    console.log(row)
    this.setState({
      data: data,
      fieldError: error,
    })
  }

  /*addLanguageTexts(header, accessor, isInt, cellWidth, show, addText, align, cellColor, textColor, edit, filterable, decimals, mouseoverText, need, editOnNew, min, max) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    if (isInt > 0 && align === null) align = "right"
    let headertext = t(header)
    if (header.substring(0, 1) === "§") headertext = header.substring(1, 1000)
    let result = {
      Header: () =>  <span dangerouslySetInnerHTML={{__html: headertext}}></span>,
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.textCell(initialValue, id , row, isInt, addText, edit || (editOnNew && row.id <= 0), decimals, mouseoverText, min, max, need) },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, color: textColor === "row" ? rowInfo.row.color : textColor, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellWidth > 0) result.width = cellWidth*em
    return result
  }*/

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const columns = [
      { accessor: 'org_id', show: false,},
      this.addTextCell('fieldname', 'id', 0, 9),
      { accessor: 'de',
        Header: t('Deutsch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.errorListCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (rowInfo.row.check_de===0 || !rowInfo.row.de ? '#FFEECC':null) } })
      },{ accessor: 'en',
        Header: t('Englisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.errorListCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (rowInfo.row.check_en===0 || !rowInfo.row.de ? '#FFEECC':null) } })
      },{ accessor: 'fr',
        Header: t('Französisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.errorListCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (!rowInfo.row.fr || rowInfo.row.check_fr===0 ? '#FFEECC':null) } })
      },{ accessor: 'it',
        Header: t('Italienisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.errorListCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (!rowInfo.row.it || rowInfo.row.check_it===0 ? '#FFEECC':null) } })
      },
      { accessor: 'check_en', show: false,},
      { accessor: 'check_de', show: false,},
      { accessor: 'check_fr', show: false,},
      { accessor: 'check_it', show: false,},
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 8*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false) },
      }
    ]
    return columns
  }

  render() {
    const columns = this.createColumns()
    const filterChange  = value => { this.setfilter(value) }
    return (
      <div>
        <div className="table_buttons">
          {t('filter_fieldname')}:&nbsp;
          <input type="text" name="filter_field" value={this.state.filterField} onChange={filterChange} onKeyDown={filterChange}/> &nbsp;
          <button onClick={() => { if (this.state.orgData[0].org_id !== -1) this.insertRow()} }>{t("addline")}</button>
          <div class="uploadform"><form action={process.env.REACT_APP_API_URL +  "/" + this.state.pdfUrl} method="post" enctype="multipart/form-data" target="_blank">
            Select PDF (DOCX) to upload: &nbsp;
            <input type="file" name="fileToUpload" id="fileToUpload" />
            {t('language')} <select name="language">
              {Object.entries(this.state.languages).map((t,k) => <option key={k} value={t[1]}>{t[1]}</option>) }
            </select> &nbsp;
            <input type="submit" value={t("submit")} name="submit" />
          </form></div>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data = {this.state.data}
          columns = {columns}
          minRows = {0}
          previousText = {'<'}
          nextText = {'>'}
          showPageJump = {true}
          //persistTableHead = {true}
          //filterable = {true}
          defaultPageSize = {50}
          pageSizeOptions = {[25, 50, 100, 200, 500]}
          useFlexLayout = {true}
          getTrProps = {(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected) { this.setState({ selected: rowInfo.index, }) } },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : 'white',
                color: rowInfo && rowInfo.index === this.state.selected ? '#777777' : 'black',
                'font-weight': 'lighter !important',
              }
            }
          }}
        />
        </div>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default ErrorTranslations;
