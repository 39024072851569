import Translation from '../../user/Translation';

function t(field) { return Translation.get(field) }


function getStatusVars() {
  return [t('status_new'), t('status_complete'), t('status_used'), t('status_inactive'), t('status_active'), t('status_historic'), t('status_delete'), t('status_error')]
}
function getStatusColors() {
  //return ["orange", "#22AA00", "#22AA00", "#0044BB", "black", "black", "grey", "red", "#BB00AA"]
  //status: new      ok       used     update     active    historic  deleted   error   drive_feed  drive_clear
  return ["black", "black", "black", "#0033AA", "#046600", "#333333", "grey",   "red",  "#AA0099",  "#CA7A16"]
}
function getFeedColors() {
  return ["black", "#00AA00", "#AA8800", "#FF2299"]
}
function getFeedBackColors() {
  return ["white", "#EEFFEE", "#FFF0DD", "#FFDDEE"]
  //const backColors = ['#FFF5F5', '#FFF5F5', '#FFF5F5', '#F5F5FF', '#F5FFF5', 'lightgrey', '#b3b3b3', '#FFE5E5', 'lightgrey']
}
function setTableFontSize(defaultfontSize = 1) {
  //console.log(window.sessionStorage.getItem("fontSize"))
  defaultfontSize *= window.sessionStorage.getItem("fontSize")
  if (window.screen.availWidth > 1280) return defaultfontSize
  else return defaultfontSize*1.4
}

export default getStatusVars
export {getStatusColors, getFeedColors, getFeedBackColors, setTableFontSize}
