import TableFunctions from './api/TableFunctions.js';
import React from 'react';
import ReactTable from "react-table-6";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP.js";
import {setTableFontSize} from './api/imports.js'
import UserProfile from '../user/UserProfile.js';
import Translation from '../user/Translation.js';
import Info from "./api/Info.js";
import './styles/react-table.css';

function t(field) { return Translation.get(field) }
//const delay = ms => new Promise(res => setTimeout(res, ms));



class DailyWeight extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_meatdaily.php",
      saveurl: "/feed/setmeatdaily.php?groups=0",
      //saveFieldsUrl: "/users/save_fieldoptions.php?id=",
      data: [], empty: null, selected: null, groups: null, groupnames: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      showfields: null, manageFields: false, managePrice: false,  // Show table fields
      showHelp: false, showHelpId: null, // Info popup
      first: true,  //daily: true,
      showHideLoad: 0, uploadFiles: ".ads, .abo",
      fontSize: setTableFontSize(),
    }
    this.upload = this.upload.bind(this)
    this.closeDiv = this.closeDiv.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (!result.auto_import) this.setState({ selected: 0, })
      //let gr = 0
      //if (result.showfields.groups) gr = 1
      this.setState({
        data: result.data,
        groups: result.groups,
        groupnames:  result.groupnames,
        //showfields: result.showfields,
        //months: result.months,
        //first: false,
        //saveurl: this.state.saveurl.substring(0, this.state.saveurl.length-1) + gr
      })
    })
  }
  changeGroupSetting() {
    let newSetting = this.state.showfields
    if (newSetting.groups) {
      newSetting.groups = false
      newSetting.groups_in_milk = false
      newSetting.groups_milk = false
    }
    else {
      newSetting.groups = true
      newSetting.groups_in_milk = true
      newSetting.groups_milk = true
    }
    this.setState({
      selected: null,
    })
    PostJsonFormPHP(this.state.saveFieldsUrl+"milkgrouplist", newSetting).then(response => {
      this.componentDidMount()
    })
  }
  createColumns() {
    //const colors =["#EEAAAA", "#AAEEAA", "#AAAAEE", "#EEAAEE", "#EEEEAA", "#AAEEEE", "#FFAA66", "#AAFF66", "#AA66FF", "#66AAFF", "#66FFAA", "#FF66AA", "#AA33AA", "#33AAAA", "#AAAA33",]
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    let columns = [
      //{ accessor: 'id',show: false,},
      //{ accessor: 'customer_id', show: false},
      this.addDateCell('date', 'day', false, 8.8),
    ]
    if (this.state.groups) {
      this.state.groups.map((t1,k) => {
        let groupColumns = []
        groupColumns = [...groupColumns, this.addTextCell('§', 'cows_'+t1, 1, 2, true, "", null, null, null, false)]
        groupColumns = [...groupColumns, this.addTextCell('§weigth', 'amount_'+t1, 2, 4, true, "", null, null, null, true, false, 1)]
        //groupColumns = [...groupColumns, this.addTextCell('§' + t('milkgroup_groups_in_milk'), 'group_'+t1+'_in_milk', 2, 4, this.state.showfields ? this.state.showfields['groups_in_milk'] : true, "", null, colors[k])]
        let groupColumn = [{
          Header: this.state.groupnames[t1],
          columns: groupColumns
        }]
        columns = [...columns, ...groupColumn]
        return null
      })
    }
    const columns2 = [
      //this.addTextCell('milkgroup_sumcows', 'cows_all', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, -1, null, true),
      //this.addTextCell('milkgroup_all', 'milk_all', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, 0, null, true),
      //this.addTextCell('milkgroup_milkpercow', 'milk_per_cow', 2, 4*this.state.fontSize, true, "", null, null, null, this.state.showfields ? !this.state.showfields['groups'] : false, false, 1, null, true),
      { accessor: 'edit',
      Header: t('table_edit'),
      width: 8.2*em,
      filterable: false,
      resizable: false,
      //Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, true, this.state.daily === (row.daily === 1) || row.id < 0) },
      Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false, true, true) },
    }]
    return [...columns, ...columns2]
  }
  upload() {
    this.setState({
      showHideLoad: 1,
    })
  }
  closeDiv(delayReload = false) {
    this.setState({
      manageFields: false,
      showHideLoad: 0,
      selected: null,
    })
    if (delayReload) {
    }
    /*  const yourFunction = async () => {
        await delay(10000)
        console.log("Waited 10s")
        this.componentDidMount()
        console.log("Reload data")
      }
      yourFunction()
    }
    else this.componentDidMount()*/
    this.componentDidMount()
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    const columns = this.createColumns()
    console.log(columns)
    console.log(this.state.data)
    //console.log(this.state.fontSize)
    //let setGroups = t("milkdaily_change_to_groups")
    return (
      <div>
        <div className="table_buttons">
          {userRight ? <button onClick={() => {  this.state.showBase = true; this.insertRow()}}>{t("addline")}</button> : null }
          <button onClick={() => { this.setState({ manageFields: true, }) }}>{t("show_advanced")}</button>
          <img src={require("./img/reload.png")} alt="*Reload" title="*Reload" onClick={() => { this.reload() } } onMouseOver={(e) => {e.currentTarget.src = require("./img/reload_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/reload.png")}} />
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_milkdaily_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        <div style={{"font-size": this.state.fontSize+"em"}}>
        <ReactTable
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {20}
          pageSizeOptions = {[10, 20, 50, 100]}
          useFlexLayout = {true}
          sortable = {false}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                //onDoubleClick: (e, t) => { if ((rowInfo.index !== this.state.selected  || rowInfo.row.id < 0) && userRight) { this.setState({ selected: rowInfo.index, }) } },
                //style: { background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white', color: rowInfo && rowInfo.row.incomplete === 1 ? '#AA0000' : null, }
            }
          }}
        />
        </div>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}

export default DailyWeight;
