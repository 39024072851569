import React, { Component } from 'react';
import TableFunctions from './api/TableFunctions.js';
import ReactTable from "react-table-6";
import TextField from '@mui/material/TextField';
import Draggable from "react-draggable";
import Info from "./api/Info";
import AlertDialog from "../user/Dialog.js";
import GetJsonFormPHP, {PostJsonFormPHP} from "./api/GetJsonFormPHP";
import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import getStatusVars, {getStatusColors} from './api/imports.js';
import { format } from 'date-fns';

import './styles/react-table.css';
import './styles/tablestyles.css';
import imgAttention from './img/attention.png';
import imgWarning from './img/warning.png';
import imgCopy from './img/copy.png';
import imgStop from './img/stop.png';
import imgRepeat from './img/repeat.png';
import imgPlay from './img/play.png';
import imgInstall from './img/install.png';
import imgCancel from './img/cancel.png';
import imgCanceled from './img/canceled.png';
import imgCancelStop from './img/cancelstop.png';
import imgEdit from './img/edit.png';
import imgWarnOnline from './img/careful.png'

function t(field) { return Translation.get(field) }

class Edit extends Component {
  constructor() {
    super()
    this.state = {
      geturl: "/robots/get_drives.php?type=1",
      saveurl: "/robots/set_drives.php?source=drivelist&robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      row: null, groups: null, groupnames: null, id: 0, type: 0,
      checked: [], extra: 1, feedamounts: [], feedOrder: [], checkedFeedamounts: [],
      data: null, empty: null, selected: null, // Table data
      position: [], selectedDate: null,
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
      eventList: null,
    }
    this.handleClose = this.handleClose.bind(this)
    //this.handleEventDelete = this.handleEventDelete.bind(this)
  }

  reload(newId) {
    /*let id = this.state.id
    if (newId) id = newId
    this.props.reload(newId)
    GetJsonFormPHP(this.state.geturl + "&id=" + id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      if (result.events === null) result.events = []
      this.setState({
        data: result.data,
        selected: null,
        eventList: result.events,
      })
    })*/
  }
  handleClose() {
    this.props.closeDiv();
  }
  storeEvent(event) {
    event.preventDefault();
    //console.log(this.state.edit, this.state.type, this.state.row);
    //console.log(this.state.eventUrl + "?evid=" + this.state.type + "&id="+this.state.checked+"&groups="+this.state.checked+"&val=" + this.state.feedamounts+"&drive_type="+this.state.row.type+"&ids="+this.state.row.ids)
    if (!this.state.edit) {
      GetJsonFormPHP(this.state.eventUrl + "?evid=" + this.state.type + "&id="+this.state.checked+"&groups="+this.state.checked+"&val=" + this.state.feedamounts+"&drive_type="+this.state.row.type+"&ids="+this.state.row.ids+"&valid="+this.state.checkedFeedamounts+"&date="+this.state.selectedDate+"&extra="+this.state.extra).then((result) => {
        this.handleClose()
      })
    }
    else {
      //GetJsonFormPHP(this.state.saveurl + "?evid=0&id="+this.state.checked+"&val=" + this.state.feedamounts+"&drive_type="+this.state.row.type).then((result) => {
      let row = this.state.row
      row.action = "UPDATE";
      row.animalgroup_ids = this.state.checked.map(e => e).join(',');
      row.feedingorder = this.state.feedOrder;
      console.log(this.state.feedOrder)
      //console.log(this.state.row, row, this.state.checked)
      PostJsonFormPHP(this.state.saveurl, row).then((result) => {
        this.handleClose()
      })
    }

  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id || state.type !== props.type) {
      //let groupIds = ""+props.row.animalgroup_id
      //let order = []
      //console.log(amounts, amounts.length, order);
      var updatedValues = []
      if (props.row.animalgroup_ids){
        const a = props.row.animalgroup_ids.length
        for (let i = 0; i < a; i++) { updatedValues = [...updatedValues, i] }
      }
      if (state.id !== props.row.id) return {
        id: props.row.id,
        row: props.row,
        //groups: groupIds.split(', '),
        groups: props.row.animalgroup_ids,
        checked: props.row.animalgroup_ids,
        checkedFeedamounts: updatedValues,
        //checked: [],
        feedamounts: props.row.amount,
        groupNames: props.row.names,
        cows: props.row.cows,
        feedOrder: props.row.diffs,
        edit: props.edit,
        type: props.type,
        position: props.position,
        selectedDate: props.date,
      }
      else return {
        id: props.row.id,
        row: props.row,
        //groups: groupIds.split(', '),
        groups:props.row.animalgroup_ids,
        groupNames: props.row.names,
        feedOrder: props.row.diffs,
        edit: props.edit,
        type: props.type,
        position: props.position,
        selectedDate: props.date,
      }
    }
    return null
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("set_drive_params")
    e1.style.left = (this.state.position[0]-28*em)+'px';
    e1.style.top =(this.state.position[1]-2*em)+'px';
  }
  render() {
    //console.log(this.state.checked, this.state.checkedFeedamounts)
    //console.log(this.state.type, this.state.row)
    const saveData = value => { this.storeEvent(value) }
    const changeExtraDrive = value => {
      let amount = this.state.feedamounts
      amount[value.target.id] = value.target.value
      this.setState({ feedamounts: amount, })
    }
    const changeFeedOrder = value => {
      let order = this.state.feedOrder
      order[value.target.id] = value.target.value
      this.setState({ feedOrder: order, })
    }
    const changeDriveTime = value => {
      let row = this.state.row
      row.starttime = value.target.value
      this.setState({ row: row, })
    }
    const handleCheck = (event) => {
      var updatedList = [...this.state.checked];
      var updatedValues = [...this.state.checkedFeedamounts];
      if (event.target.checked) {
        updatedList = [...this.state.checked, parseInt(event.target.value)];
        updatedValues = [...this.state.checkedFeedamounts, parseInt(event.target.name)];
      } else {
        var id = Number(event.target.value);
        var pos = Number(event.target.name);
        //console.log(this.state.checked, event.target.value, this.state.checked.indexOf(id))
        //console.log(this.state.checkedFeedamounts, event.target.name, this.state.checked.indexOf(event.target.name))
        updatedList.splice(this.state.checked.indexOf(id), 1);
        updatedValues.splice(this.state.checkedFeedamounts.indexOf(pos), 1);
      }
      console.log(updatedList, updatedValues)
      this.setState({
        checked: updatedList,
        checkedFeedamounts: updatedValues,
      })
    }
    const handleExtra = (event) => {
      if (event.target.checked) {
        this.setState({ extra: 1, })
      } else {
        this.setState({ extra: 0, })
      }
    }
    let e1 = document.getElementById("set_drive_params")
    if (e1){
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      e1.style.left = (this.state.position[0]-28*em)+'px';
      e1.style.top = (this.state.position[1]-2*em)+'px';
    }
    //console.log(this.state.row.type, this.state.edit, this.state.type)
    return (
      <Draggable handle=".handle">
      <div className="edittable" id="set_drive_params">
        <div className="header">
          <div className="header_inline handle">{this.state.edit ? <span>*Edit drive times</span> : this.state.type === 12 ?  t('drives_stop') : t('drives_add_repeat')}</div>
          <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
        </div>
        <form onSubmit={saveData}>
        <input value={this.state.groups} type="hidden" />
        <table width="100%">
          <tbody>
            {this.state.row.type !== 1 && this.state.edit ? <tr>
              <td colSpan="2" align="center"><div className="table_buttons"><span> {t('drive_time')} <input type="text" id="edit_time" name="edit_time" value={this.state.row['starttime']} onChange={changeDriveTime} className="input_number_4"/></span></div></td>
              <td width="10%">*cows</td>
              <td width="20%">*order 0..5</td></tr> :
              this.state.row.type === 1 && this.state.edit ?
              <td colSpan="5" align="center"><div className="table_buttons"><span> {t('drive_time')} <input type="text" id="edit_time" name="edit_time" value={this.state.row['starttime']} onChange={changeDriveTime} className="input_number_4"/></span></div></td>:
            null}
            { Object.entries(this.state.groupNames).map((t,k) => <tr key={"checkgroup"+k}>
              <td width="5%"><input name={k} value={this.state.groups[k]} type="checkbox" defaultChecked={true} onChange={handleCheck} className="checkbox_with_space"/></td>
              <td width="60%"><span dangerouslySetInnerHTML={{__html: t[1]}}></span></td>
              {this.state.cows ? <td align="right">{this.state.cows[k]}</td> : null}
              {this.state.row.type !== 1 && this.state.row.type !== 7 && !this.state.edit && this.state.type === 0 ? <td><div className="table_buttons" width="80%"><span><input type="text" id={k} name="extradrive1" value={this.state.feedamounts[k]} onChange={changeExtraDrive} className="input_number_4"/>%</span></div></td> : null}
              {this.state.edit && this.state.feedOrder ? <td width="10%"><span><input type="text" id={k} name="feedingorder" value={this.state.feedOrder[k]} onChange={changeFeedOrder} className="input_number_2"/></span></td>: null}
            </tr>) }
          </tbody>
        </table>
        {this.state.row.type !== 1 && this.state.row.type < 6 && !this.state.edit && this.state.type === 0 ?
          <div className="table_buttons" align="center"><input name="extra" value="1" type="checkbox" onChange={handleExtra} className="checkbox_with_space" defaultChecked={this.state.extra}/>{t("drives_repeat")}
          {this.state.extra !== 1 ? <div>{t("drives_repeat_hint")}</div>: null}
          </div>
        : null}
        <div className="table_buttons" align="center">
          <button onClick={saveData} disabled={this.state.checked.length > 0 ? false : true }className="button">{t("save")}</button>
          <button onClick={this.handleClose} className="button">{t("cancel")}</button>
        </div>
        </form>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
      </Draggable>
    )
  }
}


class Drives extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_drives_list.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      saveurl: "/robots/set_drives.php?drives=1&robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      data: [], data12: [], empty: null, selected: null, // Table data
      robots: null, emptyGroups: null, customers: null, animalgroups: null, driveType: [t('drives_feed'), t('drives_cleaning'), t('drives_repeat'), t('drives_repeat'),], // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(),  // Status and colors
      updateType: 0,
      showHelp: false, showHelpId: null, // Info popup
      showActive: true,
      selectecdDate: format(new Date(), 'yyyy-MM-dd'), selectedGroup: 0,
      today: format(new Date(), 'yyyy-MM-dd'), last_update: null,
      now: new Date(),
      showHideEdit: false, selectedRow: null, advancedIndex: null, advancedEdit: false, showPosition: [], // Advanced insert window
      windowWidth: 0, windowHeight: 0,
      //advancedIndex: null,
      //emptydrives: null,
      counter: 0,
      robotOnline: 0,
    }
    //this.closeDiv = this.closeDiv.bind(this)
    this.reload = this.reload.bind(this)
    this.closeEdit = this.closeEdit.bind(this)
    this.handleCopy = this.handleCopy.bind(this)
    this.handleActivate = this.handleActivate.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleResize = this.handleResize.bind(this);
  }
  reload() {
    this.setState({
      showHideEdit: false,
    })
    this.componentDidMount()
  }
  componentDidMount(active = null, date = this.state.selectecdDate, group = 0) {
    let add = this.state.showActive ? "&active=1" : "&active=0"
    if (active !== null) add = active ? "&active=1" : "&active=0"
    let groupSelected = 0
    if (group !== 0) {
      groupSelected = group
      add += "&group_id=" + group
    }
    this.fetchData(add, date, 1, groupSelected)
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  }
  fetchData(add, date, click = 1, showGroup = 0) {
    //console.log("showGroup", showGroup, this.state.selectedGroup)
    if (window.sessionStorage.getItem("page_id") === "/robot/drives" && ((this.state.selectecdDate === this.state.today && this.state.showActive && (showGroup === this.state.selectedGroup)) || click === 1)) {
      const updateTime = new Date()
      GetJsonFormPHP(this.state.geturl + add + "&date=" + date + "&counter=" + this.state.counter).then((result) => {
        let res = []
        if (!this.state.showActive) res = result.data.concat(result.data1)
        else res = result.data
        this.setState({
          empty: result.empty,
          data: res,
          data12: result.data1,
          robots: result.robots,
          emptyGroups: result.empty_groups,
          customers: result.customers,
          animalgroups: result.animalgroups,
          last_update: updateTime,
          counter: this.state.counter+1,
          robotOnline: result.robot_online,
        })
      })
      //console.log(this.state.last_update, updateTime)
      if (date === this.state.today) setTimeout(() => {
        //console.log(`updating ` + this.state.counter + " " + format(new Date(), 'yyyy-MM-dd HH:ii:ss'), window.sessionStorage.getItem("page_id"))
        this.fetchData(add, date, 0, showGroup)
      }, 60000)
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  handleDisable(row, disable) {
    //console.log(row)
    GetJsonFormPHP(this.state.eventUrl + "?evid=12&id=" + row.id + "&drive_type=" + row.type + "&groups=" + row.animalgroup_id + "&ids=" + row.ids).then((result) => {
      if (result.error) {
        this.setState({
          confirmVisible: true,
          title: t("error"),
          message: t(result.error),
          choise: false,
          selected: null,
        })
      }
      this.reload()
    })
  }
  handleCopy(row) {
    //console.log(row)
    GetJsonFormPHP(this.state.saveurl + "&copyID=" + row.id + "&ids=" + row.ids).then((result) => {
      if (result.error) {
        this.setState({
          confirmVisible: true,
          title: t("error"),
          message: t(result.error),
          choise: false,
          selected: null,
        })
      }
      this.reload()
    })
  }
  handleActivate(row) {
    let id = row.ids
    //if (row.type === 6) id = row.ids
    let evId = "10"
    //if (row.status === 8) evId = "13&time=" + row.starttime
    //console.log(row)
    GetJsonFormPHP(this.state.eventUrl + "?evid="+evId+"&id="+id).then((result) => {
      this.reload()
    })
  }
  handleCancel(row) {
    //console.log(row)
    let id = row.ids
    //if (row.type === 6) id = row.ids
    GetJsonFormPHP(this.state.eventUrl + "?evid=11&id="+id).then((result) => {
      this.reload()
    })
  }
  closeEdit() {
    this.setState({
      selected: null,
      showHideEdit: false,
    })
    this.componentDidMount(this.state.historic)
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    const userRight = UserProfile.checkRight("custuser")
    //const supportRight = UserProfile.checkRight("support")
    return [
      { accessor: 'id', show: false,},
      { accessor: 'ids', show: false, },
      { accessor: 'customer_id', show: false, },
      { accessor: 'animalgroup_id', show: false, },
      { accessor: 'status', show: false, width: 20, },
      { accessor: 'type', show: false, width: 12,},
      { accessor: 'flag', show: false,},
      this.addTextCell("drive_time", "starttime", this.state.starttime, 5, true, null, "center", null, null, false),
      { accessor: 'feedamount', show: false,},
      this.addTextCell("animals_name", "name1", 0, 0, true, null, null, null, null, false),
      this.addTextCell("recipe_name", "recipe_name", 0, 8, true, null, null, null, null, false),
      this.addSelectCell("animals_drivetype", "type", this.state.driveType, 9, true, 'center', null, null, false),
      this.addTextCell('animals_volume', 'vol', 2, 5, true, "m³", null, null, null, false, false, 1),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6.5*em,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const editEntry = value => {
            //console.log(value,  row)
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index, advancedEdit: true,
              showPosition: [value.clientX, value.clientY],
              showHideEdit: true,
              updateType: 0,
            })
          }
          const copyEntry = value => { this.handleCopy(row) }
          const activateEntry = value => { this.handleActivate(row) }
          const cancelEntry = value => { this.handleCancel(row) }
          //console.log(row)
          if (this.state.showActive) {
            return (
              <div>
                {((row.status === 3 && row.type !== 3) || (row.status === 8 && row.type === 6)) && userRight ? <div><img src={imgInstall} alt={t("send_to_robot")} title={t("send_to_robot")} onClick={activateEntry}/> <img src={imgCancel} alt={t("cancel")} title={t("cancel")} onClick={cancelEntry}/></div> : null}
              </div>
            )
          }
          else return (
            <div>
              {row.type < 2 ? this.edit(row, false, row.status < 3, false, false, false) : null}
              {row.status < 5 && !this.state.selected && userRight ? <img src={imgEdit} alt={t("edit")} title={t("edit")} onClick={editEntry}/> : null }
              {this.state.selected !== row._index && row.status !== 3 && userRight ? <img src={imgCopy} alt={t("copy")} title={t("copy")} onClick={copyEntry}/> : null}
            </div>
          )
        },
        getProps: (state, rowInfo) => ({ style: { textAlign: "center", backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? 'lightgrey' : null } }),
      }
    ]
  }
  handleResize() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }
  _onMouseMove(e) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    this.setState({ showPosition: [e.screenX, e.screenY-4*em] });
  }
  render() {
    const userRight = UserProfile.checkRight("custuser")
    const editEntry = value => {
      this.setState({
        selectedRow: this.state.data[value],
        advancedIndex: value, advancedEdit: true,
        showHideEdit: true,
      })
    }
    const repeatEntry = value => {
      let id = value.target.id
      let data = id.substring(0,2)
      id = id.substring(2, 100)
      let dataset = []
      //console.log(data, this.state.data[id])
      console.log(value)
      if (data === "d1") dataset = this.state.data[id]
      else {
        if (data === "d0") dataset = this.state.emptyGroups[id]
        else dataset = this.state.data12[id]
      }
      this.setState({
        selectedRow: dataset,
        advancedIndex: id, advancedEdit: false,
        showHideEdit: true,
        showPosition: [value.clientX, value.clientY],
        updateType: 0,
      })
    }
    const activateEntry = value => {
      let id = value.target.id
      let data = id.substring(0,2)
      id = id.substring(2, 100)
      let dataset = []
      if (data === "a1") dataset = this.state.data[id]
      else dataset = this.state.data12[id]
      this.handleActivate(dataset)
    }
    const cancelEntry = value => {
      let id = value.target.id
      let data = id.substring(0,2)
      id = id.substring(2, 100)
      let dataset = []
      if (data === "c1") dataset = this.state.data[id]
      else dataset = this.state.data12[id]
      this.handleCancel(dataset)
    }
    const selectFgFilter = value => {
      this.setState({
        selectedGroup: value.target.value,
      })
      this.componentDidMount(this.state.historic, this.state.selectecdDate, value.target.value)
    }
    const stopEntry = value => {
      let id = value.target.id
      let data = id.substring(0,2)
      id = id.substring(2, 100)
      let dataset = []
      if (data === "s1") dataset = this.state.data[id]
      else dataset = this.state.data12[id]
      //console.log(value.target, data, id, dataset)
      this.setState({
        selectedRow: dataset,
        advancedIndex: id, advancedEdit: false,
        showHideEdit: true,
        showPosition: [value.clientX, value.clientY],
        updateType: 12,
      })
    }
    const columns = this.createColumns()
    const supportRight = UserProfile.checkRight("support")
    const changeDate = value => { this.setState({ showActive: true, selectecdDate: value.target.value, }); this.componentDidMount(true, value.target.value, this.state.selectedGroup) }
    let buttonText = t('drives_show_active')
    if (this.state.showActive) buttonText = t('drives_show_all')
    //const traderRight = UserProfile.checkRight("trader")
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    //console.log(this.state.windowWidth, this.state.windowHeight, em)
    let e1 = document.getElementById("dr_table1")
    let e2 = document.getElementById("dr_table2")
    let min = 36.8*em
    let a = Math.floor((this.state.windowWidth-em*8)/2-2*em)
    if ((this.state.windowWidth-em*6) < 2*(min+2.6*em)) a = this.state.windowWidth-em*10
    else if (a > 48.5*em) a = 48.5*em
    if (e1) {
      e1.style.width = a +"px"
      e2.style.width = a +"px"
      //console.log(this.state.windowWidth/em, a/em, min/em)
    }
    // Statusanzeigen zu den Einträgen:     {d[1].status + " " + d[1].type + " " + d[1].past}

                //<tr>
                //  <td className="dr_td"><button onClick={() => {this.setState({ showActive: !this.state.showActive, selectecdDate: format(new Date(), 'yyyy-MM-dd'), selected: null, showHideEdit: false}); this.componentDidMount(!this.state.showActive, format(new Date(), 'yyyy-MM-dd'), this.state.selectedGroup)}}>{buttonText}</button></td>
                //</tr>
    return (
      <div className={supportRight ? "table_100": "table_100"}>
        <div className="table_buttons">
          <div className="erors_inline">
            {this.state.emptyGroups ?
            <div className="dr_table dr_top dr_fg">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1">
              <tbody>
                <tr><td className="dr_th" colSpan="3">{t("drives_feeder_no_drive")}</td></tr>
                {this.state.emptyGroups ?  Object.entries(this.state.emptyGroups).map((v,index) =>
                  <tr id={"nodrive_tr" + index} key={"nodrive_tr" + index}>
                    <td>{v[1].name}</td>
                    <td><div className="robot_info">{v[1].recipe_name}</div></td>
                    <td className="center"><img src={imgRepeat} alt={t("drives_repeat")} title={t("drives_repeat")} id={"d0" + index} onClick={repeatEntry} className="img" /></td>
                  </tr>) :
                null}
              </tbody></table>
            </div> : null }
            <div className="dr_table dr_top dr_robot">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1">
              <tbody>
                <tr><td className="dr_th" colSpan="2">{t("drives_feeder")}</td></tr>
                {this.state.robots ?  Object.entries(this.state.robots).map((v,index) =>
                  <tr key={"drive_tr" + index}>
                    <td className={"robot robot_" + index}>&nbsp;</td>
                    <td>
                      <div className="inline">{this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null}</div>
                      <div className="inline">{v[1].name}<span className="robot_info"><br />{v[1].status} &nbsp; {v[1].last_update}</span></div>
                    </td>
                  </tr>) :
                null}
              </tbody></table>
            </div>
            <div className="dr_table dr_top dr_command">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1"><tbody>
                <tr>
                  <td className="dr_td"><button onClick={() => {this.setState({ showActive: !this.state.showActive, selectecdDate: format(new Date(), 'yyyy-MM-dd'), selected: null, showHideEdit: false}); this.componentDidMount(!this.state.showActive, format(new Date(), 'yyyy-MM-dd'), this.state.selectedGroup)}}>{buttonText}</button></td>
                </tr>
                <tr>
                  <td className="dr_td">
                  <select name="feedlist" value={this.state.selected} onChange={(value) => {selectFgFilter(value)}} style={null} className="chartselect">
                    { (this.state.animalgroups !== null) ? Array.isArray(this.state.animalgroups) ? Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>)
                    : Object.entries(this.state.animalgroups).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
                  </select>
                  </td>
                </tr><tr>
                  <td className="dr_td"><div className="date"><TextField id="show_date" type="date" value={this.state.selectecdDate} InputLabelProps={{ shrink: true,}} onChange={changeDate} className="datefield"/></div></td>
                </tr>
              </tbody></table>
            </div>
            <div className="dr_table dr_top dr_command">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1"><tbody>
                <tr>
                  <td className="dr_td td_robot"><img src={imgRepeat} alt={t("drives_repeat")} title={t("drives_repeat")} className="img_small" /></td>
                  <td className="dr_td">{t("drives_repeat")}</td>
                </tr><tr>
                  <td className="dr_td td_robot"><img src={imgStop} alt={t("drives_stop")} title={t("drives_stop")} className="img_small" /></td>
                  <td className="dr_td">{t("drives_stop")}</td>
                </tr><tr>
                  <td className="dr_td td_robot"><img src={imgCanceled} alt={t("drives_stop")} title={t("drives_stop")} className="img_small" /></td>
                  <td className="dr_td">{t("drives_stopped")}</td>
                </tr><tr>
                  <td className="dr_td td_robot"><img src={imgAttention} alt={t("drives_stop")} title={t("drives_stop")} className="img_small" /></td>
                  <td className="dr_td">{t("drives_missing")}</td>
                </tr><tr>
                  <td className="dr_td td_robot"><img src={imgWarning} alt={t("drives_stop")} title={t("drives_stop")} className="img_small" /></td>
                  <td className="dr_td">{t("drives_warning")}</td>
                </tr>
              </tbody></table>
            </div>
          </div>
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_drives_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        {this.state.showActive ?
          <div>
            <div className="dr_table" id="dr_table1">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1">
                <thead>
                  <tr>
                    <th>{t("drive_time")}</th>
                    <th> </th>
                    <th>{t("animals_group")}</th>
                    <th>{t("animals_org_recipe_id")}</th>
                    <th width="5%">{t("status")}</th>
                    <th colSpan="2">{t("options")}</th>
                  </tr>
                </thead>
                { this.state.data != null ? Object.entries(this.state.data).map((d,index) =>
                  <tbody id={"drive_1_" + index} key={"drive_1_" + index}>{d[1].status === 100 ?
                    <tr><td colSpan="7" className="now">{d[1].time}</td></tr> :
                    <tr>
                      <td className={d[1].status === 10 ? "td_top td_active" : d[1].status ===8 ? "td_top" : d[1].status === -1 && !d[1].drived ? "td_top td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_top td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_top td_past" : "td_top td_past_err" : "td_top"}><div className={d[1].early_start ?  "starttime late_runtime" : "starttime"}>{d[1].starttime ? d[1].starttime : "-" }</div><div className={d[1].late_drive ? "runtime late_runtime" : "runtime"}>{d[1].drived ? d[1].drived : "-" }</div></td>
                      <td className={"td_robot" + (d[1].robot_color >= 0 ? " robot_" + d[1].robot_color : "") + " testentry"}> </td>
                      <td className={d[1].status === 10 ? "td_active" : d[1].status ===8 ? "" : d[1].status === -1 && !d[1].drived ? "td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past" : "td_past_err" :""}>
                      <div className=" names">
                          {d[1].names ?  Object.entries(d[1].names).map((d1,index1) => <div key={"drive_1_" + index + "_F" + index1}>{d1[1]}</div>) : null}
                        </div>
                        <div className={d[1].status === 6 ? "names amount changed" : "names amount"}>
                          {d[1].amount ? Object.entries(d[1].amount).map((d1,index1) => <div key={"drive_1_" + index + "_A" + index1} className={d[1].type === 2 ? "changed" : ""}>{d1[1] > 0 ? <span>{d1[1]}%</span>: null }</div>) : null}
                        </div>
                      </td>
                      <td className={d[1].status === 10 ? "td_active" : d[1].status ===8 ? "" : d[1].status === -1 && !d[1].drived ? "td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past" : "td_past_err" : ""}>{d[1].recipe_name}</td>
                      <td className={d[1].status === 10 ? "center td_active" : d[1].status ===8 ? "center" : d[1].status === -1 && !d[1].drived ? "td_past_err center" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center" : "td_past_err center" :"center"}><span>
                        {d[1].flag === 1 || d[1].type === 3 ? <div>E</div> : ""}
                        {d[1].type >= 6 && d[1].status >= 0 ? d[1].status >= 11 ? <img src={imgCancelStop} alt={t("drives_stopped")} title={t("drives_stopped")} className="img"/> : <img src={imgCanceled} alt={t("drives_stopped")} title={t("drives_stopped")} className="img"/> :
                        !d[1].drived && ((d[1].past > 0 && d[1].status < 0)  || d[1].status === -1) ? <img src={imgAttention} alt={t("drives_missing")} title={t("drives_missing")} className="img"/> :
                        d[1].warning === 1 ? <img src={imgWarning} alt={t("drives_warning")} title={t("drives_warning")} className="img"/> : null }</span>
                      </td>
                      <td className={d[1].status === 10 ? "center td_img td_active" : d[1].status ===8 ? "center td_img" : d[1].status === -1 && !d[1].drived ? "td_past_err center td_img" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_img td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center td_img" : "td_past_err center td_img" : d[1].status === 6 ? "red" : "center td_img"}>
                        {d[1].past < 10 && d[1].past !== -1 && d[1].status === 4 && d[1].type !== 3 ? <img src={imgRepeat} alt={t("drives_repeat")} title={t("drives_repeat")} id={"d1" + index} onClick={repeatEntry} className="img" onMouseOver={(e) => {e.currentTarget.src=require("./img/repeat_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgRepeat}}  /> :
                        ((d[1].status === 1 || d[1].status === 11) && d[1].type === 2) || ((d[1].status === 1 || d[1].status === 11) && d[1].type >= 6) ? <img src={imgInstall} id={"a1" + index} alt={t("send_to_robot")} title={t("send_to_robot")} onMouseOver={(e) => {e.currentTarget.src=require("./img/install_mo.png")}} onMouseOut={(e) => { e.currentTarget.src=imgInstall}} className="img" onClick={activateEntry}/> : null}
                      </td>
                      <td className={d[1].status === 10 ? "center td_img td_active" : d[1].status ===8 ? "center td_img" : d[1].status === -1 && !d[1].drived ? "td_past_err center td_img" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_img td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center td_img" : "td_past_err center td_img" : "center td_img"}>
                        {(d[1].past === 0 || d[1].past === 1) && d[1].status === 4 && d[1].type !== 3 && d[1].type < 6 ? <img src={imgStop} alt={t("drives_stop")} title={t("drives_stop")} id={"s1" + index}  onClick={stopEntry} className="img" onMouseOver={(e) => {e.currentTarget.src = require("./img/stop_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgStop}} /> :
                        (d[1].status <= 1 && d[1].type === 2) || (d[1].type >= 6 && d[1].status >= 0) || d[1].type === 2 || d[1].status === 3 || d[1].status === 1|| d[1].status === 8 ? <img src={imgCancel} alt={t("cancel")} title={t("cancel")} id={"c1" + index} className="img" onClick={cancelEntry} onMouseOver={(e) => {e.currentTarget.src = require("./img/cancel_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgCancel}} /> :
                        d[1].status < 0 && d[1].past < 10 && !d[1].drived ? <img src={imgPlay} alt={t("drives_reactivate")} title={t("drives_reactivate")} id={"d1" + index} onClick={repeatEntry} className="img" onMouseOver={(e) => {e.currentTarget.src=require("./img/play_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgPlay}} /> : null}
                      </td>
                    </tr>
                  }</tbody>
                ) : "-"}
              </table>
            </div>
            <div className="dr_table" id="dr_table2">
              <table width="100%" border="0" cellPadding="0" cellSpacing="1">
                <thead>
                  <tr>
                    <th>{t("drive_time")}</th>
                    <th> </th>
                    <th>{t("animals_group")}</th>
                    <th>{t("animals_org_recipe_id")}</th>
                    <th>{t("status")}</th>
                    <th colSpan="2">{t("options")}</th>
                  </tr>
                </thead>
                { this.state.data12 != null ? Object.entries(this.state.data12).map((d,index) =>
                  <tbody id={"drive_2_" + index} key={"drive_2_" + index}>{d[1].status === 100 ?
                    <tr><td colSpan="7" className="now">{d[1].time}</td></tr> :
                    <tr>
                      <td className={d[1].status === 10 ? "td_top td_active" : d[1].status ===8 ? "td_top" : d[1].status === -1 && !d[1].drived ? "td_top td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_top td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_top td_past" : "td_top td_past_err" : "td_top"}><div className={d[1].early_start ?  "starttime late_runtime" : "starttime"}>{d[1].starttime ? d[1].starttime : "-" }</div><div className={d[1].late_drive ? "runtime late_runtime" : "runtime"}>{d[1].drived ? d[1].drived : "-" }</div></td>
                      <td className={"td_robot" + (d[1].robot_color >= 0 ? " robot_" + d[1].robot_color : "") + " testentry"}> </td>
                      <td className={d[1].status === 10 ? "td_active" : d[1].status ===8 ? "" : d[1].status === -1 && !d[1].drived ? "td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past" : "td_past_err" :""}>
                        <div className=" names">
                          {d[1].names ?  Object.entries(d[1].names).map((d1,index1) => <div key={"drive_2_" + index + "_F" + index1}>{d1[1]}</div>) : null}
                        </div>
                        <div className={d[1].status === 6 ? "names amount changed" : "names amount"}>
                          {d[1].amount ? Object.entries(d[1].amount).map((d1,index1) => <div key={"drive_2_" + index + "_A" + index1} className={d[1].type === 2 ? "changed" : ""}>{d1[1] > 0 ? <span>{d1[1]}%</span>: null }</div>) : null}
                        </div>
                      </td>
                      <td className={d[1].status === 10 ? "td_active" : d[1].status ===8 ? "" : d[1].status === -1 && !d[1].drived? "td_past_err" : d[1].status !== 4 &&  d[1].status >= 0 ? "td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past" : "td_past_err" : ""}>{d[1].recipe_name}</td>
                      <td className={d[1].status === 10 ? "center td_active" : d[1].status ===8 ? "center" : d[1].status === -1 && !d[1].drived ? "td_past_err center" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center" : "td_past_err center" :"center"}><span>
                        {d[1].flag === 1 || d[1].type === 3 ? <div>E</div> : ""}
                        {d[1].type >= 6 && d[1].status >= 0 ? d[1].status >= 11 ? <img src={imgCancelStop} alt={t("drives_stopped")} title={t("drives_stopped")} className="img"/> : <img src={imgCanceled} alt={t("drives_stopped")} title={t("drives_stopped")} className="img"/> :
                        !d[1].drived && ((d[1].past > 0 && d[1].status < 0)  || d[1].status === -1) ? <img src={imgAttention} alt={t("drives_missing")} title={t("drives_missing")} className="img"/> :
                        d[1].warning === 1 ? <img src={imgWarning} alt={t("drives_warning")} title={t("drives_warning")} className="img"/> : null }</span>
                      </td>
                      <td className={d[1].status === 10 ? "center td_img td_active" : d[1].status ===8 ? "center td_img" : d[1].status === -1 && !d[1].drived ? "td_past_err center td_img" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_img td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center td_img" : "td_past_err center td_img" : d[1].status === 6 ? "red" : "center td_img"}>
                        {d[1].past < 10 && d[1].past !== -1 && d[1].status === 4 && d[1].type !== 3 ? <img src={imgRepeat} alt={t("drives_repeat")} title={t("drives_repeat")} id={"d2" + index} onClick={repeatEntry} className="img" onMouseOver={(e) => {e.currentTarget.src=require("./img/repeat_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgRepeat}}  /> :
                        d[1].status === 1 || (d[1].status === 11 && d[1].type === 2) || ((d[1].status === 1 || d[1].status === 11) && d[1].type >= 6) ? <img src={imgInstall} alt={t("send_to_robot")} title={t("send_to_robot")} id={"a2" + index} onMouseOver={(e) => {e.currentTarget.src=require("./img/install_mo.png")}} onMouseOut={(e) => { e.currentTarget.src=imgInstall}} className="img" onClick={activateEntry}/> : null}
                      </td>
                      <td className={d[1].status === 10 ? "center td_img td_active" :d[1].status ===8 ? "center td_img" :  d[1].status === -1 ? "td_past_err center td_img" : d[1].status !== 4 &&  d[1].status >= 0 ? "center td_img td_update" : d[1].past >= 1 ? (d[1].past !== 1 && d[1].past !== 11) ? "td_past center td_img" : "td_past_err center td_img" : "center td_img"}>
                        {(d[1].past === 0 || d[1].past === 1) && d[1].status === 4 && d[1].type !== 3 && d[1].type < 6 ? <img src={imgStop} alt={t("drives_stop")} title={t("drives_stop")} id={"s2" + index}  onClick={stopEntry} className="img" onMouseOver={(e) => {e.currentTarget.src = require("./img/stop_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgStop}} /> :
                        (d[1].status <= 1 && d[1].type === 2) || (d[1].type >= 6 && d[1].status >= 0) || d[1].type === 2 || d[1].status === 3 || d[1].status === 1 ? <img src={imgCancel} alt={t("cancel")} title={t("cancel")} id={"c2" + index} className="img" onClick={cancelEntry} onMouseOver={(e) => {e.currentTarget.src = require("./img/cancel_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgCancel}} /> :
                        d[1].status < 0 && d[1].past < 10 && !d[1].drived ? <img src={imgPlay} alt={t("drives_reactivate")} title={t("drives_reactivate")} id={"d2" + index} onClick={repeatEntry} className="img" onMouseOver={(e) => {e.currentTarget.src=require("./img/play_mo.png")}} onMouseOut={(e) => {e.currentTarget.src=imgPlay}}  /> : null}
                      </td>
                    </tr>
                  }</tbody>
                ) : "-"}
              </table>
            </div><br />
          </div> :
          <div onMouseMove={this._onMouseMove.bind(this)}>
          <ReactTable
            data={this.state.data}
            columns={columns}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            showPageJump= {true}
            defaultPageSize = {50}
            pageSizeOptions = {[10, 20, 50, 100]}
            useFlexLayout = {true}
            expanded={this.state.expanded}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && !this.state.showActive && userRight) { this.setState({ selected: rowInfo.index, }); editEntry(rowInfo.index) } },
                  style: {
                    background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : rowInfo.row.type === 1 ? '#E0EEBB' : 'white', //  rowInfo.row.type === 1 ? '#FFF9F1' :
                    fontWeight:  rowInfo.row.status < 3 ? "bold" : null,
                    color: rowInfo && rowInfo.index === this.state.selected ? 'white' : this.state.statusColor[rowInfo.row.status],
                    fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                  }
              }
            }}
          />
          </div>
        }
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} type = {this.state.updateType} edit = {this.state.advancedEdit} date = {this.state.selectecdDate} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Drives;
