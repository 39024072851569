import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar  } from 'react-chartjs-2';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
import { format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function t(field) { return Translation.get(field) }

class Energy extends Component{
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-31)
    this.state={
      geturl: "/charts/energy.php?",
      data: [], sums: [],
      label: [],
      feeds: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      selected: 1,
      fixScale : false, max : 200,
      //steps: {time: 3600},
      showHelp: false, showHelpId: null, // Info popup
    }
    this.getData = this.getData.bind(this)
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + "start=" + this.state.startDate + "&end=" + this.state.endDate).then((result) => {
      this.setState({
        data: result.data,
        sums: result.sums,
        label: result.label,
        //steps: result.scalevals,
        max: result.max,
      })
    })
  }
  getData(id, startDate, endDate) {
    GetJsonFormPHP(this.state.geturl + "start=" + startDate + "&end=" + endDate).then((result) => {
      this.setState({
        data: result.data,
        sums: result.sums,
        label: result.label,
        //steps: result.scalevals,
        max: result.max,
      })
    })
  }
  getOptionsChart() {
    return {
      plugins: {
        title: {
          display: true,
          text: "",
          font: { size: 24, },
        },
        legend: {
          position: 'right',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              const getTime = value => {
                var hours   = Math.floor(value / 3600)
                var minutes = Math.floor((value - (hours * 3600)) / 60)
                if (hours   < 10) {hours   = "0"+hours}
                if (minutes < 10) {minutes = "0"+minutes}
                return hours+':'+minutes
              }
              let label = context.dataset.label || '';
              if (label) { label += ': '; }
              if (context.parsed.y !== null) {
                //console.log(context.dataset)
                if (context.parsed.y>0) {
                  if (context.dataset.yAxisID === "y1") label += getTime(context.parsed.y) ;
                  else label += context.parsed.y + " kWh";
                }
                else {
                  var d = context.dataset
                  var index = context.dataIndex
                  if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                }
              }
              return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
        },
      },
      responsive: true,
      scales: {
        x: { stacked: true, },
        y: {
          min: 0,
          max: this.state.max,
          stacked: true,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kWh'
            else return ""
          } }
        },
      },
    }
  }
  render() {
    //console.log(this.state.max)
    //const startChange = value => { this.setState({ startDate: value.target.value, }) }
    //const endChange = value => { this.setState({ endDate: value.target.value, }) }
    const options = this.getOptionsChart()
    let data1 = this.state.data
    const labels = this.state.label
    const data = {
      labels,
      datasets: data1,
    }
    //console.log(this.state.sums)
    return (
      <div>
        <table width="60%" border="0">
          <thead><tr><td>{t("start_date")}</td><td>{t("end_date")}</td><td> </td></tr></thead>
          <tbody><tr>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={value => { this.setState({ startDate: value.target.value, }) }} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={ value => { this.setState({ endDate: value.target.value, }) }} className="datefield"/></td>
            <td width="15%" align="center"><button onClick={() => {this.getData(this.state.selectedGroup, this.state.startDate, this.state.endDate)}} className="button">{t("show")}</button></td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_energy_charts", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>
        <br />
        {this.state.sums ? <div><b>{t('chart_energy_sum_used')}: { this.state.sums['all']} kWh</b></div> : null}
        <Bar  options={options} data={data} className="stats"/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}

export default Energy;
