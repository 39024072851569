import React, { Component } from 'react';
import TableFunctions from '../datatables/api/TableFunctions.js';
import ReactTable from "react-table-6";
import TextField from '@mui/material/TextField';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dateFormat from 'dateformat';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
//import UserProfile from '../user/UserProfile';
import Translation from '../user/Translation';
import UserProfile from '../user/UserProfile';
import Draggable from "react-draggable";
import Info from "../datatables/api/Info";
import { format } from 'date-fns';
import imgTrash from '../datatables/img/trash.png';


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      getEventUrl: "/charts/get_events.php?type=20",
      saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      row: null, groups: null, groupnames: null, id: 0,
      checked: [], feedamounts: [],
      eventName: "", date: dateFormat(new Date(), "yyyy-mm-dd"),
      data: null, empty: null, selected: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
      position: [],
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleEventDelete = this.handleEventDelete.bind(this)
  }
  handleClose() {
    this.props.closeDiv();
  }
  storeEvent(event) {
    event.preventDefault();
    GetJsonFormPHP(this.state.eventUrl + "?id=-1&evid=20&val=" + this.state.extradrive + "&name=" + this.state.eventName + "&date=" + this.state.date).then((result) => {
      this.props.reload()
    })
  }
  static getDerivedStateFromProps(props, state) {
    return {
      empty: props.empty,
      data: props.data,
      position: props.position,
    }
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_box")
    e1.style.left = (this.state.position[0]-28*em)+'px';
    e1.style.top =(this.state.position[1]-10*em)+'px';
  }
  reload() {
    this.props.reload()
    /*GetJsonFormPHP(this.state.getEventUrl).then((result) => {
      this.setState({
        data: result.data,
        empty: result.empty,
      })
    })*/
  }
  handleEventDelete = (id) =>{
    GetJsonFormPHP(this.state.eventUrl +"?delID=" + id).then((result) => {
      this.reload()
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return [
      { accessor: 'id', show: false, },
      this.addTextCell('date', 'timestamp', 0, 6, true, "", "right", null, null, false),
      this.addTextCell('name', 'name', 0, 0, true, "", null, null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.handleEventDelete(row.id) } } width={em + "px"}/>
        },
      },
    ]
  }

  render() {
    const saveEvent = value => { this.handleClose() }
    const columnsEvents = this.createColumns()
    return (
      <Draggable handle=".handle">
      <div className="edittable" id="edit_box">
      <div className="header">
          <div className="header_inline handle">{t('animalgroup_add_event')}</div>
          <div className="header_inline close"><img src={require("../datatables/img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/close.png")}} /></div>
        </div>
        <div align ="center">
          <br />
          <form onSubmit={saveEvent}>
            <div className="table_buttons">
              <input type="text" name="event_name" value={this.state.eventName} onChange={(value) => { this.setState({ eventName: value.target.value, }) }} className="" /> &nbsp; &nbsp;
              <span>{t("date")}: <TextField id="event_date" type="date" defaultValue={this.state.date} InputLabelProps={{ shrink: false, }} onChange={ (value) => { this.setState({ date: value.target.value, }) }} className="dateInput"/></span>
            </div>
            <div className="table_buttons" align="center">
              <button onClick={(value) => { this.storeEvent(value) }} disabled={this.state.eventName.length > 0 ? false : true }className="button">{t("save")}</button>
              <button onClick={this.handleClose} className="button">{t("cancel")}</button>
            </div>
          </form>
        </div>
        {this.state.data !== null ?
        <ReactTable
            data={this.state.data}
            columns={columnsEvents}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            useFlexLayout = {true}
            showPaginationBottom={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: {
                    background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                    color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                  }
              }
            }}
        /> : null}
      </div>
      </Draggable>
    )
  }
}

class ChartFeeds extends Component{
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate()-31)
    this.state={
      geturl: "/charts/feeds.php?feed_id=",
      getEventUrl: "/charts/get_events.php?type=20",
      data: [],
      label: [],
      feeds: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      selected: 0,
      fixScale : false,
      maxAmount: 100,
      minAmount: -5,
      sum: null,
      TM: "0",
      eventList: [], eventEmpty: [], // event list for edit
      showHelp: false, showHelpId: null, showPosition: [], // Info popup
      showHideEdit: false, // Advanced insert window
    }
    this.getData = this.getData.bind(this)
    this.reload = this.reload.bind(this)
    this.closeEdit = this.closeEdit.bind(this)
  }
  componentDidMount() {
    //console.log("mount")
    GetJsonFormPHP(this.state.geturl + this.state.selected + "&start=" + this.state.startDate + "&end=" + this.state.endDate).then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        sum: result.sum,
        minAmount: result.max[1],
      })
      //console.log(this.state.data)
    })
    GetJsonFormPHP(this.state.getEventUrl).then((result) => {
      //console.log("reload events")
      this.setState({
        eventList: result.data,
        eventEmpty: result.empty,
      })
      //console.log(this.state.eventList)
    })
  }
  reload() {
    this.setState({
      showHideEdit: false,
    })
    this.componentDidMount()
  }
  closeEdit() {
    this.setState({
      showHideEdit: false,
    })
  }
  getData(feedID, startDate, endDate, TM = this.state.TM) {
    GetJsonFormPHP(this.state.geturl + feedID + "&start=" + startDate + "&end=" + endDate + "&TM=" + TM).then((result) => {
      this.setState({
        data: result.data,
        label: result.label,
        feeds: result.feeds,
        sum: result.sum,
        selected: feedID,
      })
    })
  }

  render() {
    //console.log(this.state)
    const userRight = UserProfile.checkRight("custuser")
    const changeTM = value => { this.setState({ TM: value.target.value}); this.getData(this.state.selected, this.state.startDate, this.state.endDate, value.target.value) }
    let title = "" //t("chart_feeds_per_day")
    if (this.state.sum !== null ) title = title + "  " + this.state.sum[0] + " kg"
    const options = {
      plugins: {
        title: {
          display: true,
          text: title,
          font: { size: 24, },
        },
        legend: {
          position: 'bottom',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              //console.log(data)
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                //return null
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          //bodyFontStyle: 'bold',
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
          //titleFontSize: 35,
          //bodyFontSize: 28,
          //borderColor: '#FF9999',
          //multiKeyBackground: "#ffffff"
        },
      },
      responsive: true,
      scales: {
        x: { stacked: false, },
        y: {
          stacked: false,
          min: this.state.minAmount,
          ticks: { callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'
            else return ""
          } }
        },
        y2: {
          min: 0,
          position: 'none',
          grid: {
            display: false,
          },
          ticks: {
            color: 'blue',
            callback: function(value, index, ticks) {
              if (value >= 0 ) return value + ' ' // '◆' +
            else return ""
          } },
        },
      },
    }
    let data1 = this.state.data
    const labels = this.state.label
    const data = {
      labels,
      datasets: data1,
    }
    return (
      <div>
        <table width="100%" border="0">
          <thead><tr><td>{t("chart_feeds")}</td><td>{t("start_date")}</td><td>{t("end_date")}</td><td> </td></tr></thead>
          <tbody><tr>
            <td>
            <select name="feedlist" value={this.state.selected} onChange={(value) => { this.getData(value.target.value, this.state.startDate, this.state.endDate) }} style={null} className="chartselect">
                { this.state.feeds !== null ? Array.isArray(this.state.feeds) ? Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>)
                  : Object.entries(this.state.feeds).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ startDate: value.target.value, }) }} className="datefield"/></td>
            <td><TextField id="end_date" type="date" defaultValue={this.state.endDate} InputLabelProps={{ shrink: true,}} onChange={(value) => { this.setState({ endDate: value.target.value, }) }} className="datefield"/></td>
            <td>
              <nobr><input type="radio" id="FM" name="TM" value="0" checked={this.state.TM === "0" ? true : false} onChange={changeTM} /><label htmlFor="FM">{t('feed_FM')}  ({t('feed_FM_short')})</label></nobr><br />
              <nobr><input type="radio" id="TM" name="TM" value="1" checked={this.state.TM === "1" ? true : false} onChange={changeTM} /><label htmlFor="TM">{t('feed_T')} ({t('feed_T_short')})</label></nobr>
            </td>
            <td width="15%" align="center"><button onClick={() => {this.getData(this.state.selected, this.state.startDate, this.state.endDate, this.state.TM)}} className="button">{t("show")}</button></td>
            <td width="40%"><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_feed_charts", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>

        <Bar options={options} data={data} className="stats"/>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.showHideEdit && <Edit data={this.state.eventList} empty={this.state.eventEmpty} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        {userRight ? <div align="center"><br /><button onClick={(value) => { this.setState({ showHideEdit: true, showPosition: [value.clientX, value.clientY], })}} className="button">{t("animalgroup_add_global_event")}</button></div> : null}
      </div>
    )
  }
}

export default ChartFeeds;