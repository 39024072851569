import React, { Component } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Select from 'react-select'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
//import { PolarArea } from 'react-chartjs-2';
import { RadialLinearScale, } from 'chart.js';
import '../styles/style.css';

function t(field) { return Translation.get(field) }
//ChartJS.register(ArcElement, Tooltip, Legend);
//ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.register(RadialLinearScale);

class Recipes extends Component{
  constructor() {
    super();
    this.state={
      geturl: "/charts/recipes.php?id=",
      feedLabel1: [], feedLabel2: [],
      feedData1: [], feedData2: [],
      ingredientlabel1:[], ingredientlabel2: [],
      ingredientdata1: [], ingredientdata2: [],
      selected: 0, selectedComp: 0,
      recipes: null,
      name: null,
      TM: "0",
      label2: [],
      data2: [],
      showHelp: false, showHelpId: null, // Info popup
    }
  }
  componentDidMount(val = this.state.selected, comp = this.state.selectedComp, TM = this.state.TM) {
    GetJsonFormPHP(this.state.geturl + val + "&id2=" + comp + "&TM=" + TM).then((result) => {
      if (val === 0) val = result.first
      this.setState({
        name: result.name,
        feedData1: result.feeddata1,
        feedLabel1: result.feedlabel1,
        feedData2: result.feeddata2,
        feedLabel2: result.feedlabel2,
        ingredientlabel1: result.ingredientlabel1,
        ingredientlabel2: result.ingredientlabel2,
        ingredientdata1: result.ingredientdata1,
        ingredientdata2: result.ingredientdata2,
        recipes: result.recipes, comparelist: result.comparelist, //compare: result.compare,
        selected: val, selectedComp: comp,
      })
    })
  }
  getOptionsPie() {
    return {
      maintainAspectRatio: true,
      aspectRatio: 1.65,
      layout: {
        padding: {
          left: 0,
          right: 0
        }
      },
      plugins: {
        legend: {
          position: 'right',
          align: "start",
          labels: {
            font: { size: 12, } // This more specific font property overrides the global property
          }
        },
        title: {
          display: false,
          text: t("chart_feeds_nutrids"),
        },
        /*title: {
          display: true,
          text: this.state.name,
          font: {
            size: 24,
            lineHeight: 0,
          },
        },*/
        tooltip: {
          callbacks: {
            label: function(context) {
              if (context.parsed !== null) return context.label + " " + context.parsed + " kg"
              else return context.label
            },
            /*afterBody: function(data) {
              var d = data[0].dataset
              if (d.event !== "") {
                var multistringText = [d.event]
                //multistringText.push('Event 2');
                return multistringText
              }
              return null
            } */
          },
        }
      },
      //layout: { padding: -10 },
    }
  }
  getOptionsChart() {
    return {
      responsive: true,
      aspectRatio: 2,
      plugins: {
        title: {
          display: true,
          text: t("chart_feeds_nutrids"),
          font: { size: 24, },
        },
        legend: {
          position: 'right',
          fullSize: true,
          //align: "start",
          //rtl: true,
          labels: {
            boxWidth: 20,
            font: { size: 12, }, // This more specific font property overrides the global property
            padding: 5,
            //color: 'blue',
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';
              if (label) { label += ': '; }
              if (context.parsed.y !== null) { label += context.parsed.y + ""; }
              return label
            },
            afterBody: function(data) {
              var d = data[0].dataset
              if (d.event !== "") {
                var multistringText = [d.event]
                multistringText.push('Event 2');
                return multistringText
              }
              return null
            }
          },
        }
      },
      categoryPercentage: 1.0,
      barPercentage: 0.96,
      scales: {
        x: {
          ticks: {
            color: 'red',
            font: { size: 14 }
          }
        },
        y: {
          display: true,
          position: 'left',
        },
        y1: {
          display: true,
          position: 'right',
          ticks: { color: 'black' },
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      /*scales: {
        x: {
          categoryPercentage: 1.0,
          barPercentage: 1.0,
        }
      },*/
    }
  }

  render() {
    //console.log(this.state.TM)
    const changeRecipe = value => { this.componentDidMount(value.value, this.state.selectedComp) }
    const changeCompare = value => { this.componentDidMount(this.state.selected, value.value) }
    const changeTM = value => { this.setState({ TM: value.target.value}); this.componentDidMount(this.state.selected, this.state.selectedComp, value.target.value) }
    const options = this.getOptionsPie()
    const feeddata = {
      labels: this.state.feedLabel1,
      datasets: [this.state.feedData1,]
    }
    const feeddata2 = {
      labels: this.state.feedLabel2,
      datasets: [this.state.feedData2,]
    }
    let options1 = this.getOptionsChart()
    const ingredientdata1 = {
      //barPercentage: 1.0,
      labels: this.state.ingredientlabel1,
      datasets: this.state.ingredientdata1,
    }
    const ingredientdata2 = {
      labels: this.state.ingredientlabel2,
      datasets: this.state.ingredientdata2,
    }

    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    if (this.state.selected > 0)
    return (
      <div>
        <table width="100%">
          <thead><tr>
            <td width="30%">{t("chart_recipelist")}</td>
            <td width="23%">{t("chart_FM_T")}</td>
            <td width="47%">{t("chart_compare")}</td>
          </tr></thead>
          <tbody><tr>
            <td>
              <Select
                defaultValue={this.state.recipes[0]}
                options={this.state.recipes}
                styles={colourStyles}
                isSearchable={true}
                className="chartselect"
                onChange={(value) => {changeRecipe(value)}}
                placeholder={t('select')}
              />
            </td>
            <td>
              <input type="radio" id="FM" name="TM" value="0" checked={this.state.TM === "0" ? true : false} onChange={changeTM} /><label htmlFor="FM">{t('feed_FM')}  ({t('feed_FM_short')})</label><br />
              <input type="radio" id="TM" name="TM" value="1" checked={this.state.TM === "1" ? true : false} onChange={changeTM} /><label htmlFor="TM">{t('feed_T')} ({t('feed_T_short')})</label><br />
            </td>
            <td>
              <Select
                options={this.state.comparelist}
                styles={colourStyles}
                isSearchable={true}
                className="chartselect"
                onChange={(value) => {changeCompare(value)}}
                placeholder={t('select')}
              />
            </td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_recipe_charts", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>
        <table width="100%"><tbody><tr>
          <td width="50%"><Pie options={options} data={feeddata} className="pie" /></td>
          <td width="50%">{this.state.selectedComp > 0 ? <Pie options={options} data={feeddata2} className="pie" /> : null}</td>
        </tr>
        <tr>
          <td><Bar options={options1} data={ingredientdata1} className="stats"/></td>
          <td>{this.state.selectedComp > 0 ? <Bar options={options1} data={ingredientdata2} className="stats"/> : null}</td>
        </tr>
        </tbody></table>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
    else return null
  }
}

export default Recipes;

/*
        tooltip: {
          enabled: true,
          external: function(context) {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');
                // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table border=0 cellSpacing=1 cellPadding=1 style="background-color: #AAAAAA; padding: 2px"></table>';
                document.body.appendChild(tooltipEl);
            }
                // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
                //tooltipEl.style.opacity = 0;
                return;
            }
            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) { tooltipEl.classList.add(tooltipModel.yAlign); }
            else { tooltipEl.classList.add('no-transform'); }
            function getBody(bodyItem) { return bodyItem.lines; }
            // Set Text
            if (tooltipModel.body) {
              const titleLines = tooltipModel.title || [];
              const bodyLines = tooltipModel.body.map(getBody);
              let innerHtml = '<thead>';
              titleLines.forEach(function(title) { innerHtml += '<tr><th>' + title + '</th></tr>'; });
              innerHtml += '</thead><tbody>';
              bodyLines.forEach(function(body, i) {
                const colors = tooltipModel.labelColors[i];
                let style = 'background:' + colors.backgroundColor;
                style += '; border-color:' + colors.borderColor;
                style += '; border-width: 2px';
                const span = '<span style="' + style + '"></span>';
                innerHtml += '<tr><td>' + span + body + ' kg</td></tr>';
              });
              innerHtml += '</tbody>';
              let tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }
            const position = context.chart.canvas.getBoundingClientRect();
            //const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);
            // Display, position, and set styles for font
            //tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            //tooltipEl.style.font = bodyFont.string;
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }

*/