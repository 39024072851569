import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Translation from '../user/Translation'
import GetJsonFormPHP from "./api/GetJsonFormPHP"
import {setTableFontSize} from './api/imports.js'

import './styles/react-table.css'
import imgFalse from './img/false.png'
import imgTrue from './img/true.gif'

function t(field) { return Translation.get(field) }

class Translations extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/users/get_translations.php",
      saveurl: "/users/set_translations.php",
      orgData: [], data: [], empty: null, selected: null,  // Table data
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      filterField: "", filterLan: "", // Filter Options
      fontSize: setTableFontSize(0.9),
    }
    this.setfilter = this.setfilter.bind(this)
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        orgData: result.data,
        data: result.data,
        empty: {org_id: -1, id: "", en: "", de: "", fr: "", it: ""},
      })
      if (this.state.filterField !== "" || this.state.filterLan !== "") this.setfilter()
    })
  }
  setfilter(value) {
    let filterField = this.state.filterField
    let filterLan = this.state.filterLan
    if (value && value.target.name === "filter_field") filterField = value.target.value
    if (value && value.target.name === "filter_lans") filterLan = value.target.value
    let  a = []
    if (filterField !== "") {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.id.toString().toLowerCase().includes(filterField.toLowerCase()) || t.org_id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    let  a1 = []
    if (filterLan !== "") {
      // eslint-disable-next-line array-callback-return
      a.map((t,k) => {
        const filter = filterLan.toLowerCase()
        if (t.en.toLowerCase().includes(filter) || t.de.toLowerCase().includes(filter) || t.fr.toLowerCase().includes(filter) || t.it.toLowerCase().includes(filter) || t.org_id === -1) a1 = [...a1, t]
      })
    }
    else a1 = a
    this.setState({
      data: a1,
      filterField: filterField,
      filterLan: filterLan,
      selected: null,
    })
  }
  radioboxCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
        <label><input type="radio" name={id} value="1" className="checkbox" checked={row.enabled === "1"} onChange={inputChange} />{options[1]}</label><br />
        <label><input type="radio" name={id} value="0" className="checkbox" checked={row.enabled === "0"} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === "1") return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
    }
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const columns = [
      { accessor: 'org_id', show: false,},
      this.addTextCell('fieldname', 'id', 0, 9),
      { accessor: 'de',
        Header: t('Deutsch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textareaCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (rowInfo.row.check_de===0 || !rowInfo.row.de ? '#FFEECC':null) } })
      },{ accessor: 'en',
        Header: t('Englisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textareaCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (rowInfo.row.check_en===0 || !rowInfo.row.de ? '#FFEECC':null) } })
      },{ accessor: 'fr',
        Header: t('Französisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textareaCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (!rowInfo.row.fr || rowInfo.row.check_fr===0 ? '#FFEECC':null) } })
      },{ accessor: 'it',
        Header: t('Italienisch'),
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.textareaCell(initialValue, id, row) },
        getProps: (state, rowInfo) => ({ style: { backgroundColor: (!rowInfo.row.it || rowInfo.row.check_it===0 ? '#FFEECC':null) } })
      },
      { accessor: 'check_en', show: false,},
      { accessor: 'check_de', show: false,},
      { accessor: 'check_fr', show: false,},
      { accessor: 'check_it', show: false,},
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false) },
      }
    ]
    return columns
  }

  render() {
    const columns = this.createColumns()
    const filterChange  = value => { this.setfilter(value) }
    return (
      <div>
        <div className="table_buttons">
          <button onClick={() => { if (this.state.orgData[0].org_id !== -1) this.insertRow()}}>{t("addline")}</button>
          <span>
            {t('filter_fieldname')}:&nbsp;
            <input type="text" name="filter_field" value={this.state.filterField} onChange={filterChange} onKeyDown={filterChange}/> &nbsp;
            {t('filter_languages')}:&nbsp;
            <input type="text" name="filter_lans" value={this.state.filterLan} onChange={filterChange} onKeyDown={filterChange}/> &nbsp;
          </span>
        </div>
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data = {this.state.data}
          columns = {columns}
          minRows = {0}
          previousText = {'<'}
          nextText = {'>'}
          showPageJump = {true}
          //persistTableHead = {true}
          //filterable = {true}
          defaultPageSize = {100}
          pageSizeOptions = {[50, 100, 200, 500]}
          useFlexLayout = {true}
          getTrProps = {(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected) { this.setState({ selected: rowInfo.index, }) } },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : 'white',
                color: rowInfo && rowInfo.index === this.state.selected ? '#777777' : 'black',
                'font-weight': 'lighter !important',
              }
            }
          }}
        />
        </div>
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default Translations;
