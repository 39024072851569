import React, { Component } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
//import DialogContent from '@mui/material/DialogContent'
//import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Translation from '../user/Translation'

function t(field) { return Translation.get(field) }

class AlertDialog extends Component {
  constructor() {
    super()
    this.state={
      title: "",
      message: "",
      choise: true,
      open: false,
      insert: "",
      content: "",
    }
    this.onTrigger = this.onTrigger.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    let insert = ""
    if (props.insert) insert = props.insert
    return {
      title: props.title,
      message: props.message,
      choise: props.choise,
      open: props.openDialog,
      insert: insert,
    }
  }

  onTrigger = (event) => {
    this.setState({open : false})
    this.props.callback(event, this.state.content)
  }

  render() {
    const inputChange = value => { this.setState({content: value.target.value}) }
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.onTrigger} aria-labelledby="alert-dialog-title">
          <DialogTitle id="alert-dialog-title" className="dialogtitle">{this.state.title}</DialogTitle>
          <div align="center" className="dialogchoise">
            {this.state.message}
            {this.state.insert  !== ""? <div>{t(this.state.insert)} <input type="text" name="insert" value={this.state.content} onChange={inputChange}/></div> : null }
            <DialogActions>
              {this.state.choise ? <Button onClick={() => { this.onTrigger(false)}} className="dialogbutton">{t("cancel")}</Button> : null}
              <Button onClick={() => { this.onTrigger(true)}} autoFocus className="dialogbutton">{t("confirm")}</Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    )
  }
}
export default AlertDialog

/*

        <Dialog open={this.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {"Use Google's location service?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Let Google help apps determine location. This means sending anonymous
              location data to Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Disagree</Button>
            <Button onClick={handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <h2 class="MuiTypography-root MuiTypography-h6 MuiDialogTitle-root css-bdhsul-MuiTypography-root-MuiDialogTitle-root" id="alert-dialog-title">Zeile löschen</h2>
*/