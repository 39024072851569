import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, } from 'chart.js';
import { Doughnut  } from 'react-chartjs-2';
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP";
import Translation from '../user/Translation';
import Info from "../datatables/api/Info";
import { format } from 'date-fns';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
function t(field) { return Translation.get(field) }


class Timechart extends Component{
  constructor() {
    super();
    let startDay = new Date()
    startDay.setDate(startDay.getDate())
    this.state={
      geturl: "/charts/timechart.php?",
      data: [],
      label: [],
      feeds: null,
      startDate: format(startDay, 'yyyy-MM-dd'),
      robots: [],
      robot_id: 0,
      selected: 1,
      fixScale : false,
      steps: {time: 3600},
      colors: [],
      showHelp: false, showHelpId: null, // Info popup
    }
    this.getData = this.getData.bind(this)
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl + "day=" + this.state.startDate).then((result) => {
      this.setState({
        data: result.data,
        robots: result.robots,
        robot_id: result.robot_id,
        colors: result.colors,
        usage: result.usage,
      })
    })
  }
  getData(day, robot) {
    GetJsonFormPHP(this.state.geturl + "day=" + day + "&robot_id=" + robot).then((result) => {
      this.setState({
        data: result.data,
        robots: result.robots,
        usage: result.usage,
        //label: result.label,
      })
    })
  }
  /*convertIntToTime(sec_num) {
    //var sec_num = parseInt(sec, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
  }*/
  getOptionsChart() {
    return {
      plugins: {
        title: {
          display: false,
          text: "24 Std.",
          font: { size: 20, },
        },
        legend: {
          position: 'right',
          labels: {
            font: { size: 16, } // This more specific font property overrides the global property
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              /*const getTime = value => {
                var hours   = Math.floor(value / 3600)
                var minutes = Math.floor((value - (hours * 3600)) / 60)
                if (hours   < 10) {hours   = "0"+hours}
                if (minutes < 10) {minutes = "0"+minutes}
                return hours+':'+minutes
              }*/
              let label = context.dataset.label || '';
              if (label) { label += ': '; }
              //console.log(context.parsed, context.dataset.info[context.dataIndex])
              if (context.parsed.y !== null) {
                label = context.dataset.info[context.dataIndex]
                //console.log(context.dataset)
                /*if (context.parsed.y>0) {
                  label = context.dataset.info[context.dataIndex]
                  //label = getTime(context.parsed.y)
                  //label += context.parsed.y + " kWh";
                }
                else {
                  var d = context.dataset
                  var index = context.dataIndex
                  if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                }*/
              }
              return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
        },
        datalabels: {
          formatter: (value) => {
            if (value < 15) return '';
            return value + '%';
          }
        },
      },
      responsive: true,
      /*scales: {
        x: {
          stacked: false,
          ticks: { callback: function(value, index, ticks) {
            console.log(ticks)
            if (value >= 0 ) return value
            else return ""
          } }
        },
        y: {
          stacked: false,
          ticks: { callback: function(value, index, ticks) {
            console.log(value, ticks)
            if (value >= 0 ) return value
            else return ""
          } }
        },
      },*/
    }
  }
  render() {
    //console.log(this.state.robots)
    const startChange = value => { this.setState({ startDate: value.target.value, }); this.getData(value.target.value, this.state.robot_id) }
    const changeRobot = value => { this.setState({ robot_id: value.target.value, });  this.getData(this.state.startDate, value.target.value) }
    const options = this.getOptionsChart()
    let data1 = this.state.data
    const labels = this.state.label
    const data = {
      labels,
      datasets: data1,
    }
    const divIdle = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.idle }
    const divLoad = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.load }
    const divLoadFull = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.load_full }
    const divLoadFloat = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.load_float }
    const divUnload = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.unload }
    const divFill = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.fill }
    const divFillE = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.fill_E }
    const divFeed = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.feed }
    const divFeedE = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.feed_E }
    const divFeedPlan = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.feed_plan }
    const divClear = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.clear }
    const divClearE = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.clear_E }
    const divFeedCol = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.feed_collision }
    const divClearCol = { border: "1px solid #A0A0A0", marginRight: "0.3em", padding: "6px", background: this.state.colors.clear_collision }
    //<button onClick={() => {this.getData(this.state.startDate)}} className="chartbutton2">{t("show")}</button> &nbsp;
    //<button onClick={() => {this.getData(this.state.startDate)}} className="chartbutton1">{t("show")}</button>
    //<button onClick={() => {this.getData(this.state.startDate)}} className="button">{t("show")}</button> &nbsp;
    let os = navigator.userAgent
    let OsOffset = 0
    if (os.search('Mac')!==-1) OsOffset = 30// finalOs="MacOS"

    return (
      <div>
        <table width="100%" border="0">
          <thead><tr><td width="10%">{t("start_date")}</td><td width="10%"> </td><td width="80%"> </td></tr></thead>
          <tbody><tr>
            <td><TextField id="start_date" type="date" defaultValue={this.state.startDate} InputLabelProps={{ shrink: true,}} onChange={startChange} className="datefield"/></td>
            <td align="center">
            <select name="feedlist" value={this.state.robot_id} onChange={(value) => {changeRobot(value)}} style={null} className="chartselect">
                { this.state.robots !== null ? Array.isArray(this.state.robots) ? Object.entries(this.state.robots).map((t,k) => <option key={k} value={t[1].value} style={ t[1].color ? { color: t[1].color, } : null }>{t[1].label}</option>) 
                  : Object.entries(this.state.robots).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) : null}
              </select>
            </td>
            <td><div className="table_buttons"><div className="right_side"><img id="info" src={require("../datatables/img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_time_charts", }) } } onMouseOver={(e) => {e.currentTarget.src = require("../datatables/img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("../datatables/img/info.png")}} /></div></div></td>
          </tr></tbody>
        </table>
        <table border="0">
          <tbody><tr>
            <td>
              <div align="left" className="Doughnut"><Doughnut options={options} data={data} className="stats"/></div>
              <span Style={"position: relative; left: " + (291-OsOffset/2) + "px; top: -610px;"}>00:00</span>
              <span Style={"position: relative; left: " + (249-OsOffset/3*2) + "px; top: -45px;"}>12:00</span>
              <span Style={"position: relative; left: " + (500-OsOffset) + "px; top: -325px;"}>06:00</span>
              <span Style={"position: relative; left:  " + (-125-OsOffset*2) + "px; top: -325px;"}>18:00</span>
            </td>
            <td valign="top">
              <table border="0">
                <tbody>
                  <tr><td colSpan="2"><b>{t('timechart_outer_scale')}</b></td></tr>
                  <tr><td><div style={divIdle}> </div></td><td> {t('timechart_wait')}</td></tr>
                  <tr><td><div style={divLoad}> </div></td><td> {t('timechart_akku_loading')}</td></tr>
                  <tr><td><div style={divLoadFull}> </div></td><td> {t('timechart_akku_overloading')}</td></tr>
                  <tr><td><div style={divLoadFloat}> </div></td><td> {t('timechart_akku_connected')}</td></tr>
                  <tr><td><div style={divUnload}> </div></td><td> {t('timechart_akku_discharge')}</td></tr>
                  <tr><td colSpan="2"><div><br></br></div></td></tr>
                  <tr><td colSpan="2"><b>{t('timechart_middle_scale')}</b></td></tr>
                  <tr><td><div style={divIdle}> </div></td><td> {t('timechart_wait')}</td></tr>
                  <tr><td ><div style={divFill}> </div></td><td> {t('timechart_filling')}</td></tr>
                  <tr><td><div style={divFeed}> </div></td><td> {t('timechart_feed')}</td></tr>
                  <tr><td ><div style={divFillE}> </div></td><td> {t('timechart_filling')} {t('drives_repeat')}</td></tr>
                  <tr><td><div style={divFeedE}> </div></td><td> {t('timechart_feed')} {t('drives_repeat')}</td></tr>
                  <tr><td><div style={divClear}> </div></td><td> {t('timechart_clear')}</td></tr>
                  <tr><td><div style={divClearE}> </div></td><td> {t('timechart_clear')} {t('drives_repeat')}</td></tr>
                  <tr><td colSpan="2"><br></br></td></tr>
                  <tr><td colSpan="2"><b>{t('timechart_inner_scale')}</b></td></tr>
                  <tr><td><div style={divIdle}> </div></td><td> {t('timechart_wait')}</td></tr>
                  <tr><td><div style={divFeedPlan}> </div></td><td> {t('timechart_feed')}</td></tr>
                  <tr><td><div style={divClear}> </div></td><td> {t('timechart_clear')}</td></tr>
                  <tr><td><div style={divFeedCol}> </div></td><td> {t('timechart_collision')} {t('timechart_feed')}</td></tr>
                  <tr><td><div style={divClearCol}> </div></td><td> {t('timechart_collision')} {t('timechart_clear')}</td></tr>
                  <tr><td colSpan="2"><b><br/>{t('timechart_usage')} {this.state.usage}%</b></td></tr>
                </tbody>
              </table>
            </td>
          </tr></tbody>
        </table>
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
      </div>
    )
  }
}

export default Timechart;
